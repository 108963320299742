/**
 * Utility class for fetching the current Kentico Contact ID using custom API
 * endpoint. The fetched ID is then pushed to the dataLayer as a user_id.
 */

class CurrentContactID {
    constructor() {
        this.apiUrl = '/api/currentcontactid';

        this.fetchCurrentContactID()
            .then((data) => {
                dataLayer.push({
                    user_id: data,
                });
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }

    fetchCurrentContactID() {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: this.apiUrl,
            })
                .done(function(data) {
                    resolve(data);
                })
                .fail(function(xhr, status, error) {
                    reject(error);
                });
        });
    }
}

export default new CurrentContactID();
