// @ts-nocheck
import { DcBaseComponent } from '@deleteagency/dc';

export default class SlideCaptcha extends DcBaseComponent {
    static getNamespace() {
        return 'slide-captcha';
    }

    onInit() {
        this.captchaSlider = document.querySelector('#slider-captcha');
        if (!this.captchaSlider) {
            return;
        }

        this.outerForm = this.captchaSlider.closest('form');
        this.formSubmitButton = this.outerForm.querySelector("input[type='submit']")
            ? this.outerForm.querySelector("input[type='submit']")
            : undefined;
        this.handle = this.captchaSlider.querySelector('div');
        this.dragState = undefined;
        this.dimensions = {};

        this.handlers = {
            setDimensions: this._setDimensions.bind(this),
            dragStart: this._dragStartHandler.bind(this),
            dragMove: this._dragMoveHandler.bind(this),
            dragEnd: this._dragEndHandler.bind(this),
        };

        this.handle.addEventListener('mousedown', this.handlers.dragStart);
        this.handle.addEventListener('mousemove', this.handlers.dragMove);
        this.handle.addEventListener('mouseup', this.handlers.dragEnd);

        this.handle.addEventListener('touchstart', this.handlers.dragStart);
        this.handle.addEventListener('touchmove', this.handlers.dragMove);
        this.handle.addEventListener('touchend', this.handlers.dragEnd);

        window.addEventListener('resize', this.handlers.setDimension);

        this._setDimensions();
        if (this.outerForm) {
            let previousSubmitAction = this.outerForm.onsubmit;
            this.outerForm.onsubmit = (event) => {
                setTimeout(() => {
                    this._reloadComponent();
                }, 500);
                previousSubmitAction(event);
            };
        }

        if (this.formSubmitButton) {
            this.formSubmitButton.disabled = true;
        }
    }

    _reloadComponent = () => {
        this.captchaSlider.classList.remove('satisfied');
        this.handle.setAttribute('style', '');
        this.captchaSlider = undefined;
        this.formSubmitButton = undefined;
        this.handle = undefined;
        this.dragState = undefined;
        this.dimensions = undefined;
        this.onInit();
    };

    _setDimensions = () => {
        let captchaElementDimension = this.captchaSlider?.getBoundingClientRect();
        if (captchaElementDimension) {
            this.dimensions = {
                width: captchaElementDimension.width,
                height: captchaElementDimension.height,
            };
        }
    };

    _dragStartHandler = (evt) => {
        evt.preventDefault();
        this.dragState = evt.screenX || evt.touches[0].pageX;
        this.handle.classList.add('active');
        this.handle.addEventListener('mouseout', this.handlers.dragEnd);
        this.handle.addEventListener('touchleave', this.handlers.dragEnd);
    };

    _dragEndHandler = (evt) => {
        evt.preventDefault();
        if (this.dragState) {
            this.dragState = undefined;
            this.handle.classList.remove('active');
        }

        this.handle.removeAttribute('style');
        this.handle.removeEventListener('mouseout', this.handlers.dragEnd);
        this.handle.removeEventListener('touchleave', this.handlers.dragEnd);
    };

    _dragMoveHandler = (evt) => {
        if (this.dragState) {
            evt.preventDefault();

            let start = this.dimensions.height;
            let distance = this.dimensions.width;
            let delta = (evt.screenX || evt.touches[0].pageX) - this.dragState;

            delta = Math.min(distance, Math.max(0, delta));
            if (delta >= distance - start) {
                this.handle.removeEventListener('mouseout', this.handlers.dragEnd);
                this.handle.removeEventListener('mousedown', this.handlers.dragStart);
                this.handle.removeEventListener('mousemove', this.handlers.dragMove);
                this.handle.removeEventListener('mouseup', this.handlers.dragEnd);

                this.handle.removeEventListener('touchstart', this.handlers.dragStart);
                this.handle.removeEventListener('touchmove', this.handlers.dragMove);
                this.handle.removeEventListener('touchend', this.handlers.dragEnd);

                this.captchaSlider.classList.add('satisfied');
                this.handle.classList.remove('active');
                this.handle.setAttribute('style', 'right: 0;left: auto;');
                this.dragState = undefined;
                if (this.formSubmitButton) {
                    this.formSubmitButton.disabled = false;
                }

                return;
            } else {
                if (this.formSubmitButton) {
                    this.formSubmitButton.disabled = true;
                }
            }

            this.handle.setAttribute('style', 'left:' + (delta + start) + 'px;');
        }
    };
}
