import { eventBus } from 'general/js/events';
import loquateService from 'general/js/loquate-service';
import SvgIcon from 'general/js/svg-icon';
import nanoid from 'nanoid';
import React from 'preact/compat';
import propTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { defaultTheme } from 'react-autosuggest/dist/theme';

export const EVENT_RV_ADDRESS_BLURED = 'eventRvAddressBlured';
export const EVENT_RV_ADRESS_CHOOSED = 'eventRvAddressChoosed';
export const EVENT_RV_ADDRESS_EDIT_CLICKED = 'eventRvAddressEdited';
export const EVENT_RV_EDIT_BUTTON_CLICKED = 'eventRvEditButtonClicked';

class AddressPredictiveField extends React.Component {
    constructor(props) {
        super(props);

        const {
            attributes: { value = '' }
        } = props;

        this.state = {
            suggestions: [],
            value,
            isDisabled: false,
            errorIsActive: false,
            containerScrollLeft: 0,
        };

        this.id = nanoid();
        this.autosuggestInstance = null;
        this.ref = React.createRef();
        eventBus.addListener(EVENT_RV_ADRESS_CHOOSED, this._onExternalChoose);
        eventBus.addListener(EVENT_RV_ADDRESS_EDIT_CLICKED, this._setEmpty);
        eventBus.addListener(EVENT_RV_EDIT_BUTTON_CLICKED, this._onEditClick);
    }

    getSuggestionValue = (suggestion) => suggestion.text || suggestion.label;

    onSuggestionsFetchRequested = async ({ value }) => {
        await this._getAddressesByText(value);
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = async (event, { suggestion, method }) => {
        if (method === 'enter') {
            event.preventDefault();
        }

        if (suggestion.type === 'Address') {
            await this._getAddressDetails(suggestion.id);
        } else {
            await this._getAddressesById(suggestion.id);
        }

        this._setParentScroll(this.state.containerScrollLeft);
    };

    _getAddressDetails = async (id) => {
        const { target } = this.props;
        try {
            const content = await loquateService.getAddressDetails(id);
            this.setState({ isDisabled: true, errorIsActive: false }, () => {
                const { value } = this.state;
                eventBus.emit(EVENT_RV_ADRESS_CHOOSED, { target, content, value, id: this.id });
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e.message);
        }
    };

    _getAddressesById = async (id) => {
        try {
            const addresses = await loquateService.getAddressesById(id);
            const state = {
                suggestions: addresses.map((item) => ({
                    id: item.Id,
                    text: `${item.Text}, ${item.Description}`,
                    type: item.Type
                }))
            };
            this.setState(state);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e.message);
        }
    };

    _getAddressesByText = async (text) => {
        try {
            const addresses = await loquateService.getAddressesByFreeText(text);
            const state = {
                suggestions: addresses.map((item) => ({
                    id: item.Id,
                    text: `${item.Text}, ${item.Description}`,
                    type: item.Type
                }))
            };
            this.setState(state);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e.message);
        }
    };

    _onExternalChoose = ({ content, id }) => {
        if (id !== this.id) {
            this.setState({
                isDisabled: true,
                value: this.getSuggestionValue(content),
                errorIsActive: false
            });
        }
    };

    onValueChange = (event, { newValue }) => {
        this.setState({ value: newValue });
        this._getParentScroll();
    };

    _onBlur = () => {
        const { isDisabled } = this.state;
        if (!isDisabled) {
            this.setState({ errorIsActive: true });
            eventBus.emit(EVENT_RV_ADDRESS_BLURED);
        }
    };

    _onEditClick = () => {
        this.setState(
            { isDisabled: false, value: '', suggestions: [], errorIsActive: false },
            () => {
                this.input.focus();
            }
        );
        eventBus.emit(EVENT_RV_ADDRESS_EDIT_CLICKED, this.id);
    };

    _setEmpty = (id) => {
        if (id !== this.id) {
            this.setState({ isDisabled: false, value: '', suggestions: [], errorIsActive: false });
        }
    };

    // The parent scroll functions & related props are to fix bug #116495
    // It mosts masks the problem (on mobile, long addresses cause form side scroll on selection)
    // Replace if real solution is found
    _getParentScroll = () => {
        const scrollWrapper = this.ref.current.closest(this.props.wrapperTarget);

        if (scrollWrapper) {
            this.setState({ containerScrollLeft: scrollWrapper.scrollLeft });
        }
    };

    _setParentScroll = (int = 0) => {
        const scrollWrapper = this.ref.current.closest(this.props.wrapperTarget);

        if (scrollWrapper) {
            scrollWrapper.scrollLeft = int;
        }
    }

    render() {
        const { suggestions, value, isDisabled, errorIsActive } = this.state;
        const { attributes, labelNode, errorMessage, editButton } = this.props;
        const { inputClass = '' } = attributes;
        const inputProps = {
            ...attributes,
            readonly: isDisabled,
            value,
            autocomplete: 'no',
            'data-parsley-group': `["${attributes['data-parsley-group']}", "rv-address"]`,
            onBlur: !isDisabled ? this._onBlur : null,
            onChange: this.onValueChange
        };
        if (labelNode) labelNode.classList[isDisabled ? 'add' : 'remove']('is-selected');
        if (editButton) editButton.hidden = isDisabled ? false : true;
        return (
            <React.Fragment>
                <div 
                    className={`predictive${isDisabled ? ' is-disabled' : ''}`}
                    ref={this.ref}>
                    {!editButton && (
                        <button
                            className={`predictive__edit-btn mb-1${isDisabled ? ' is-active' : ''}`}
                            type="button"
                            hidden={!isDisabled || null}
                            onClick={isDisabled ? this._onEditClick : null}
                        >
                            <SvgIcon size={['17']} name="pencil" />
                            <span>Edit</span>
                        </button>
                    )}
                    <Autosuggest
                        ref={(instance) => {
                            this.autosuggestInstance = instance;
                        }}
                        theme={{
                            ...defaultTheme,
                            input: `${defaultTheme.input} ${inputClass}`,
                        }}
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        getSuggestionValue={this.getSuggestionValue}
                        renderSuggestion={this.getSuggestionValue}
                        onSuggestionSelected={this.onSuggestionSelected}
                        onValueChange={this.onValueChange}
                        inputProps={inputProps}
                    />
                </div>
                {errorMessage && errorIsActive && (
                    <ul className="form-control-errors filled">
                        <li>{errorMessage}</li>
                    </ul>
                )}
            </React.Fragment>
        );
    }

    componentDidMount() {
        if (this.autosuggestInstance) this.input = this.autosuggestInstance.input;

        this._getParentScroll();
    }
}

AddressPredictiveField.propTypes = {
    attributes: propTypes.obj,
    labelNode: propTypes.obj,
    editButton: propTypes.obj,
    errorMessage: propTypes.string,
    target: propTypes.string,
    wrapperTarget: propTypes.string
};

export default AddressPredictiveField;
