import './scss/index.scss';

import { dcFactory } from '@deleteagency/dc';
import SidebarNavComponent from './js/sidebar-nav.component';
import SidebarMenuUserComponent from './js/sidebar-menu-user.component';
import SidebarMenuBurgerComponent from './js/sidebar-menu-burger.component';

dcFactory.register(SidebarNavComponent);
dcFactory.register(SidebarMenuUserComponent);
dcFactory.register(SidebarMenuBurgerComponent);
