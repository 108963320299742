import ControlRadio from './control-radio';
import ControlSelect from './control-select';

const CONTROL_TYPE_RADIO = 'radio';
const CONTROL_TYPE_SELECT = 'select';

export default class ControlAdapter {
    #controlRealization;
    #toggleControlType;
    #trigger;
    #triggerBuy;
    #triggerRent;
    #addBuyCb;
    #addRentCb;
    #removeCb;

    constructor(
        toggleControlType,
        trigger,
        triggerBuy,
        triggerRent,
        addBuyCb,
        addRentCb,
        removeCb
    ) {
        this.#toggleControlType = toggleControlType;
        this.#trigger = trigger;
        this.#triggerBuy = triggerBuy;
        this.#triggerRent = triggerRent;
        this.#addBuyCb = addBuyCb;
        this.#addRentCb = addRentCb;
        this.#removeCb = removeCb;

        this.#init();
    }

    #init() {
        switch (this.#toggleControlType) {
            case CONTROL_TYPE_RADIO:
                this.#initRadio();
                break;
            case CONTROL_TYPE_SELECT:
                this.#initSelect();
                break;
            default:
                break;
        }
    }

    #initRadio() {
        this.#controlRealization = new ControlRadio(
            this.#triggerBuy,
            this.#triggerRent,
            this.#addBuyCb,
            this.#addRentCb,
            this.#removeCb
        );
    }

    #initSelect() {
        this.#controlRealization = new ControlSelect(
            this.#trigger,
            this.#addBuyCb,
            this.#addRentCb,
            this.#removeCb
        );
    }
}
