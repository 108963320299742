import { DcBaseComponent, dcFactory } from '@deleteagency/dc';
import api from 'general/js/api';
import { createFragment, createElement } from '@deleteagency/dom-helper';

export default class CookieLoaderComponent extends DcBaseComponent {
    static getNamespace() {
        return 'cookie-loader';
    }

    async onInit() {
        const contentHtmlString = await this.getContent(this.options.contentEndpointUrl);
        const rootElement = this.generateHtml(contentHtmlString);
        this.initCookieBanner(rootElement);
    }

    generateHtml(htmlString) {
        const htmlFragment = createFragment(htmlString);
        const tempContainer = createElement('<div></div>');
        tempContainer.appendChild(htmlFragment);
        document.body.appendChild(tempContainer);
        return tempContainer;
    }

    initCookieBanner(root) {
        dcFactory.init(root);
    }

    async getContent(url) {
        const res = await api.get(url);
        return res.data;
    }
}
