import { appConfig } from 'general/js/app-config';
import { searchSaveService, siteNoti } from 'general/js/save-service';
import SvgIcon from 'general/js/svg-icon';
import React from 'preact/compat';
import PropTypes from 'prop-types';

class SavedSearches extends React.Component {
    constructor(props) {
        super(props);

        this.state = { items: searchSaveService.getItems() };
        searchSaveService.subscribeOnChanges(this._onChangeSearches);
    }

    _onCrossClick = (id) => {
        const { items } = this.state;
        this.setState({ items: items.filter((item) => item.id !== id) });
        siteNoti.showNoti({ name: appConfig.translations.removedSearch });
        searchSaveService.delete(id);
    };

    _onChangeSearches = (items) => {
        this.setState({ items });
    };

    _onChangeStatus = (e, id) => {
        const status = e.target.value;
        searchSaveService.updateItem(id, status);
    };

    render() {
        const { items } = this.state;
        const { placeholder, updateCaption, updateOptions } = this.props;
        return items.length === 0 ? (
            <p dangerouslySetInnerHTML={{ __html: placeholder }}></p>
        ) : (
            <ul className="saved-searches">
                {items.map((item) => (
                    <li key={item.id} className="saved-search-card">
                        <h4 className="saved-search-card__title">
                            <a
                                className="saved-search-card__link"
                                href={item.url}
                                dangerouslySetInnerHTML={{ __html: item.title }}
                            />
                        </h4>
                        <div className="saved-search-card__update">
                            <label
                                className="saved-search-card__update-label"
                                for={`update-${item.id}`}
                            >
                                {updateCaption}
                            </label>
                            <div className="saved-search-card__update-select-container">
                                <select
                                    className="saved-search-card__update-select"
                                    id={`update-${item.id}`}
                                    onChange={(e) => this._onChangeStatus(e, item.id)}
                                    value={item.alertFrequency || 100}
                                >
                                    {updateOptions.map((option) => (
                                        <option
                                            key={`${item.id}_${option.value}`}
                                            value={option.value}
                                        >
                                            {option.caption}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <button
                            className="saved-search-card__delete btn-close"
                            type="button"
                            onClick={() => this._onCrossClick(item.id)}
                        >
                            <SvgIcon size={['22']} name="cross" />
                        </button>
                    </li>
                ))}
            </ul>
        );
    }
}

SavedSearches.propTypes = {
    placeholder: PropTypes.string.isRequired,
    updateOptions: PropTypes.object.isRequired,
    updateCaption: PropTypes.string.isRequired,
};

export default SavedSearches;
