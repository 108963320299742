import { DcBaseComponent } from '@deleteagency/dc';
import { eventBus } from 'general/js/events';
import { EVENT_CLOSEST_LOCATION_RECEIVED } from './location-button.component';
import PredictiveInput from './predictive-input';

export default class PredictiveInputComponent extends DcBaseComponent {
    static getNamespace() {
        return 'predictive';
    }

    static getRequiredRefs() {
        return ['input'];
    }

    onInit() {
        const { input } = this.refs;
        this.predictiveInput = new PredictiveInput({
            input,
            endpointUrl: this.options.endpointUrl,
            onSelect: this._onSuggestionSelected,
        });
        this.predictiveInput.init();
        eventBus.addListener(
            EVENT_CLOSEST_LOCATION_RECEIVED,
            this._onClosestLocationReceived
        );
    }

    _onSuggestionSelected = (event, { suggestion, method }) => {
        if (method === 'enter') {
            event.preventDefault();
        }

        if (this.refs.place) this.refs.place.value = suggestion.id;
    };

    _onClosestLocationReceived = ({ location, target }) => {
        if (location && target === this.options.id) {
            this.predictiveInput.value = location.text;
        }
    };
}
