import { DcBaseComponent } from '@deleteagency/dc';
import { eventBus, events } from 'general/js/events';

export default class SubnavTabTriggerComponent extends DcBaseComponent {
    static getNamespace() {
        return 'subnav-tab-trigger';
    }

    onInit() {
        this.eventId = this.options.customEventId || events.EVENT_SUBNAV_TAB_TRIGGER;

        this.addListener(this.element, 'click', () => {
            eventBus.emit(this.eventId, this.element.getAttribute('data-trigger'));
        });
    }
}
