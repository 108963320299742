import { DcBaseComponent } from '@deleteagency/dc';
import { render } from 'preact';
import React from 'preact/compat';
import LocalOfficeSearchBar from './local-office-search-bar';

export default class LocalOfficeSearchBarComponent extends DcBaseComponent {
    static getNamespace() {
        return 'local-office-search-bar';
    }

    onInit() {
        const { placeholder, endpointUrl, value } = this.options;
        render(<LocalOfficeSearchBar
            placeholder={placeholder}
            endpointUrl={endpointUrl}
            value={value}/>, this.element);
    }
}
