import axios from 'axios';
import { getUrlByParams } from 'general/js/url-helper';
import { renameKey, convertObjectsKeysToCamelCase } from 'general/js/object-helper';

const loquateApi = axios.create({
    timeout: 15000
});

const LOQATE_API_KEY = 'NC98-JG44-GN11-DJ93';
const LOQATE_BASE_URL = 'https://api.addressy.com/';
const LAT_LNG_PARAMS = { Field1Format: '{Latitude}', Field2Format: '{Longitude}' };

const API_KEY_PARAMETER_NAME = 'Key';
const ISO_CODE_GREAT_BRITAIN = 'gb';
const MAX_RESULTS = 100;

const ENTITY_TYPE_POSTCODE = 'Postcode';

const ERROR_CODE_NOT_RESPONDED_IN_TIME = 1005;

class LoquateService {
    constructor() {
        this.baseUrl = LOQATE_BASE_URL;
        this.apiKey = LOQATE_API_KEY;
    }

    checkEmail() {}

    checkPhone() {}

    async getAddressesByFreeText(text) {
        return await this._performRequest('Capture/Interactive/Find/v1.10/json3.ws', {
            Text: text,
            Origin: ISO_CODE_GREAT_BRITAIN,
            Countries: ISO_CODE_GREAT_BRITAIN,
            Limit: MAX_RESULTS
        });
    }

    async getAddressesByPostCode(text) {
        const postcodeId = await this._findPostCodeId(text);
        if (postcodeId) {
            return this._findAddresses(postcodeId);
        }
        throw new Error('No post code was found for the provided query');
    }

    async getAddressesById(id) {
        return this._findAddresses(id);
    }

    async getAddressDetails(id) {
        const items = await this._retrieveAddress(id);
        renameKey(items[0], 'Field1', 'Latitude');
        renameKey(items[0], 'Field2', 'Longitude');
        convertObjectsKeysToCamelCase(items[0]);
        return items[0];
    }

    async _findPostCodeId(text) {
        const items = await this._performRequest('Capture/Interactive/Find/v1.10/json3.ws', {
            Text: text,
            Origin: ISO_CODE_GREAT_BRITAIN,
            Countries: ISO_CODE_GREAT_BRITAIN,
            Limit: MAX_RESULTS
        });
        const postcodeItem = items.find(item => item.Type === ENTITY_TYPE_POSTCODE);
        if (postcodeItem) {
            return postcodeItem.Id;
        }

        return null;
    }

    async _findAddresses(postcodeId) {
        return this._performRequest('Capture/Interactive/Find/v1.10/json3.ws', {
            Container: postcodeId
        });
    }

    async _retrieveAddress(id) {
        return this._performRequest('Capture/Interactive/Retrieve/v1.00/json3.ws', { Id: id, ...LAT_LNG_PARAMS });
    }

    _getApiUrl(methodPath, params) {
        return getUrlByParams(`${this.baseUrl}${methodPath}`, {
            ...params,
            [API_KEY_PARAMETER_NAME]: this.apiKey
        });
    }

    async _performRequest(methodPath, params) {
        try {
            const response = await loquateApi.get(this._getApiUrl(methodPath, params));
            const data = response.data;

            const items = data.Items;
            if (items && Array.isArray(items)) {
                if (items.length > 0 && 'Error' in items[0]) {
                    const error = items[0];
                    this._handleApiError(error.Error);
                }
                // in case we have zero items - return it like success empty result
                return items;
            }
            this._throwUnknownError();
        } catch (e) {
            this._throwUnknownError();
        }
    }

    _handleApiError(code) {
        switch (code) {
            case ERROR_CODE_NOT_RESPONDED_IN_TIME:
                throw new Error(
                    'We couldn\'t get the response, the server didn\'t respond in time. Please try again in a few minutes'
                );
            default:
                this._throwUnknownError();
        }
    }

    _throwUnknownError() {
        throw new Error(
            'An error occurred during your request. Please, try again in a few minutes'
        );
    }
}

export default new LoquateService();
