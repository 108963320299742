import { DcBaseComponent } from '@deleteagency/dc';

export default class TogglePsswordComponent extends DcBaseComponent {
    static getNamespace() {
        return 'toggle-password';
    }

    async onInit() {
        this.isVisible = false;
        this.addListener(this.refs.togglePasswordButton, 'click', this._onToggle);
    }

    _onToggle = () => {
        this.refs.togglePasswordInput.setAttribute('type', !this.isVisible ? 'text' : 'password');
        this.isVisible = !this.isVisible;
    };
}
