import { DcBaseComponent } from '@deleteagency/dc';
import { eventBus, events } from 'general/js/events';
import { camelCase } from 'lodash';

const TAB_SEPARATOR = '/';

export default class SubnavTabsMenuHashComponent extends DcBaseComponent {
    static getNamespace() {
        return 'subnav-tabs-menu-hash';
    }

    onInit() {
        eventBus.addListener(events.EVENT_SUBNAV_TAB_TRIGGER, this._setUrlHashFromTabId);

        this._handleUrlHashOnInit();
    }

    _handleUrlHashOnInit() {
        const tabIds = this._getTabIdsFromUrlHash();
        if (tabIds && tabIds.length > 0) {
            eventBus.emit(events.EVENT_SUBNAV_TAB_TRIGGER, tabIds[0]);
        }
    }


    _setUrlHashFromTabId(tabId) {
        if (tabId) {
            if (history.pushState) {
                history.pushState(null, null, `#${tabId}`);
            } else {
                window.location.hash = tabId;
            }
        }
    }

    _getTabIdsFromUrlHash() {
        if (window.location.hash.slice(1)) {
            const tabIds = window.location.hash.slice(1).split(TAB_SEPARATOR);
            const pascalCaseIds = tabIds.map((id) => {
                return camelCase(id);
            });

            return pascalCaseIds;
        }

        return null;
    }
}
