import React from 'preact/compat';
import propTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { defaultTheme } from 'react-autosuggest/dist/theme';
import { AxiosSingular } from '@deleteagency/axios-singular';
import axios from 'axios';
import api from 'general/js/api';

class Predictive extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            suggestions: [],
        };

        this.axionSingluar = new AxiosSingular(
            api,
            axios.CancelToken,
            axios.isCancel
        );

        if (this.props.setStartValue) {
            const { placeholder } = this.props;
            this.setValue(placeholder);
        }
    }

    onValueChange = (event, { newValue }) => {
        this.props.onValueChange(newValue);
    };

    setValue = (value) => {
        this.props.setValue(value);
    }

    resetValue = (e) => {
        e.preventDefault();
        this.props.setValue('');
        this.props.onValueChange('');

        if (this.props.inputRef) {
            this.props.inputRef.focus(); 
        }
    }

    getSuggestionValue = (suggestion) => suggestion.text;
    
    renderSuggestion = (suggestion) => {
        const { value } = this.props;
        const suggestionText = suggestion.text;
        
        const startIndex = suggestionText.toLowerCase().indexOf(value.toLowerCase());
    
        if (startIndex !== -1) {            
            const beforeMatch = suggestionText.substring(0, startIndex);
            const matchedText = suggestionText.substring(startIndex, startIndex + value.length);
            const afterMatch = suggestionText.substring(startIndex + value.length);

            const reworkedText = <span>{beforeMatch}<strong>{matchedText}</strong>{afterMatch}</span>;
            return reworkedText
        } else {
            return suggestionText;
        }
    };
    
    onSuggestionsFetchRequested = ({ value }) => {
        this.axionSingluar
            .get(this.props.endpointUrl, {
                params: {
                    text: value,
                },
            })
            .then(
                (response) => {
                    this.setState({ suggestions: response.data.suggestions });
                },
                (error) => {
                    if (!axios.isCancel(error)) {
                        console.error('Actual error!', error);
                    }
                }
            );
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    getTheme() {
        return {
            ...defaultTheme,
            input: this.getInputClass(),
        };
    }

    getInputClass() {
        return `${defaultTheme.input} ${this.props.inputClass}`;
    }

    onClosestPlaceReceived = (location) => {
        this.props.onValueChange(location.text);
    };

    render() {
        const { suggestions } = this.state;
        const {
            value,
            placeholder,
            name,
            onSuggestionSelected,
            renderInputComponent,
        } = this.props;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder,
            value,
            name,
            onChange: this.onValueChange,
        };


        return (
            <div className={`predictive ${this.props.value.length > 0 ? 'predictive--clear' : ''}`}>
                <Autosuggest
                    theme={this.getTheme()}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={
                        this.onSuggestionsFetchRequested
                    }
                    onSuggestionsClearRequested={
                        this.onSuggestionsClearRequested
                    }
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    onSuggestionSelected={onSuggestionSelected}
                    renderInputComponent={renderInputComponent}
                    inputProps={inputProps}
                />
                
                    <button 
                        type="reset" 
                        onClick={this.resetValue}
                        className="predictive__clear-btn">
                            <span class="predictive__clear-text">Clear search</span>
                            <svg class="predictive__clear-icon" width="24" height="24"><use xlinkHref="#icon-cross-new" /></svg>
                    </button>
                
            </div>
        );
    }
}

Predictive.defaultProps = {
    placeholder: '',
    inputClass: '',
};

Predictive.propTypes = {
    name: propTypes.string,
    placeholder: propTypes.string,
    inputClass: propTypes.string,
    endpointUrl: propTypes.string.isRequired,
    value: propTypes.string.isRequired,
    onValueChange: propTypes.func.isRequired,
    setValue: propTypes.func.isRequired,
    onSuggestionSelected: propTypes.func,
    renderInputComponent: propTypes.func,
    inputRef: propTypes.element
};

export default Predictive;
