import { DcBaseComponent } from '@deleteagency/dc';
import { createEvent } from '@deleteagency/dom-helper';
import get from 'lodash/get';
import userService, { EVENT_GOT_USER_INFO } from 'general/js/user/user-service';
import { eventBus } from 'general/js/events';

const INPUTS_SELECTOR = 'input, textarea, select';
const INPUTS_SELECTOR_EXCLUDED =
    'input[type=button], input[type=submit], input[type=reset], input[type=hidden]';

const BIND_ATTRIBUTE = 'user-bind';
const BIND_MULTIPLE_ATTRIBUTE = 'user-bind-multiple';
const CONDITION_ATTRIBUTE = 'user-if';
const CSS_HIDDEN_CLASS = 'is-hidden';

export default class UserDataComponent extends DcBaseComponent {
    #user = null;

    static getNamespace() {
        return 'user-data';
    }

    onInit() {
        this.applyConditions();
        if (userService.user !== null) {
            this.#gotUser(userService.user);
        }
        eventBus.addListener(EVENT_GOT_USER_INFO, this.#gotUser);
    }

    #gotUser = (user) => {
        if (this.#user === undefined || this.#user !== user) {
            this.#user = user;
            this.applyModel();
        }
    };

    getModel() {
        const isLoggedIn = !!this.#user;
        const user = this.#user || {};
        return {
            isLoggedIn,
            ...user
        };
    }

    applyModel() {
        this.applyConditions();
        this.applyBindings();
    }

    applyConditions() {
        const conditionElements = [...this.element.querySelectorAll(`[${CONDITION_ATTRIBUTE}]`)];
        conditionElements.forEach((el) => {
            let cond = false;
            let inverse = false;

            let prop = el.getAttribute(CONDITION_ATTRIBUTE);
            if (prop) {
                if (prop[0] === '!') {
                    inverse = true;
                    prop = prop.substring(1);
                }
                cond = this.getModelValue(prop, false);
            }

            cond = inverse ? !cond : cond;
            if (!cond) {
                el.classList.add(CSS_HIDDEN_CLASS);
            } else {
                el.classList.remove(CSS_HIDDEN_CLASS);
            }
        });
    }

    applyBindings() {
        const bindElements = [...this.element.querySelectorAll(`[${BIND_ATTRIBUTE}]`)];
        const inputElements = [];
        const textElements = [];

        bindElements.forEach((element) => {
            if (element.matches(INPUTS_SELECTOR) && !element.matches(INPUTS_SELECTOR_EXCLUDED)) {
                inputElements.push(element);
            } else {
                textElements.push(element);
            }
        });

        this.applyInputsBindings(inputElements);
        this.applyTextBindings(textElements);
    }

    applyInputsBindings(elements) {
        elements.forEach((el) => {
            const prop = el.getAttribute(BIND_ATTRIBUTE);
            this.applyInputValue(el, this.getModelValue(prop));
        });
    }

    applyInputValue(input, value) {
        switch (input.type) {
            case 'checkbox':
                this.applyCheckboxValue(input, value);
                break;
            case 'radio':
                break;
            case 'select-multiple':
                break;
            default:
                input.value = value || '';
        }
        input.dispatchEvent(createEvent('change'));
    }

    applyCheckboxValue(input, value) {
        if (input.hasAttribute(BIND_MULTIPLE_ATTRIBUTE)) {
            const modelValue = value || [];
            const inputValue = input.value;
            input.checked = modelValue.includes(inputValue);
        } else {
            input.checked = value || false;
        }
    }

    getModelValue(prop, defaultValue) {
        return get(this.getModel(), prop, defaultValue);
    }

    applyTextBindings(elements) {
        elements.forEach((el) => {
            const prop = el.getAttribute(BIND_ATTRIBUTE);
            if (prop) {
                el.innerHTML = this.getModelValue(prop, '');
            }
        });
    }
}
