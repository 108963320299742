import { DcBaseComponent } from '@deleteagency/dc';
import notificationsService from 'general/js/notifications-service';
import userService from 'general/js/user/user-service';
import BaseForm from 'general/js/forms/base-form';
import GtmHelper from 'components/gtm-trigger/js/gtm-helper';
import ElementSpinner from 'general/js/spinner/element-spinner';

export default class RegisterFormComponent extends DcBaseComponent {
    static getNamespace() {
        return 'register-form';
    }

    async onInit() {
        this.spinner = new ElementSpinner(this.element, { modifiers: ['with-overlay'] });
        this.form = new BaseForm(this.element, {
            onBeforeSubmit: this._onBeforeSubmit,
            submitElement: this.refs.submit,
            errorsSummaryElement: this.refs.errorsSummary,
            onSuccessfulSubmit: this._onSuccessfulSubmit,
            onFailedSubmit: this._onFailedSubmit
        }, this);
    }

    _onSuccessfulSubmit = ({ data }) => {
        this.spinner.hide();
        GtmHelper.send(null, { category: 'My Winkworth', action: 'Sign up', label: 'My Winkworth Sign up' });
        userService.refetchUser();
        notificationsService.createFlash(data.successMessage);
    };

    _onBeforeSubmit = () => {
        this.spinner.show();
    };

    _onFailedSubmit = (error) => {
        this.spinner.hide();
        console.error(error);
    };
}
