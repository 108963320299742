import SaveService from 'general/js/save-service/save-service';
import SiteNoti from 'general/js/save-service/site-noti';
import RecentService from 'general/js/save-service/recent-service';

const SEARCHES = {
    name: 'searches',
    needGetOnSave: false,
    startCollection: {},
    endpoints: {
        get: 'api/mySaved/search/get',
        save: 'api/mySaved/search/save',
        delete: 'api/mySaved/search/delete',
        update: 'api/mySaved/search/update'
    }
};
const PROPERTIES = {
    name: 'properties',
    needGetOnSave: true,
    startCollection: {},
    endpoints: {
        get: 'api/mySaved/properties/get',
        save: 'api/mySaved/properties/save',
        delete: 'api/mySaved/properties/delete'
    }
};
const RECENT = {
    name: 'recent',
    startCollection: []
};

export const searchSaveService = new SaveService(SEARCHES);
export const propertiesSaveService = new SaveService(PROPERTIES);
export const recentSearchService = new RecentService(RECENT);
export const siteNoti = new SiteNoti();
