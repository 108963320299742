import { DcBaseComponent } from '@deleteagency/dc';
import nanoid from 'nanoid';
import { eventBus } from 'general/js/events';
import analyticsService from 'general/js/analytics-service';

export const EVENT_RV_RADIO_CHANGE = 'rvRadioChanged';

export default class RvRadio extends DcBaseComponent {
    static getNamespace() {
        return 'rv-radio';
    }

    static getRequiredRefs() {
        return ['inputs'];
    }

    onInit() {
        this.name = this.refs.inputs[0].getAttribute('name');
        this.id = nanoid();
        eventBus.addListener(EVENT_RV_RADIO_CHANGE, this._onSubscribe);
        this.refs.inputs.forEach((input) => {
            this.addListener(input, 'change', this._onChange);
        });
    }

    _onChange = (e) => {
        analyticsService.sendEvent(
            window.location.href,
            'Book valuation',
            e.target.getAttribute('placeholder')
        );
        eventBus.emit(EVENT_RV_RADIO_CHANGE, {
            [this.name]: e.target.value,
            id: this.id,
            target: this.options.target
        });
    };

    _onSubscribe = (props) => {
        const { id } = props;
        if (id !== this.id) {
            const value = props[this.name];
            this.refs.inputs.forEach((input) => {
                if (input.value === value) input.checked = true;
            });
        }
    };
}
