import { DcBaseComponent } from '@deleteagency/dc';
import GtmHelper from './gtm-helper';

export default class GTMTriggerComponent extends DcBaseComponent {
    static getNamespace() {
        return 'gtm';
    }

    onInit() {
        this.addListener(this.element, 'click', e => GtmHelper.send(e, this.options));
    }
}
