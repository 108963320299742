import { DcBaseComponent } from '@deleteagency/dc';
import BaseForm from 'general/js/forms/base-form';
import ElementSpinner from 'general/js/spinner/element-spinner';

export default class ForgottenPasswordFormComponent extends DcBaseComponent {
    static getNamespace() {
        return 'forgotten-password-form';
    }

    static getRequiredRefs() {
        return ['successMessage'];
    }

    async onInit() {
        this.spinner = new ElementSpinner(this.element, { modifiers: ['with-overlay'] });
        this.form = new BaseForm(this.element, {
            onBeforeSubmit: this._onBeforeSubmit,
            submitElement: this.refs.submit,
            errorsSummaryElement: this.refs.errorsSummary,
            onSuccessfulSubmit: this._onSuccessfulSubmit,
            onFailedSubmit: this._onFailedSubmit
        }, this);
    }

    _onSuccessfulSubmit = ({ data }) => {
        this.spinner.hide();
        this.refs.successMessage.innerHTML = data.successMessage;
    };

    _onBeforeSubmit = () => {
        this.spinner.show();
    };

    _onFailedSubmit = (error) => {
        this.spinner.hide();
        console.error(error);
    };
}
