import { DcBaseComponent } from '@deleteagency/dc';
import GtmHelper from 'components/gtm-trigger/js/gtm-helper';

const TYPE_CHECKBOX = 'checkbox';
const TYPE_TEXT = 'text';

export default class GTMSearchForm extends DcBaseComponent {
    static getNamespace() {
        return 'gtm-search-form';
    }

    onInit() {
        this.addListener(this.element, 'submit', this._onSubmit);
    }

    _onSubmit = () => {
        const elements = [...this.element.elements].filter((input) => input.matches('select, [type=text], [type=checkbox]'));
        const choose = (input) => {
            switch (true) {
                case !!input.options:
                    return input.options[input.selectedIndex].textContent;
                case (input.type === TYPE_TEXT && !!input.value && input.value.length > 0):
                    return input.value;
                case (input.type === TYPE_CHECKBOX):
                    return input.checked + '';
                default:
                    return '';
            }
        };
        const label = elements.map((input) => (
            (input.labels[0] ? input.labels[0].textContent + ': ' : '') +
            (choose(input))
        )).join(' > ');
        GtmHelper.send(null, {
            category: this.options.category,
            action: this.options.action,
            label
        });
    };
}
