import { DcBaseComponent } from '@deleteagency/dc';
import VideoCreator from 'general/js/video/creator';
import { pageSpinner, ElementSpinner } from 'general/js/spinner';
import { eventBus, events } from 'general/js/events';
import { MODAL_WINDOW_CLOSE } from 'general/js/modal';

export default class VideoComponent extends DcBaseComponent {
    constructor(element, extendedOptions = {}, onPlayTriggered = null) {
        super(element);

        this.isStarted = false;
        this.onPlayTriggered = onPlayTriggered;
        this.options = { ...this.options, ...extendedOptions };
    }

    static getNamespace() {
        return 'video';
    }

    onInit() {
        this.inline = this.options.inline;

        const options = this.options;
        let playerElement = this.refs.player;
        if (!playerElement) {
            playerElement = document.createElement('div');
        }
        options.playerElement = playerElement;

        if (this.refs.playButton) {
            this.addListener(this.refs.playButton, 'click', this.onPlayButtonClick);
        } else {
            this.addListener(this.element, 'click', this.onPlayButtonClick);
        }

        if (options.customTabId) {
            eventBus.addListener(events.EVENT_VIDEO_PAUSE, this.onTabClose);
        }

        this.spinner = this.inline ? new ElementSpinner(this.element) : pageSpinner;
        options.onExternalStop = this.hideSpinner;

        /**
         * @type {AbstractVideo}
         */
        this.video = VideoCreator.createFromOptions(options);
        this.video.onStart(this.handleOnPlay);

        if (!this.refs.thumbnail && this.refs.preview) {
            this.loadThumbnail();
        }

        eventBus.addListener(MODAL_WINDOW_CLOSE, this.onModalClose);
    }

    onPlayButtonClick = (e) => {
        if (this.options.id && this.options.id.length > 0) {
            e.preventDefault();
            this.play();
        } else {
            console.log('there is no Video id, nothing to play');
        }
    };

    getStateElement() {
        return this.refs.video ? this.refs.video : this.element;
    }

    // on video first time launched
    handleOnPlay = () => {
        if (!this.isStarted) {
            this.isStarted = true;
            // after first launch in case of inline player we should do the stuff
            if (this.inline) {
                this.getStateElement().classList.add('is-started-inline');
            }
        }
    };

    async play() {
        if (!this.video.isStarting) {
            if (!this.isStarted) {
                if (this.inline) {
                    this.getStateElement().classList.add('is-play-triggered-inline');
                }
                if (this.onPlayTriggered) {
                    this.onPlayTriggered();
                }
            }

            this.showSpinner();
            try {
                await this.video.play();
            } catch (e) {
                console.error(e);
            }

            this.hideSpinner();
        }
    }

    getVideo() {
        return this.video;
    }

    showSpinner = () => {
        this.spinner.show();
    };

    hideSpinner = () => {
        this.spinner.hide();
    };

    loadThumbnail() {
        const thumbnailUrlPromise = this.video.getThumbnailUrl();
        if (thumbnailUrlPromise) {
            thumbnailUrlPromise.then((thumbnailUrl) => {
                if (thumbnailUrl) {
                    const img = document.createElement('img');
                    img.setAttribute('data-src', thumbnailUrl);
                    img.className = 'lazyload';
                    this.refs.preview.insertAdjacentElement('afterbegin', img);
                }
            });
        }
    }

    onTabClose = (tabId) => {
        if (this.options.customTabId === tabId) {
            this.video.pause();
        }
    };

    onModalClose = () => {
        this.video.pause();
    };
}
