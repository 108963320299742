import './scss/index.scss';

import { dcFactory } from '@deleteagency/dc';
import StickySubnavComponent from './js/sticky-subnav.component';
import SubnavTabsComponent from './js/subnav-tabs.component';
import SubnavTabsMenuComponent from './js/subnav-tabs-menu.component';
import SubnavTabTriggerComponent from './js/subnav-tab-trigger.component';
import SubnavDropComponent from './js/subnav-drop.component';
import SubnavTabsHashComponent from './js/subnav-tabs-hash.component';
import SubnavTabsMenuHashComponent from './js/subnav-tabs-menu-hash.component';

dcFactory.register(StickySubnavComponent);
dcFactory.register(SubnavTabsComponent);
dcFactory.register(SubnavTabsMenuComponent);
dcFactory.register(SubnavTabTriggerComponent);
dcFactory.register(SubnavDropComponent);
dcFactory.register(SubnavTabsHashComponent);
dcFactory.register(SubnavTabsMenuHashComponent);
