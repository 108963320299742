import { render } from 'preact';
import React from 'preact/compat';
import PredictiveStandalone from './predictive-standalone';

export default class PredictiveInput {
    constructor(props) {
        this._input = props.input;
        this._endpointUrl = props.endpointUrl;
        this._onSelect = props.onSuggestionSelected;
        this._setStartValue = this._input.parentNode.hasAttribute('data-dc-predictive-value');
    }

    set value(val) {
        if (this._predictiveInstance) {
            this._predictiveInstance.setValue(val);
        } else {
            this._input.value = val;
        }
    }

    init = () => {
        if (this._input) {
            this._predictiveInstance = null;

            var shouldFocus = document.activeElement === this._input;
            this._activate(shouldFocus);
        }
    };

    _activate = (shouldFocus) => {
        const {
            placeholder, name, value, className
        } = this._input;

        const parentElement = this._input.parentElement;
        parentElement.removeChild(this._input);

        this._render(parentElement, {
            placeholder, name, value, className
        });

        if (shouldFocus) this._focusInput();
    };

    _predictiveRef = (predictiveInstance) => {
        this._predictiveInstance = predictiveInstance;
    };

    _focusInput() {
        if (this._predictiveInstance) {
            this._predictiveInstance.focus();
        }
    }

    _render(parentElement, {
        placeholder, name, value, className
    }) {
        render(
            <PredictiveStandalone
                placeholder={placeholder}
                name={name}
                inputClass={className}
                value={value}
                setStartValue={this._setStartValue}
                endpointUrl={this._endpointUrl}
                ref={this._predictiveRef}
                onSuggestionSelected={this._onSelect}/>,
            parentElement
        );
    }
}
