import { DcBaseComponent } from '@deleteagency/dc';
import focusLock from 'dom-focus-lock';
import { eventBus, events } from 'general/js/events';
import { menuService } from 'general/js/menu';
import { initScrollEmitter } from 'general/js/scrollEmitter';
import constants from './constants';

export default class HeaderComponent extends DcBaseComponent {
    static getNamespace() {
        return 'header';
    }

    static getRequiredRefs() {
        return ['megaMenu'];
    }

    onInit() {
        this.isScrolled = false;
        this.changeOnScroll = this.options.changeOnScroll;
        menuService.register(constants.MEGA_MENU_ID, this);
        this.isOpened = false;
        if (this.changeOnScroll) {
            initScrollEmitter();
            this.checkScroll();
            eventBus.addListener('pageScrolled', this.checkScroll);
        }
        if ('transition' in document.documentElement.style) {
            this.refs.megaMenu.addEventListener('transitionend', (e) =>
                this._transitionFinished(e, this.isOpened)
            );
        }
    }

    onOpen() {
        eventBus.emit(events.TOGGLE_SCROLL_TRIGGER_EVENT, false);
        this.isOpened = true;
        this.refs.megaMenu.classList.add('is-opened');
        document.documentElement.classList.add('is-mega-menu-opened');

        if (this.removeForciblyClosedClassTimeout) {
            clearTimeout(this.removeForciblyClosedClassTimeout);
        }

        focusLock.on(this.refs.megaMenu);
    }

    _transitionFinished = (e, isOpened) => {
        if (e.propertyName === 'max-height' && isOpened) {
            this.refs.megaMenu.classList.add('is-expanded');
        }
    };

    onClose(forced) {
        eventBus.emit(events.TOGGLE_SCROLL_TRIGGER_EVENT, true);
        this.isOpened = false;
        this.refs.megaMenu.classList.remove('is-expanded');
        this.refs.megaMenu.classList.remove('is-opened');
        document.documentElement.classList.remove('is-mega-menu-opened');

        if (forced) {
            // in terms of z-index header component should be able to be in 2 different states
            // - under overlay (default)
            // - over overlay when mega menu is opened
            // the second case should be animated so that header is over overlay until overlay is completely hidden
            // but at the same time when closing of the header was triggered because of opening some other menu
            // header should be hidden immediately
            this.element.classList.add('is-mega-menu-forcibly-closed');
            this.removeForciblyClosedClassTimeout = setTimeout(() => {
                this.element.classList.remove('is-mega-menu-forcibly-closed');
                this.removeForciblyClosedClassTimeout = null;
            }, 500);
        }

        focusLock.off(this.refs.megaMenu);
    }

    checkScroll = () => {
        const isScrolled = window.pageYOffset >= 100;
        if (this.isScrolled !== isScrolled) this.isScrolled = isScrolled;

        if (this.isScrolled) {
            this.element.classList.add('is-scrolled');
        } else {
            this.element.classList.remove('is-scrolled');
        }
    };
}
