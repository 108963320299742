import { dcFactory } from '@deleteagency/dc';
import ContactFormComponent from './js/contact-form.component';
import ForgottenPasswordFormComponent from './js/forgotten-password-form.component';
import FormComponent from './js/form.component';
import LoginFormComponent from './js/login-form.component';
import LogoutFormComponent from './js/logout-form.component';
import PasswordStrengthComponent from './js/password-strength.component';
import PortalBtn from './js/portal-btn';
import RangeControlComponent from './js/range-control.component';
import RegisterFormComponent from './js/register-form.component';
import ResetPasswordFormComponent from './js/reset-password-form.component';
import TogglePasswordComponent from './js/toggle-password.component';
import SelectComponent from './js/select.component';
import './scss/index.scss';

dcFactory.register(FormComponent);
dcFactory.register(RegisterFormComponent);
dcFactory.register(LoginFormComponent);
dcFactory.register(LogoutFormComponent);
dcFactory.register(ForgottenPasswordFormComponent);
dcFactory.register(ResetPasswordFormComponent);
dcFactory.register(ContactFormComponent);
dcFactory.register(PortalBtn);
dcFactory.register(TogglePasswordComponent);
dcFactory.register(PasswordStrengthComponent);
dcFactory.register(RangeControlComponent);
dcFactory.register(SelectComponent);
