import { DcBaseComponent } from '@deleteagency/dc';
import { deviceObserver } from '@deleteagency/device-observer';

export default class SocialSharingComponent extends DcBaseComponent {
    _isOpened = false;

    static getNamespace() {
        return 'footer';
    }

    static getRequiredRefs() {
        return ['trigger', 'container', 'content'];
    }

    onInit() {
        if (this.refs.trigger && this.refs.container) {
            this.addListener(this.refs.trigger, 'click', this._onTriggerClick);
            deviceObserver.subscribeOnChange(() => {
                this._reset();
            });
        }
    }

    _reset() {
        this._close();
        this.refs.container.removeAttribute('style');
    }

    _onTriggerClick = (e) => {
        e.preventDefault();
        if (this._isOpened) {
            this._close();
        } else {
            this._open();
        }
    };

    _open() {
        this.refs.trigger.classList.add('is-active');
        this.refs.container.style.maxHeight = this.contentHeight + 'px';
        this._isOpened = true;
    }

    _close() {
        this.refs.trigger.classList.remove('is-active');
        this.refs.container.style.maxHeight = 0 + 'px';
        this._isOpened = false;
    }

    get contentHeight() {
        return this.refs.content.clientHeight;
    }
}
