// @ts-nocheck
var readMoreButtons = document.querySelectorAll('.readMoreToggle');
if (readMoreButtons) {
    readMoreButtons.forEach((button) =>
        button.addEventListener('click', (event) => {
            var readMoreButtonText = button.innerHTML;
            var readMoreTextElement = button.parentElement?.querySelector('.readMoreText');
            if (!readMoreButtonText) return;

            if (readMoreButtonText === 'Read More') {
                button.innerHTML = 'Read Less';
                readMoreTextElement.style.display = 'inline';
                readMoreTextElement.style.transition = 'transform 0.5s, opacity 0.5s';
            } else {
                button.innerHTML = 'Read More';
                readMoreTextElement.style.display = 'none';
                readMoreTextElement.style.transition = 'transform 0.5s, opacity 0.5s';
            }
        })
    );
}
