import { DcBaseComponent } from '@deleteagency/dc';

export default class ContentBlockNew extends DcBaseComponent {
    static getNamespace() {
        return 'content-block';
    }

    onInit() {
        this._isExpanded = false;
        this.addListener(this.refs.more, 'click', this._onShowMoreClick);
    }

    _onShowMoreClick = () => {
        const image = this.refs.image;
        setTimeout(() => {
            if (!this._isExpanded) {
                this.refs.more.classList.add('expanded');
                if (image) image.classList.add('block');
                this.refs.content.style.maxHeight = this.refs.content.scrollHeight + 'px';
            } else {
                this.refs.more.classList.remove('expanded');
                if (image) image.classList.remove('active');
                this.refs.content.style.maxHeight = null;
            }
        }, 100)

        setTimeout(() => {
            this._displayImage();
            this._isExpanded = !this._isExpanded;
        }, 500)
    }

    _displayImage() {
        const image = this.refs.image;
        if (!image) return;
        this._isExpanded ? image.classList.remove('block') : image.classList.add('block')
        this._isExpanded ? image.classList.remove('active') : image.classList.add('active')
    }
}
