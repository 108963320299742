const OPTION_ID_BUY = 'buy';
const OPTION_ID_RENT = 'let';

export default class ControlSelect {
    constructor(trigger, addBuyCb, addRentCb, removeCb) {
        this.trigger = trigger;
        this.addBuyCb = addBuyCb;
        this.addRentCb = addRentCb;
        this.removeCb = removeCb;
        this.#addListeners();
    }

    #addListeners() {
        this.trigger.addEventListener('change', this._onTriggerClick);
    }

    _onTriggerClick = e => {
        const select = e.target;
        const id = select[select.selectedIndex].id;
        switch (id) {
            case OPTION_ID_BUY:
                this.#onBuySelect();
                break;
            case OPTION_ID_RENT:
                this.#onRentSelect();
            default:
                break;
        }
    };

    #onBuySelect = () => {
        this.removeCb();
        this.addBuyCb();
    };

    #onRentSelect = () => {
        this.removeCb();
        this.addRentCb();
    };
}
