// @ts-nocheck
import { DcBaseComponent } from '@deleteagency/dc';
import eventBus from 'general/js/events/bus';
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import { EVENT_INPUT_UPDATED } from '../../search-filters/js/events';
export default class RangeControlComponent extends DcBaseComponent {
    static getNamespace() {
        return 'range-control';
    }

    async onInit() {
        this.slider = this.refs.rangeInput;
        this.isRadius = this.options.isRadius;
        this._addSlider(this._getRange());
        this.slider.noUiSlider.on('update', this._onUpdate);
        eventBus.addListener(EVENT_INPUT_UPDATED, this._onChange);
    }

    _addSlider(range) {
        let start = [];

        const [minEdge, maxEdge] = this.options.edges;

        const _minEdge = Number(minEdge);
        const _maxEdge = Number(maxEdge);

        if (this.refs.minValue && this.refs.maxValue) {
            start = [
                this.refs.minValue.value ? this.refs.minValue.value : _minEdge,
                this.refs.maxValue.value ? this.refs.maxValue.value : _maxEdge,
            ];
        } else {
            start = [this.refs.minValue.value ? this.refs.minValue.value : _minEdge];
        }

        const options = {
            start,
            range,
            connect: start.length > 1 ? true : 'lower',
            snap: true,
            format: {
                to: (value) => {
                    if (value === _minEdge || value === _maxEdge) {
                        return '';
                    }
                    return value;
                },
                from: (value) => value,
            },
            ...(this.isRadius && {
                format: {
                    to: (value) => {
                        if (value === _minEdge) return '';
                        return value
                            .toString()
                            .replace(/^0./, '0-')
                            .concat('-miles');
                    },
                    from: (value) => {
                        if (Number(value) === _minEdge) return _minEdge;
                        return Number(
                            value
                                .replace(/^0-/, '0.')
                                .replace('miles', '')
                                .replace(/-/g, '')
                        );
                    },
                },
            }),
        };

        noUiSlider.create(this.slider, options);
    }

    _getRange() {
        const { options } = this.options;

        const formattedOptions = Object.fromEntries(
            Object.entries(options).map(([key, val]) => {
                let newKey;
                if (key !== '-1') {
                    newKey = key
                        .replace(/^0-/, '0.')
                        .replace('miles', '')
                        .replace(/-/g, '');
                } else {
                    newKey = key;
                }
                return [newKey, val];
            })
        );

        const values = this.isRadius ? Object.keys(formattedOptions) : Object.keys(options);
        const length = values.length - 1;
        const step = 100 / length;
        const sortedValues = values.sort((a, b) => a - b);

        return sortedValues.reduce((acc, key, index) => {
            const value = Number(key);
            if (index === 0) {
                acc['min'] = [value];
            } else if (index === length) {
                acc['max'] = [value];
            } else {
                acc[`${(index * step).toFixed(2)}%`] = [value];
            }
            return acc;
        }, {});
    }

    _onUpdate = (values) => {
        const [min, max] = values;
        const [minEdge, maxEdge] = this.options.edges;
        const { options } = this.options;

        this.refs.minLabel.textContent = options[(min.toString() ? min : minEdge).toString()];
        this.refs.minValue.value = min;
        if (this.refs.maxLabel) {
            this.refs.maxLabel.textContent = options[(max.toString() ? max : maxEdge).toString()];
        }
        if (this.refs.maxValue) {
            this.refs.maxValue.value = max;
        }
    };

    _onChange = (el) => {
        if (this.refs?.minValue?.isEqualNode(el) || this.refs?.maxValue?.isEqualNode(el)) {
            const [minEdge, maxEdge] = this.options.edges;
            if (this.refs.minValue && this.refs.maxValue) {
                this.slider.noUiSlider.set([minEdge, maxEdge]);
            } else {
                this.slider.noUiSlider.set(minEdge);
            }
        }
    };
}
