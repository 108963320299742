import { DcBaseComponent } from '@deleteagency/dc';
import { eventBus, events } from 'general/js/events';
import { kebabCase, camelCase } from 'lodash';

const TAB_SEPARATOR = '/';
const TABS = [
    {
        prefix: 'propertyTab',
        separator: '',
        event: events.EVENT_SUBNAV_TAB_TRIGGER
    },
    {
        prefix: 'SubTab',
        separator: '/',
        event: 'SubTab'
    }
];

export default class SubnavTabsHashComponent extends DcBaseComponent {
    static getNamespace() {
        return 'subnav-tabs-hash';
    }

    onInit() {
        TABS.forEach((element) => {
            eventBus.addListener(element.event, this._setUrlHashFromTabId);
        });
        this._handleUrlHashOnInit();
    }

    _handleUrlHashOnInit() {
        const tabIds = this._getTabIdsFromUrlHash();

        if (tabIds) {
            tabIds.reduce((acc, element, index) => {
                acc = acc + TABS[index].prefix + element;
                eventBus.emit(TABS[index].event, acc);
                return acc;
            }, '');
        }
    }

    _setUrlHashFromTabId(tabId) {
        let formattedId = tabId;
        TABS.forEach((element) => {
            formattedId = formattedId.replace(element.prefix, element.separator);
        });
        formattedId = formattedId.split(TAB_SEPARATOR).map((id) => kebabCase(id)).join(TAB_SEPARATOR);
        window.location.hash = formattedId;
    }

    _getTabIdsFromUrlHash() {
        if (window.location.hash.slice(1)) {
            const tabIds = window.location.hash.slice(1).split(TAB_SEPARATOR);
            const pascalCaseIds = tabIds.map((id) => {
                const camelId = camelCase(id);
                return camelId[0].toUpperCase() + camelId.slice(1);
            });
            return pascalCaseIds;
        }

        return null;
    }
}
