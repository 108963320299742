import RootService from 'general/js/save-service/root-service';
import { isEqual } from 'general/js/object-helper';

const LS_FLAG_NAME = 'recentNeed';

export default class RecentService extends RootService {
    searched = () => {
        this._toggleflag(true);
    };

    checkNeeded = (search) => {
        if (this._checkFlag()) {
            const index = this._getIndex(search);
            if (index >= 0) {
                this._items.sort(item => (item.url === this._items[index].url ? -1 : 0));
            } else {
                this._items.unshift(search);
                if (this._items.length > 5) this._items.pop();
            }
            this._writeInLocalStorage();
            this._callSubscribers();
            this._toggleflag(false);
        }
    };

    _getIndex = search => this.getItems().findIndex(item => isEqual(item, search));

    _checkFlag = () => window.localStorage.getItem(LS_FLAG_NAME);

    _toggleflag = (state) => {
        window.localStorage[state ? 'setItem' : 'removeItem'](LS_FLAG_NAME, 'true');
    };
}
