export default class ControlRadio {
    constructor(triggerBuy, triggerRent, addBuyCb, addRentCb, removeCb) {
        this.triggerBuy = triggerBuy;
        this.triggerRent = triggerRent;
        this.addBuyCb = addBuyCb;
        this.addRentCb = addRentCb;
        this.removeCb = removeCb;
        this.#addListeners();
    }

    #addListeners() {
        this.triggerBuy.addEventListener('click', this._onBuyClick);
        this.triggerRent.addEventListener('click', this._onRentClick);
    }
    _onBuyClick = () => {
        this.removeCb();
        this.addBuyCb();
    };

    _onRentClick = () => {
        this.removeCb();
        this.addRentCb();
    };
}
