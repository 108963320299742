import { DcBaseComponent } from '@deleteagency/dc';
import Scroller from 'general/js/scroller';

export default class AnchorScrollComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);
        this.scroller = null;
        this.anchor = null;
    }

    static getNamespace() {
        return 'anchor-scroll';
    }

    onInit() {
        const hash = this.element.hash;

        const id = hash.replace('#', '');
        this.anchor = document.getElementById(id);
        if (this.anchor) {
            this.scroller = this._scroller;
            this.element.addEventListener('click', this._onTriggerClick);
        }
    }

    get _scroller() {
        return new Scroller(this.anchor, this._config);
    }

    get _config() {
        return { scrollOffset: this.options.scrollOffset || 0 };
    }

    _onTriggerClick = (e) => {
        e.preventDefault();
        this.scroller.scrollToTop().then(() => this._setFocus());
    };

    _setFocus() {
        this.anchor.setAttribute('tabindex', '-1');
        this.anchor.focus({ preventScroll: true });
    }

    destroy() {
        this.element.removeEventListener('click', this._onTriggerClick);
    }
}
