import React from 'preact/compat';
import PropTypes from 'prop-types';
import nanoid from 'nanoid';
import { recentSearchService } from 'general/js/save-service';

class RecentSearches extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: recentSearchService.getItems()
        };
        recentSearchService.subscribeOnChanges(this._onChange);
    }

    _onChange = (content) => { this.setState({ content }); };

    render() {
        const { content } = this.state;
        const { placeholder } = this.props;
        return content.length === 0 ? (
            <p className="mywink-section__placehold">{placeholder}</p>
        ) : (
            <ul className="action-list">
                {content.map(item => (
                    <li key={nanoid()} className="action-list__item">
                        <a className="action-list__action"
                            href={item.url}
                            dangerouslySetInnerHTML={{ __html: item.title }}
                        />
                    </li>
                ))}
            </ul>
        );
    }
}

RecentSearches.propTypes = {
    placeholder: PropTypes.string.isRequired
};

export default RecentSearches;
