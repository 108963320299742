import { DcBaseComponent } from '@deleteagency/dc';
import keyBy from 'lodash/keyBy';

export default class SidebarNavComponent extends DcBaseComponent {
    static getNamespace() {
        return 'sidebar-nav';
    }

    static getRequiredRefs() {
        return ['root', 'triggers', 'items'];
    }

    onInit() {
        this.stack = [this.refs.root];

        this.triggers = this.refs.triggers;
        this.items = this.refs.items;
        this.itemsById = keyBy(this.items, (el) => el.getAttribute('data-id'));
        this.back = this.refs.back || [];

        this.triggers.forEach((el) => {
            const id = el.getAttribute('data-target');
            this.addListener(el, 'click', this._onTriggerClick.bind(this, id));
        });

        this.back.forEach((el) => this.addListener(el, 'click', this._onBackClick));
    }

    _onTriggerClick(id) {
        this._showItem(id);
    }

    _showItem(id) {
        const itemToShow = this.itemsById[id];
        if (!itemToShow) {
            throw new Error(`No items for id ${id}`);
        }

        const current = this.stack[this.stack.length - 1];
        current.classList.add('is-prev');
        itemToShow.classList.add('is-active');
        this.stack.push(itemToShow);
    }

    _onBackClick = () => {
        this._goBack();
    };

    _goBack() {
        if (!this.stack.length) return;
        const currentItem = this.stack.pop();
        const prevItem = this.stack[this.stack.length - 1];
        currentItem.classList.remove('is-active');
        prevItem.classList.remove('is-prev');
    }
}
