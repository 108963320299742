import { DcBaseComponent } from '@deleteagency/dc';
import { modalService } from 'general/js/modal';
import { pageSpinner } from 'general/js/spinner';
import api from 'general/js/api';

// false target means empty, null - valid meaningful value
const SYNC_TARGET_NOT_INITED_VALUE = false;

export default class ModalTriggerComponent extends DcBaseComponent {
    constructor(element, afterOpen = null) {

        super(element);

        this.modal = null;
        this.afterOpen = afterOpen;
        this._syncTarget = SYNC_TARGET_NOT_INITED_VALUE;

        this.isOpenning = false;
        this.initFunction = () => this.onInit();
    }

    static getNamespace() {
        return 'modal-trigger';
    }

    onInit() {
        if (this.options.handleUrlHashOnInit) {
            this._handleUrlHashOnInit();
        }
        this.element.addEventListener('click', this.onClick);
    }

    async open() {
        if (!this.isOpenning) {
            this.isOpenning = true;
            try {
                const modal = await this.getModal();
                modal.open();
                this.isOpenning = false;
                if (this.afterOpen) {
                    this.afterOpen();
                }
            } catch (e) {
                this.isOpenning = false;
                throw e;
            }
        }
    }

    onClick = (e) => {
        e.preventDefault();
        this.open();
    };

    async getModal() {
        if (this.modal === null) {
            const target = await this.getTarget();
            this.modal = modalService.create(target, this.options);
        }
        return this.modal;
    }

    onDestroy() {
        this.element.removeEventListener('click', this.onClick);
        this.modal = null;
    }

    getSyncTarget() {
        if (this._syncTarget === SYNC_TARGET_NOT_INITED_VALUE) {
            let target = this.getFromChildren();
            if (!target) {
                target = this.getFromTarget();
            }
            this._syncTarget = target;
        }
        return this._syncTarget;
    }

    getFromTarget() {
        const targetId = this.element.dataset.target;
        if (targetId) {
            return document.getElementById(targetId);
        }
        return null;
    }

    getFromChildren() {
        return this.refs.target;
    }

    async getTarget() {
        const syncTarget = this.getSyncTarget();
        if (syncTarget) {
            return syncTarget;
        }

        const asyncTargetDataUrl =
            this.element.getAttribute('href') || this.getChildAttribute(this.element, 'href');

        if (asyncTargetDataUrl) {
            pageSpinner.show();
            try {
                const response = await api.get(asyncTargetDataUrl);
                pageSpinner.hide();
                return response.data;
            } catch (e) {
                pageSpinner.hide();
                throw e;
            }
        }

        throw new Error('Target is not defined');
    }

    async closeModal() {
        const modal = await this.getModal();
        modal.close();
    }

    _handleUrlHashOnInit() {
        const anchor = window.location.hash.slice(1);
        const targetId = this.getSyncTarget().id;
        if (anchor === targetId) {
            this.open();
        }
    }
}
