import { DcBaseComponent, dcFactory } from '@deleteagency/dc';
import { menuService } from 'general/js/menu';

export default class MenuTriggerComponent extends DcBaseComponent {
    static getNamespace() {
        return 'menu-trigger';
    }

    onInit() {
        this.targetId = this.options.target;
        this.element.addEventListener('click', this._onClick);
        menuService.subscribeOnActiveMenuChange(this._onActiveMenuChange);
        this.element.setAttribute('aria-expanded', 'false');
    }

    _onClick = () => {
        if (!menuService.isActive(this.targetId)) {
            menuService.triggerOpen(this.targetId);
            document.addEventListener('keydown', this._onKeydown);
        } else {
            menuService.triggerClose(this.targetId);
            document.removeEventListener('keydown', this._onKeydown);
        }
    };

    _onActiveMenuChange = (newActiveId) => {
        if (this.targetId === newActiveId) {
            this.element.classList.add('is-opened');
            this.element.setAttribute('aria-expanded', 'true');
            dcFactory.init(menuService.getMenuElement(this.targetId), true);
        } else {
            this.element.classList.remove('is-opened');
            this.element.setAttribute('aria-expanded', 'false');
        }
    };

    _onKeydown = (e) => {
        if (e.keyCode === 27) this._onClick();
    };
}
