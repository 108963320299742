import { DcBaseComponent } from '@deleteagency/dc';
import GtmHelper from '../../../components/gtm-trigger/js/gtm-helper';
import { EVENT_OPEN_REGISTER } from '../../../components/site-sidebar/js/sidebar-user-tabs.component';
import { appConfig } from '../../../general/js/app-config';
import { eventBus } from '../../../general/js/events';
import { menuService } from '../../../general/js/menu';
import { clone } from '../../../general/js/object-helper';
import { propertiesSaveService, siteNoti } from '../../../general/js/save-service';
import userService from '../../../general/js/user/user-service';
import { counter } from './counter';
import Hearts from './hearts';
export default class SavePropertyBtn extends DcBaseComponent {
    static getNamespace() {
        return 'save-property-btn';
    }

    onInit() {
        this._findAndToggle(propertiesSaveService.getItems());
        propertiesSaveService.subscribeOnChanges(this._findAndToggle);
        this.addListener(this.element, 'click', this._onClick);
    }

    _onClick = (e) => {
        if (this.isSaved) {
            this.isSaved = false;
            this._toggleActive(false);
            propertiesSaveService.delete(this.options.id, clone(this.options));
            siteNoti.showNoti({ name: appConfig.translations.removedProperty });
        } else {
            Hearts.pop(e, this.element);
            
            if (userService.user === null) {
                switch (counter.value) {
                    case 0:
                        menuService.triggerOpen('user');
                        break;
                    case 1:
                        siteNoti.showNoti(this.options);
                        break;
                    default:
                        eventBus.emit(EVENT_OPEN_REGISTER);
                        menuService.triggerOpen('user');
                }
                counter.value++;
            } else {
                this.isSaved = true;
                this._toggleActive(true);
                GtmHelper.send(null, {
                    category: 'Saved properties',
                    action: 'Save',
                    label: this.options.name,
                });
                propertiesSaveService.saveItem(this.options.id, clone(this.options));
                siteNoti.showNoti(this.options);
            }
        }
    };

    _toggleActive = (isSaved) => {
        this.element.classList[isSaved ? 'add' : 'remove']('is-active');        
        this.element.parentElement.classList[isSaved ? 'add' : 'remove']('is-active');
    };

    _findAndToggle = (items) => {
        this.isSaved = this._find(items);
        this._toggleActive(this.isSaved);
    };

    _find = (items) => items.some((item) => item.id === this.options.id);
}
