import propTypes from 'prop-types';
import React from 'preact/compat';
import 'preact/debug';
import Predictive from '../../predictive/js/predictive';

class LocalOfficeSearchBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        };
    }

    onValueChange = (newValue) => {
        this.setState({
            value: newValue
        });
    };

    onSuggestionSelected = (event, { suggestion }) => {
        console.log('Suggestion selected', suggestion);
    };

    onSubmit = (e) => {
        e.preventDefault();
        console.log('Search submitted with value', this.state.value);
    };

    render() {
        const { value } = this.state;
        const { placeholder, endpointUrl } = this.props;

        return (
            <form className="local-office-search-bar" onSubmit={this.onSubmit}>
                <div className="local-office-search-bar__input-wrapper">
                    <Predictive
                        value={value}
                        endpointUrl={endpointUrl}
                        placeholder={placeholder}
                        showLocationButton={true}
                        inputClass={'local-office-search-bar__input'}
                        onValueChange={this.onValueChange}
                        onSuggestionSelected={this.onSuggestionSelected}
                    />
                </div>
                <button type="submit" className="btn local-office-search-bar__submit">
                    <span className="button-content">Go</span>
                </button>
            </form>

        );
    }
}

LocalOfficeSearchBar.defaultProps = {
    placeholder: '',
    value: '',
};

LocalOfficeSearchBar.propTypes = {
    placeholder: propTypes.string,
    value: propTypes.string,
    endpointUrl: propTypes.object.isRequired,
};

export default LocalOfficeSearchBar;
