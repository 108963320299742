import React from 'preact/compat';
import PropTypes from 'prop-types';

const Spinner = (props) => {
    const {
        isActive, isFaded, mobileSmall, isSticky
    } = props;

    return (
        <div
            className={
                'spinner' +
                (mobileSmall ? ' spinner--mobile-small' : '') +
                (isSticky ? ' spinner--sticky' : '') +
                (isActive ? ' is-visible' : '') +
                (isFaded ? ' is-faded' : '')
            }
        />
    );
};

Spinner.propTypes = {
    isActive: PropTypes.bool.isRequired,
    isFaded: PropTypes.bool,
    mobileSmall: PropTypes.bool,
    isSticky: PropTypes.bool
};

export default Spinner;

