import { appConfig } from 'general/js/app-config';
import { propertiesSaveService, siteNoti } from 'general/js/save-service';
import SvgIcon from 'general/js/svg-icon';
import React from 'preact/compat';
import PropTypes from 'prop-types';

const PLACEHOLDERS_COUNT = 20;

class SavedProperties extends React.Component {
    constructor(props) {
        super(props);

        this.state = { items: propertiesSaveService.getItems() };
        propertiesSaveService.subscribeOnChanges(this._onChangeProperties);
    }

    _onCrossClick = (id) => {
        const { items } = this.state;
        this.setState({ items: items.filter((item) => item.id !== id) });
        siteNoti.showNoti({ name: appConfig.translations.removedProperty });
        propertiesSaveService.delete(id);
    };

    _onChangeProperties = (items) => {
        this.setState({ items });
    };

    _renderPlaceholder = () => {
        const { placeholder } = this.props;   
        return <li className='saved-property-card-placeholder'>{placeholder}</li>;
    };

    render() {
        const { items } = this.state;

        let placeholders = [];

        if (items.length < PLACEHOLDERS_COUNT) {
            const placeholdersCount = PLACEHOLDERS_COUNT - items.length;
            placeholders = Array(placeholdersCount).fill(true);
        }

        return (
            <ul className="saved-properties">
                {items.length > 0 &&
                    items.map((item) => {
                        const isPublished = item.isPublished;                       

                        return (
                            <li
                                key={item.id} data-id={item.id} 
                                className={`saved-property-card ${
                                    !isPublished ? 'saved-property-card__unpublished' : ''
                                }`}
                            >
                                <button
                                    className="saved-property-card__delete btn-close"
                                    type="button"
                                    onClick={() => this._onCrossClick(item.id)}
                                >
                                    <SvgIcon size={['22']} name="cross" />
                                </button>
                                <figure className="saved-property-card__image">
                                    <img
                                        className="lazyload"
                                        data-src={`${item.image}?width=300`}
                                        alt={item.name}
                                    />
                                    {!isPublished && (
                                        <span className={`saved-property-card__notification`}>
                                            Property no longer available
                                        </span>
                                    )}
                                </figure>
                                <div className="saved-property-card__content">
                                    <h4 className="saved-property-card__title">
                                        {isPublished ? (
                                            <a
                                                className="saved-property-card__link"
                                                href={item.urlToDetails}
                                                aria-label={item.name}
                                            >
                                                {item.name}
                                            </a>
                                        ) : (
                                            <>{item.name}</>
                                        )}
                                    </h4>
                                    <p
                                        className="saved-property-card__description"
                                        dangerouslySetInnerHTML={{ __html: item.price }}
                                    ></p>
                                </div>
                            </li>
                        );
                    })}
                {placeholders.map((item) => this._renderPlaceholder())}
            </ul>
        );
    }
}

SavedProperties.propTypes = {
    placeholder: PropTypes.string.isRequired,
};

export default SavedProperties;
