import { DcBaseComponent } from '@deleteagency/dc';
import BaseForm from 'general/js/forms/base-form';
import ElementSpinner from 'general/js/spinner/element-spinner';

export default class ResetPasswordFormComponent extends DcBaseComponent {
    static getNamespace() {
        return 'reset-password-form';
    }

    static getRequiredRefs() {
        return ['successMessage'];
    }

    async onInit() {
        this.spinner = new ElementSpinner(this.element, { modifiers: ['with-overlay'] });
        this.form = new BaseForm(
            this.element,
            {
                submitElement: this.refs.submit,
                onBeforeSubmit: this._onBeforeSubmit,
                errorsSummaryElement: this.refs.errorsSummary,
                onSuccessfulSubmit: this._onSuccessfulSubmit,
                onFailedSubmit: this._onFailedSubmit,
            },
            this
        );
    }

    _onSuccessfulSubmit = ({ data }) => {
        this.spinner.hide();
        const { successMessage, submit, userProfileButton, cancelButton } = this.refs;
        successMessage.innerHTML = data.successMessage;
        submit.hidden = true;
        cancelButton.hidden = true;
        userProfileButton.hidden = false;
    };

    _onBeforeSubmit = () => {
        this.spinner.show();
    };

    _onFailedSubmit = (error) => {
        this.spinner.hide();
        console.error(error);
    };
}
