import { DcBaseComponent } from '@deleteagency/dc';

export default class SelectComponent extends DcBaseComponent {
    static getNamespace() {
        return 'select';
    }

    onInit() {
        this.addListener(this.element, 'change', this.onSelectChange);
    }

    onSelectChange(evt) {
        const select = evt.target;
        const options =  select.options;
        const selectedItem =  options[select.selectedIndex];
        selectedItem.value.trim() !== '' ? select.classList.add('selected') : select.classList.remove('selected');
    }

    onDestroy() {
        this._removeAllListeners();
    }
}
