import { DcBaseComponent } from '@deleteagency/dc';
import analyticsService from 'general/js/analytics-service';
import { eventBus } from 'general/js/events';
import { EVENT_CLOSEST_LOCATION_RECEIVED } from 'components/predictive/js/location-button.component';

export default class GTMMicroSiteSearchForm extends DcBaseComponent {
    static getNamespace() {
        return 'gtm-microsite-search-form';
    }

    onInit() {
        const { triggerBuy, triggerRent, triggerFindOffice, triggerFindPlace } = this.refs;
        this.addListener(triggerBuy, 'click', this._sendBuyGAEvent);
        this.addListener(triggerRent, 'click', this._sendRentGAEvent);
        this.addListener(triggerFindOffice, 'click', this._sendFindOfficeEvent);
        this.triggerFindPlace = triggerFindPlace;

        eventBus.addListener(
            EVENT_CLOSEST_LOCATION_RECEIVED,
            this._onLocationReceived
        );
    }

    _sendBuyGAEvent = () => {
        this._resetFormAction();
        const gaCategory = this.options.category || 'Microsite Search';
        const gaAction = 'Buy';
        const gaLabel = this.element[0].value || 'Empty';

        analyticsService.sendEvent(gaCategory, gaAction, gaLabel);
    };

    _sendRentGAEvent = () => {
        this._resetFormAction();
        const gaCategory = this.options.category || 'Microsite Search';
        const gaAction = 'Rent';
        const gaLabel = this.element[0].value || 'Empty';
        analyticsService.sendEvent(gaCategory, gaAction, gaLabel);
    };

    _sendFindOfficeEvent = () => {
        this.element.action = '/Location/Search';
        if (this.element[0].value.trim() === '') this.triggerFindPlace.click();
        else this.element.submit();
    }

    _resetFormAction = () => {
        this.element.action = '/PropertiesSearch/Index';
    }

    _onLocationReceived = ({ location }) => {
        this.element[0].value = location.text;
        this.element.submit();
    }
}
