// @ts-nocheck
import { EVENT_RV_ADRESS_CHOOSED } from 'components/address-predictive-field/js/address-predictive-field';
import api from 'general/js/api';
import { eventBus } from 'general/js/events';
import Spinner from 'general/js/spinner/spinner';
import SvgIcon from 'general/js/svg-icon';
import nanoid from 'nanoid';
import React from 'preact/compat';
import PropTypes from 'prop-types';
import { EVENT_RV_RADIO_CHANGE } from './rv-radio.component';
import { SELECTED_TIME_NAME } from './rv-wizard.component';

const LANG = 'en-GB';
const TIME_OPTIONS = { hour: 'numeric', minute: 'numeric' };
const DAY_OPTIONS = { day: 'numeric' };
const MONTH_OPTIONS = { month: 'long' };
const RETURN_OPTIONS_1 = {
    day: 'numeric',
    weekday: 'short',
    month: 'long',
};
const RETURN_OPTIONS_2 = {
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
};
const RETURN_OPTIONS_3 = { day: 'numeric', weekday: 'long', month: 'long' };

const WORK_STARTS_AT = 9;
const WORKING_HOURS_IN_DAY = 9;
const MINUTES_IN_HOUR = 60;
const MAX_COUNT_FOR_RETRY = 4;

class Calendar extends React.Component {
    constructor(props) {
        super(props);
        const { optionsForRequest = {}, waitingOptions, initLoading } = props;
        this.currentDate = new Date();
        this.state = {
            date: this.currentDate,
            isLoading: false,
            needRequest: Object.keys(optionsForRequest).length > 0,
            week: this._makeWeek(this.currentDate),
            optionsForRequest,
            requestTryCount: 0,
            selectedDate: null,
            selectedTime: null,
        };
        this.id = nanoid();
        if (waitingOptions) {
            eventBus.addListener(EVENT_RV_RADIO_CHANGE, this._onUpdateOptions);
            eventBus.addListener(EVENT_RV_ADRESS_CHOOSED, this._onUpdateOptions);
        }
    }

    componentDidMount() {
        const { initLoading } = this.props;
        if (initLoading) {
            document.addEventListener('click', (e) => {
                if (e.target.dataset.dcRvWizardTrigger) {
                    this.sentRequest();
                }
            });            
        }
    }

    createDebugList = (data) => {
        const ul = document.createElement('ul');
        for (const key in data) {
            const li = document.createElement('li');
            if (Array.isArray(data[key])) {
                const spanKey = document.createElement('span');
                spanKey.textContent = `${key}: `;
                li.appendChild(spanKey);
                const nestedUl = document.createElement('ul');
                data[key].forEach(item => {
                    const nestedLi = document.createElement('li');
                    nestedLi.appendChild(this.createDebugList(item));
                    nestedUl.appendChild(nestedLi);
                });
                li.appendChild(nestedUl);
            } else if (typeof data[key] === 'object' && data[key] !== null) {
                const spanKey = document.createElement('span');
                spanKey.textContent = `${key}: `;
                li.appendChild(spanKey);
                li.appendChild(this.createDebugList(data[key]));
            } else {
                li.textContent = `${key}: ${data[key]}`;
            }
            ul.appendChild(li);
        }
        return ul;
    };

    _makeWeek = (date, response) => {
        const week = [];
        const startDayOfWeek = date.getUTCDate() - date.getUTCDay() + 1;
        for (let i = 0; i < 7; i++) {
            const _date = new Date(
                date.getUTCFullYear(),
                date.getUTCMonth(),
                startDayOfWeek + i,
                12
            );
            week.push({
                date: _date,
                dayName: new Intl.DateTimeFormat(LANG, DAY_OPTIONS).format(_date),
                times: this._makeTimes(_date, response),
            });
        }
        return week;
    };

    _makeTimes = (date, response = {}) => {
        const { endpoint } = this.props;
        const times = [];
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth();
        const day = date.getUTCDate();

        if (endpoint.includes('Foundation')) {
            // foundation api
            // Extract the 'appointments' property with a default value of an empty array.
            const { appointments = [] } = response;

            // Create a new Date object without a specific time component.
            const dateWithoutTime = new Date(year, month, day);

            // Filter the 'appointments' array to find appointments that match the date (ignoring the time component).
            const _appointments = appointments.filter((item) => {
                // Convert the 'dateTime' string from 'item' to a Date object in UTC.
                const dateTime = new Date(item.dateTime + 'Z');

                // Create a new Date object for the appointment date without the time component (in UTC).
                const appointmentDateWithoutTime = new Date(
                    dateTime.getUTCFullYear(),
                    dateTime.getUTCMonth(),
                    dateTime.getUTCDate()
                );

                // Check if the appointment's date (without time) matches the provided date (without time) using UTC timestamps.
                return (
                    this._getUTCNumber(appointmentDateWithoutTime) ===
                    this._getUTCNumber(dateWithoutTime)
                );
            });

            // Check if there are matching appointments.
            if (_appointments) {
                // Iterate through the filtered '_appointments' array.
                _appointments.forEach((appointment) => {

                    // Create a new Date object for the appointment date and time (in UTC).
                    const _date = new Date(appointment.dateTime + 'Z');

                    // Check if BST || Add the offset to the date - to mitigate issue in BST
                    _date.setMinutes(_date.getMinutes() + _date.getTimezoneOffset());

                    // Format the appointment date and time as a time string using the specified language and options.
                    const time = new Intl.DateTimeFormat(LANG, TIME_OPTIONS).format(_date);

                    // Push the formatted time, the '_date' object, and the 'appointment' object into the 'times' array.
                    times.push({
                        time,
                        date: _date,
                        value: appointment,
                    });
                });
            }
            return times;
        } else {
            // legacy api
            // Extract 'interval' and 'appointments' with default values.
            const { interval = 30, appointments = [] } = response;

            // Calculate the number of time slots based on working hours and the given interval.
            const slotsCount = Math.floor((WORKING_HOURS_IN_DAY * MINUTES_IN_HOUR) / interval);

            // Iterate through the time slots.
            for (let i = 0; i <= slotsCount; i++) {
                // Create a new Date object with the specified year, month, day, and calculated time based on 'i'.
                const _date = new Date(year, month, day, WORK_STARTS_AT, interval * i);

                // Format the date as a time string using the specified language and options.
                const time = new Intl.DateTimeFormat(LANG, TIME_OPTIONS).format(_date);

                // Find the appointment that matches the current time slot.
                const value = appointments.find((item) => {
                    // Convert the 'dateTime' string from 'appointments' to a Date object. Add Z to the end of the string to indicate UTC time.
                    const dateTime = new Date(item.dateTime + 'Z');

                    // Check if the Date objects match by comparing their UTC timestamps.
                    return +dateTime === this._getUTCNumber(_date);
                });
                times.push({ time, date: _date, value });
            }

            return times;
        }
    };

    _getUTCNumber = (date) =>
        Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes() - date.getTimezoneOffset()
        );

    _changeWeek = (forward, requestTryCount = null) => {
        const { date } = this.state;
        const newDate = new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate() + (forward ? 7 : -7)
        );
        const state = {
            date: newDate,
            week: this._makeWeek(newDate),
            needRequest: true,
            isLoading: true,
            selectedDate: null,
        };
        if (requestTryCount !== null) state.requestTryCount = requestTryCount;
        this.setState(state, this.sentRequest);
    };

    _onUpdateOptions = (options) => {
        const { waitingOptions } = this.props;
        const { optionsForRequest } = this.state;
        waitingOptions.forEach((key) => {
            if (options[key] || (options.content && options.content[key])) {
                optionsForRequest[key] = options[key] || options.content[key];
            }
        });
        this.setState({
            optionsForRequest,
            needRequest: true,
        });
    };

    sentRequest = () => {
        const {
            date,
            week,
            needRequest,
            isLoading,
            requestTryCount,
            optionsForRequest,
        } = this.state;

        if (!needRequest) return;

        const { endpoint, onFailRequest } = this.props;
        const currentDate = new Date();

        const formatStartOfDay = (date) => {
            const _date = new Date(date);
            _date.setHours(0, 0, 0, 0);
            return _date.toISOString();
        };

        const formatEndOfDay = (date) => {
            const _date = new Date(date);
            _date.setHours(23, 59, 59);
            return _date.toISOString();
        };

        const fromDate =
            week[0].date <= currentDate
                ? currentDate.toISOString()
                : formatStartOfDay(week[0].date);
        const toDate = formatEndOfDay(week[6].date);

        const address =
            `${window.location.origin}${endpoint}?fromDate=${fromDate}&toDate=${toDate}` +
            (Object.keys(optionsForRequest).length > 0
                ? '&' +
                  Object.keys(optionsForRequest)
                      .map((key) => `${key}=${optionsForRequest[key]}`)
                      .join('&')
                : '');

        /* In Depth Expaination of the address variable address above with example output values:
         *
         *      ${window.location.origin}  = http://winkworth.deletedev.com
         *
         *      ${endpoint}  = /api/forms/arrangeViewing/appointments
         *
         *      ${fromDate}  = 2023-11-27T17:39:23.014Z
         *
         *      ?fromDate=${(
         *        week[0].date <= currentDate
         *        ? currentDate.toISOString()
         *        : formatStartOfDay(week[0].date)
         *      )}  = ?fromDate=2023-11-27T17:39:23.014Z
         *
         *      &toDate=${
         *        formatEndOfDay(week[6].date)
         *      }  = &toDate=2023-12-03T00:00:00.000Z
         *
         *      (Object.keys(optionsForRequest).length > 0
         *          ? '&' + Object.keys(optionsForRequest)
         *              .map((key) => `${key}=${optionsForRequest[key]}`)
         *              .join('&')
         *          : ''
         *      );  = &propertyId=BAS210003
         */

        if (!isLoading) this.setState({ isLoading: true });

        return api
            .get(address)
            .then((result) => {
                if (result.data.errors === null) {
                    this.setState({
                        week: this._makeWeek(date, result.data),
                        isLoading: false,
                        needRequest: false,
                        requestTryCount: 0,
                    });
                    // Render a debug list if the URL contains ?debug=true
                    if (window.location.search.includes('debug=true')) {
                        const debugContainer = document.querySelectorAll('.stepped-form__debug');
                        if (debugContainer) {
                            debugContainer.forEach((debug) => {
                                debug.innerHTML = '';
                                const debugList = this.createDebugList(result.data);
                                debug.appendChild(debugList);
                            });
                            $('.request-valuation__close').on('click', (e) => {
                                debugContainer.forEach((debug) => {
                                    debug.innerHTML = '';
                                });
                            });
                        }
                    }
                } else if (
                    result.data.noAppointmentsFound &&
                    requestTryCount < MAX_COUNT_FOR_RETRY
                ) {
                    this._changeWeek(true, requestTryCount + 1);
                } else {
                    this.setState({ isLoading: false, needRequest: false, requestTryCount: 0 });
                    onFailRequest(result.data.errors);
                }
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                    needRequest: false,
                    week: this._makeWeek(this.currentDate),
                    requestTryCount: 0,
                });
                onFailRequest();
            });
    };

    _renderTimeSlots = () => {
        const { week, selectedDate } = this.state;
        const { group, onChoose } = this.props;

        const selectedDay = week.filter((day) => day.date === selectedDate)[0];

        if (!selectedDay) return null;

        return (
            <React.Fragment>
                <div className="calendar__label">Slots available</div>
                <div className="calendar__time-slots">
                    {selectedDay.times.map(
                        (time, index) =>
                            time.value && (
                                <label
                                    className="calendar__time-slot"
                                    key={`${selectedDay.date.toDateString()}_time_${index}`}
                                >
                                    {time.value && (
                                        <input
                                            className="calendar__radio"
                                            name={SELECTED_TIME_NAME}
                                            type="radio"
                                            value={JSON.stringify(time.value)}
                                            data-parsley-group={group}
                                            onChange={() => {
                                                const pen = new Intl.DateTimeFormat(
                                                    LANG,
                                                    RETURN_OPTIONS_2
                                                )
                                                    .format(time.date)
                                                    .toUpperCase();
                                                const apple = new Intl.DateTimeFormat(
                                                    LANG,
                                                    RETURN_OPTIONS_1
                                                ).format(time.date);
                                                onChoose(`${apple} at ${pen}`);
                                            }}
                                        />
                                    )}
                                    <span className="calendar__radio-text">{time.time}</span>
                                </label>
                            )
                    )}
                </div>
            </React.Fragment>
        );
    };

    render() {
        const { date, week, isLoading, selectedDate } = this.state;
        return (
            <div className={`calendar ${isLoading ? ' is-loading' : ''}`}>
                <Spinner isActive={isLoading} />
                <div className="calendar__header">
                    <button
                        type="button"
                        title="Previous week"
                        className="calendar__btn calendar__btn--prev"
                        disabled={week[0].date <= this.currentDate || isLoading || null}
                        onClick={() => {
                            this._changeWeek(false);
                        }}
                    >
                        <SvgIcon size={['10', '16']} name="chevron-left" />
                    </button>

                    <div className="calendar__month">
                        {selectedDate
                            ? new Intl.DateTimeFormat(LANG, RETURN_OPTIONS_3).format(selectedDate)
                            : new Intl.DateTimeFormat(LANG, MONTH_OPTIONS).format(date)}
                    </div>
                    <button
                        type="button"
                        title="Next week"
                        className="calendar__btn calendar__btn--next"
                        disabled={isLoading}
                        onClick={() => {
                            this._changeWeek(true);
                        }}
                    >
                        <SvgIcon size={['10', '16']} name="chevron-right" />
                    </button>
                </div>
                <ul className="calendar__days-names">
                    <li>M</li>
                    <li>T</li>
                    <li>W</li>
                    <li>T</li>
                    <li>F</li>
                    <li>S</li>
                    <li>S</li>
                </ul>
                <div className="calendar__days">
                    {week.map((day) => (
                        <button
                            type="button"
                            key={day.date.toDateString()}
                            onClick={() => {
                                this.setState({ selectedDate: day.date });
                            }}
                            disabled={
                                day.date <= this.currentDate ||
                                day.times.every((time) => time.value === undefined)
                            }
                            className={`calendar__day ${
                                day.date === selectedDate ? 'is-active' : ''
                            }`}
                        >
                            <span className="calendar__day-name">{day.dayName}</span>
                        </button>
                    ))}
                </div>
                {this._renderTimeSlots()}
            </div>
        );
    }
}

Calendar.propTypes = {
    onChoose: PropTypes.func,
    endpoint: PropTypes.string.isRequired,
    onFailRequest: PropTypes.func.isRequired,
    propertyId: PropTypes.string,
    fullPostcode: PropTypes.string,
    valuationType: PropTypes.string,
    officeId: PropTypes.string,
    optionsForRequest: PropTypes.array,
    waitingOptions: PropTypes.array,
    group: PropTypes.string.isRequired,
    initLoading: PropTypes.bool,
};

export default Calendar;
