import { DcBaseComponent } from '@deleteagency/dc';
import { eventBus, events } from 'general/js/events';
import { propertyStorageService } from 'general/js/property-storage-service/index';

const CLASS_ACTIVE = 'is-active';

export default class SubnavTabsMenuComponent extends DcBaseComponent {
    constructor(element) {
        super(element);

        this.items = this.refs.items;
        this._activeTabId = null;
        this.scrollOffset = this.options.scrollOffset || 0;
    }

    static getRequiredRefs() {
        return ['items'];
    }

    static getNamespace() {
        return 'subnav-tabs-menu';
    }

    onInit() {
        this.eventId = this.options.customEventId || events.EVENT_SUBNAV_TAB_TRIGGER;
        this._setInitialItem();
        this._openTabByUrl();
        eventBus.addListener(this.eventId, this._onTriggerClick);
    }

    _onTriggerClick = (tabId) => {
        if (tabId) {
            this._activate(tabId);
        }
    };

    _openTabByUrl() {
        const hash = window.location.hash;
        if (hash) {
            const routeTabId = hash.slice(1);
            const isTabIdExists = this._isTabIdExists(routeTabId);

            if (!isTabIdExists) return;

            this._onTriggerClick(routeTabId);
            const box = this.items[routeTabId].getBoundingClientRect();
            const top = box.top + window.pageYOffset - this.scrollOffset;
            window.scrollTo(0, top);
        }
    }

    _isTabIdExists = (tabId) => Object.keys(this.items).some((key) => key === tabId);

    _setInitialItem() {
        const activeItem = Object.keys(this.items)
            .map((tabId) => [tabId, this.items[tabId]])
            .find(([tabId, item]) => item.classList.contains(CLASS_ACTIVE));
        if (activeItem) {
            this._activeTabId = activeItem[0];
        }
    }

    _activate(tabId) {
        if (tabId !== this._activeTabId && tabId in this.items) {
            // close currently opened tab
            this._activateItem(tabId, this._activeTabId);
            this._activeTabId = tabId;

            //update the last searches with the hash value map/list
            if (tabId === 'map' || tabId === 'list') {
                const storage = propertyStorageService.getStore();
                if (storage && storage.length > 0) {
                    const lastSearch = storage[storage.length - 1];
                    lastSearch.propertySearchFormModel.propertySearchSelectedOptions.showMapTab =
                        tabId === 'map';
                    propertyStorageService.updateStore(lastSearch);
                }
            }
        }
    }

    _activateItem(newTabId, oldTabId) {
        if (oldTabId) {
            this.items[oldTabId].classList.remove(CLASS_ACTIVE);
            eventBus.emit(events.EVENT_VIDEO_PAUSE, oldTabId);
        }
        this.items[newTabId].classList.add(CLASS_ACTIVE);
        eventBus.emit(events.EVENT_TAB_CHANGE, newTabId);

        if (this.refs.activeTab) {
            this.refs.activeTab.innerText = this.items[newTabId].textContent.trim();
        }
    }
}
