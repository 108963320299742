import React from 'preact/compat';
import PropTypes from 'prop-types';

const SvgIcon = ({ name, size, additionalClass }) => (
    <svg className={`${additionalClass || ''} icon`} width={size[0]} height={size[1] || size[0]} focusable="false">
        <use xlinkHref={`#icon-${name}`}/>
    </svg>
);

SvgIcon.propTypes = {
    additionalClass: PropTypes.string,
    name: PropTypes.string.isRequired,
    size: PropTypes.array.isRequired
};

export default SvgIcon;
