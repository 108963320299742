import nanoid from 'nanoid';
import userService, { EVENT_GOT_USER_INFO } from 'general/js/user/user-service';
import eventBus from 'general/js/events/bus';
import api from 'general/js/api';
import { isPartialEqual } from 'general/js/object-helper';
import RootService from './root-service';

export default class SaveService extends RootService {
    constructor(options) {
        super(options);
        this._init();
    }

    _init = () => {
        if (userService.user !== null) this._onUserChange(userService.user);
        eventBus.addListener(EVENT_GOT_USER_INFO, this._onUserChange);
    };

    getItems = () => Object.keys(this._items).map(key => this._items[key]);

    _analyzeUser = user => new Promise((resolve, reject) => {
        if (user === null) {
            this._items = {};
            resolve();
        } else {
            const post = (url, options = null) => {
                api.post(this._makeUrl(url), options)
                    .then((result) => {
                        this._items = this._makeObj(result.data);
                        resolve();
                    })
                    .catch((error) => { console.dir(error); reject(); });
            };
            const ids = Object.keys(this._items);
            if (ids.length > 0) {
                post(this.options.endpoints.save, this.getItems());
            } else {
                post(this.options.endpoints.get);
            }
        }
    });

    _makeObj = (data) => {
        const result = {};
        if (data[this.options.name]) {
            data[this.options.name].forEach((item) => {
                result[item.id] = item;
            });
        }
        return result;
    };

    _onUserChange = (user) => {
        this._analyzeUser(user).then(() => {
            this._cleanLocalStorage();
            this._callSubscribers();
        });
    };

    updateItem = (id, status) => {
        if (userService.user === null) {
            if (this._items[id]) {
                this._items[id].alertFrequency = status;
                this._writeInLocalStorage();
            }
        } else {
            api.put(`${this._makeUrl(this.options.endpoints.update)}/${id}/${status}`)
                .catch((error) => { console.dir(error); });
        }
    };

    _makeUrl = url => window.location.origin + '/' + url;

    saveItem = (id, item) => {
        const post = (url, props) => {
            api.post(this._makeUrl(url), props)
                .then((result) => {
                    this._items = this._makeObj(result.data);
                    this._callSubscribers();
                })
                .catch((error) => { console.dir(error); });
        };

        if (userService.user === null) {
            const _id = id || this._getIdByItem(item) || nanoid();
            this._items[_id] = item;
            if (!this._items[_id].id) this._items[_id].id = _id;
            this._writeInLocalStorage();
            if (this.options.needGetOnSave) {
                post(this.options.endpoints.get, Object.keys(this._items));
            } else {
                this._callSubscribers();
            }
        } else post(this.options.endpoints.save, [item]);
    };

    delete = (id, item) => {
        const _id = id || this._getIdByItem(item);
        if (_id && this._items[_id]) {
            if (userService.user === null) {
                delete this._items[_id];
                this._writeInLocalStorage();
                this._callSubscribers();
            } else {
                api.delete(`${this._makeUrl(this.options.endpoints.delete)}/${_id}`)
                    .then(() => {
                        delete this._items[_id];
                        this._callSubscribers();
                    })
                    .catch((error) => { console.dir(error); });
            }
        }
    };

    _getIdByItem = (item) => {
        if (item.id) return item.id;
        const result = isPartialEqual(this.getItems(), item);
        return result.length > 0 ? result[0].id : null;
    }
}
