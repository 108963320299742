import { DcBaseComponent } from '@deleteagency/dc';
import VideoCreator from 'general/js/video/creator';

export default class MediaQuoteComponent extends DcBaseComponent {
    constructor(element, extendedOptions = {}, onPlayTriggered = null) {
        super(element);

        this.isStarted = false;
        this.onPlayTriggered = onPlayTriggered;
        this.options.id = this.getVideoIdFromURL(this.options.url, this.options.type);
        this.options = { ...this.options, ...extendedOptions };
    }

    static getNamespace() {
        return 'media-quote-video';
    }

    onInit() {
        const options = this.options;
        let playerElement = this.refs.player;
        if (!playerElement) {
            playerElement = document.createElement('div');
        }
        options.playerElement = playerElement;

        if (this.refs.playButton) {
            this.addListener(this.refs.playButton, 'click', this.onPlayButtonClick);
        } else {
            this.addListener(this.element, 'click', this.onPlayButtonClick);
        }

        this.video = VideoCreator.createFromOptions(options);
        this.video.onStart();

        if (!options.preview) {
            this.loadThumbnail();
        }

    }

    onPlayButtonClick = (e) => {
        if (this.options.id && this.options.id.length > 0) {
            e.preventDefault();
            if (this.refs.preview) this.refs.preview.remove();
            this.refs.playButton.remove();
            this.play();
        } else {
            console.log('there is no Video id, nothing to play');
        }
    }

    async play() {
        if (!this.video.isStarting) {
            try {
                await this.video.play();
            } catch (e) {
                console.error(e);
            }
        }
    }

    loadThumbnail() {
        const thumbnailUrlPromise = this.video.getThumbnailUrl();
        if (thumbnailUrlPromise) {
            thumbnailUrlPromise.then((thumbnailUrl) => {
                if (thumbnailUrl) {
                    const img = document.createElement('img');
                    img.setAttribute('data-src', thumbnailUrl);
                    img.className = 'lazyload';
                    this.refs.preview.insertAdjacentElement('afterbegin', img);
                }
            });
        }
    }

    getVideoIdFromURL(url, type) {
        let id = undefined;
        if (type === 'Youtube') {
            const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
            const match = url.match(regExp);
            if (match && match[2].length == 11) {
                id = match[2];
            }
        } else {
            const regExp = new RegExp(/(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/);
            if ( regExp.test(url) ) {
                id = regExp.exec(url)[5];
            }
        }
        return id;
    }

}
