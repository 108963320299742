import { DcBaseComponent } from '@deleteagency/dc';
import { render } from 'preact';
import React from 'preact/compat';
import RecentSearches from './recent-searches';

export default class RecentSearchesComponent extends DcBaseComponent {
    static getNamespace() {
        return 'recent-searches';
    }

    onInit() {
        render(<RecentSearches placeholder={this.options.placeholder} />, this.element);
    }
}
