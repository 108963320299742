import { DcBaseComponent } from '@deleteagency/dc';
import { eventBus } from 'general/js/events';
import { render } from 'preact';
import React from 'preact/compat';
import AddressPredictiveField, { EVENT_RV_EDIT_BUTTON_CLICKED } from './address-predictive-field';

export default class AddressPredictiveFieldComponent extends DcBaseComponent {
    static getNamespace() {
        return 'address-predictive-field';
    }

    static getRequiredRefs() {
        return ['input'];
    }

    onInit() {
        this._render();
        if (this.refs.editButton) {
            this.addListener(this.refs.editButton, 'click', this._onEditButtonClick);
        }
    }

    _onEditButtonClick = () => {
        eventBus.emit(EVENT_RV_EDIT_BUTTON_CLICKED);
    };

    _render() {
        const { input } = this.refs;
        const { parentElement } = input;
        const attributes = {};
        input.getAttributeNames().forEach((name) => {
            attributes[name !== 'class' ? name : 'inputClass'] = input.getAttribute(name) || true;
        });
        parentElement.removeChild(input);
        render(
            <AddressPredictiveField
                attributes={attributes}
                labelNode={this.refs.label}
                editButton={this.refs.editButton}
                errorMessage={this.options.errorMessage}
                target={this.options.target}
                wrapperTarget={this.options.wrapperTarget}
            />,
            parentElement,
            parentElement.lastChild
        );
    }
}
