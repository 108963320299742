import { DcBaseComponent } from '@deleteagency/dc';
import Flickity from 'flickity';

export default class TeamGroupCarouselComponent extends DcBaseComponent {
    constructor(element) {
        super(element);
    }

    static getNamespace() {
        return 'team-group-car';
    }

    onInit() {
        this._carousel = null;
        this._flickityOptions = {
            contain: true,
            wrapAround: true,
            pageDots: true,
            watchCSS: true,
            prevNextButtons: false
        };
        this._initFlickity();
    }

    _initFlickity() {
        this._carousel = new Flickity(this.element, this._flickityOptions);
        this.element.classList.add('team-group--max-height');
    }

    onDestroy() {
        this._carousel.destroy();
        this._carousel = null;
    }
}
