import { dcFactory } from '@deleteagency/dc';
import { menuService } from 'general/js/menu';
import SidebarMenuComponent from './sidebar-menu.component';
import SidebarUserTabsComponent from './sidebar-user-tabs.component';

export default class SidebarMenuUserComponent extends SidebarMenuComponent {
    static getNamespace() {
        return 'user-menu';
    }

    getMenuId() {
        return 'user';
    }

    static getRequiredRefs() {
        return ['tabs'];
    }

    onInit() {
        this.userTabsInstance = new SidebarUserTabsComponent(this.refs.tabs);
        this.userTabsInstanceDetails = new SidebarUserTabsComponent(this.refs.details);
        this.userTabsInstance.init();
        this.userTabsInstanceDetails.init();
        super.onInit();
    }

    afterOpenByUrl(routeParts) {
        if (routeParts.length > 1) {
            // pass the rest of the route to the user tabs component
            dcFactory.init(menuService.getMenuElement('user'), true);
            this.userTabsInstance.open(routeParts.slice(1));
        }
    }
}
