import { dcFactory } from '@deleteagency/dc';
import GTMTriggerComponent from './js/gtm-trigger.component';
import GTMTriggerGroupComponent from './js/gtm-trigger-group.component';
import GTMSearchForm from './js/gtm-search-form.component';
import GTMFormChange from './js/gtm-form-change';
import GTMMicroSiteSearchForm from './js/gtm-microsite-search-form.component';

dcFactory.register(GTMTriggerComponent);
dcFactory.register(GTMTriggerGroupComponent);
dcFactory.register(GTMSearchForm);
dcFactory.register(GTMFormChange);
dcFactory.register(GTMMicroSiteSearchForm);
