import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';

gsap.registerPlugin(CustomEase);
const NUMBER_OF_PARTICLES = 10;

class Hearts {
    constructor() {}

    pop(e, element) {
        this.tl = gsap.timeline({
            repeat: 0,
        });
        this.tl.to(element, {
            scale: 1.3,
            duration: 0.6,
            transformOrigin: '50% 50%',
            ease: 'power2.inOut',
        });
        this.tl.to(element, {
            scale: 1,
            duration: 0.3,
            transformOrigin: '50% 50%',
            ease: 'power2.inOut',
            onComplete: () => {
                // Quick check if user clicked the button using a keyboard
                if (e.clientX === 0 && e.clientY === 0) {
                    const { top, left, width, height } = element.getBoundingClientRect();
                    const x = left + width / 2;
                    const y = top + height / 2;
                    for (let i = 0; i < NUMBER_OF_PARTICLES; i++) {
                        this._createParticle(x, y);
                    }
                } else {
                    for (let i = 0; i < NUMBER_OF_PARTICLES; i++) {
                        this._createParticle(e.clientX, e.clientY);
                    }
                }
            },
        });
    }

    _createParticle(x, y) {
        const particle = document.createElement('div');
        particle.classList.add('btn-particle');
        particle.innerHTML = `<svg class="icon" width="18" height="18" focusable="false">
                        <use xlink:href="#icon-heart"></use>
                    </svg>`;
        document.body.appendChild(particle);

        // Calculate a random size from 10px to 20px
        const size = Math.floor(Math.random() * 20 + 10);
        particle.style.width = `${size}px`;
        particle.style.height = `${size}px`;

        // Generate a random x & y destination within a distance of 75px from the mouse
        const destinationX = x + (Math.random() - 0.5) * 2 * 75;
        const destinationY = y + (Math.random() - 0.5) * 2 * 75;

        particle.style.transform = `translate(-50%, -50%) translate(${x}px, ${y}px)`;
        particle.style.opacity = '1';

        gsap.to(particle, {
            opacity: 0,
            x: destinationX,
            y: destinationY,
            transformOrigin: '50% 50%',
            duration: (Math.random() * 3000 + 500) / 1000,
            ease: CustomEase.create('easeName', '0, .9, .57, 1'),
            delay: (Math.random() * 200) / 1000,
            onComplete: () => {
                particle.remove();
            },
        });
    }
}

export default new Hearts();
