import { DcBaseComponent } from '@deleteagency/dc';
import BaseForm from 'general/js/forms/base-form';
import { eventBus } from 'general/js/events';
import GtmHelper from 'components/gtm-trigger/js/gtm-helper';
import notificationService from 'general/js/notifications-service';
import { EVENT_PREPOPULATED_FORM_FILLED } from 'components/prepopulated-form/js/prepopulated-form.component';
import ElementSpinner from 'general/js/spinner/element-spinner';

export const EVENT_FORM_SUBMIT = 'formSubmited';

export default class FormComponent extends DcBaseComponent {
    static getNamespace() {
        return 'form';
    }

    async onInit() {
        this.spinner = new ElementSpinner(this.element, { modifiers: ['with-overlay'] });
        this.form = new BaseForm(this.element, {
            submitElement: this.refs.submit,
            onBeforeSubmit: this._activateSpinner,
            errorsSummaryElement: this.refs.errorsSummary,
            onSuccessfulSubmit: this._onSuccessfulSubmit,
            onFailedSubmit: this._onFailedSubmit,
            withRecaptcha: this.options.withRecaptcha
        }, this);

        const { logicOrRequiredFields } = this.options;
        if (logicOrRequiredFields && Array.isArray(logicOrRequiredFields)) {
            logicOrRequiredFields.forEach((_name) => {
                eventBus.addListener(EVENT_PREPOPULATED_FORM_FILLED, this._onFormFilled);
                this.addListener(this.element[_name], 'input', this._setLogicOrFields);
            });
        }
    }

    _activateSpinner = () => {
        this.spinner.show();
    };

    _onFormFilled = (form) => {
        if (this.element.contains(form) || form.contains(this.element)) this._setLogicOrFields();
    };

    _setLogicOrFields = () => {
        const { logicOrRequiredFields } = this.options;
        const name = logicOrRequiredFields.find(
            (_name) =>
                this.element[_name] &&
                typeof this.element[_name].value === 'string' &&
                this.element[_name].value.length > 0
        );
        logicOrRequiredFields.forEach((_name) => {
            this.element[_name][!name || name === _name ? 'setAttribute' : 'removeAttribute'](
                'required',
                'true'
            );
        });
    };

    _onSuccessfulSubmit = (response) => {
        const { data } = response;
        this.spinner.hide();
        if (data.succeeded || data.result) {
            GtmHelper.send(null, {
                category: 'page',
                action: this.options.action,
                label: `${document.title} > ${this.options.label}`
            });
            notificationService.createFlash(data.message);
            if (this.options.name) {
                eventBus.emit(EVENT_FORM_SUBMIT, this.options.name);
            }
        } else {
            this.form._applyErrorsSummary([data.errorMessage]);
        }
    };

    _onFailedSubmit = ({ data }) => {
        this.spinner.hide();
        this.form._applyErrorsSummary(['internal error']);
        console.error(data);
    };
}
