import React from 'preact/compat';
import Predictive from './predictive';

class PredictiveStandalone extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value,
        };
    }

    // public method for setting value outside via component ref
    setValue = (newValue) => {
        this.setState({
            value: newValue,
        });
    };

    inputRef = (innerRef, element) => {
        innerRef(element);
        this.input = element;
    };

    focus() {
        if (this.input) {
            this.input.focus();
        }
    }

    onValueChange = (newValue) => {
        if (newValue && newValue.includes('<')) {
            newValue = newValue.replace('<', '');
        }

        this.setState({
            value: newValue,
        });
    };

    renderInputComponent = (inputProps) => {
        // bind inputProps.ref to our custom ref and call it manually
        return (
            <input
                {...inputProps}
                ref={this.inputRef.bind(this, inputProps.ref)}
            />
        );
    };

    render() {
        const { value } = this.state;

        return (
            <Predictive
                {...this.props}
                value={value}
                onValueChange={this.onValueChange}
                setValue={this.setValue}
                renderInputComponent={this.renderInputComponent}
                inputRef={this.input}
            />
        );
    }
}

PredictiveStandalone.propTypes = {
    ...Predictive.propTypes,
};

export default PredictiveStandalone;
