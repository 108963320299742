import SidebarMenuComponent from './sidebar-menu.component';

export default class SidebarMenuBurgerComponent extends SidebarMenuComponent {
    static getNamespace() {
        return 'burger-menu';
    }

    getMenuId() {
        return 'burger';
    }
}
