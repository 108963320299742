import { DcBaseComponent } from '@deleteagency/dc';
import { EVENT_OPEN_REGISTER } from 'components/site-sidebar/js/sidebar-user-tabs.component';
import { appConfig } from 'general/js/app-config';
import { clone, isPartialEqual } from 'general/js/object-helper';
import { recentSearchService, searchSaveService, siteNoti } from 'general/js/save-service';
import userService from 'general/js/user/user-service';
import { eventBus } from 'general/js/events';
import { menuService } from 'general/js/menu';
import { counter } from './counter';
import tippy from 'tippy.js';
import Hearts from './hearts';

const IGNORE_OPTIONS = [
    'url',
    'title',
    'propertyType',
    'countyName',
    'locationNameForSearch',
    'nodeAliasOfficeName',
    'alertFrequency',
    'id',
];

export default class SaveSearchBtn extends DcBaseComponent {
    static getNamespace() {
        return 'save-search-btn';
    }

    static getRequiredRefs() {
        return ['save', 'remove'];
    }

    onInit() {
        this._optimizedClone = this._getOptimizedClone();
        if (!this.options.url) this.options.url = window.location.href;
        recentSearchService.checkNeeded(this.options);
        this._findAndToggle(searchSaveService.getItems());
        searchSaveService.subscribeOnChanges(this._findAndToggle);
        this.addListener(this.element, 'click', this._onClick);
        
        const underlineLink = this.element.classList.contains('icon-underline-text-link');
        if (!underlineLink) {
            this.tooltip = tippy(this.element, {
                content: this.refs.save.innerText,
                appendTo: () => document.body,
                popperOptions: {
                    strategy: 'fixed',
                },
            });
        }

    }

    _onClick = (e) => {
        if (this.isSaved) {
            this.isSaved = false;
            this._toggleCaptions(false);
            searchSaveService.delete(null, this._optimizedClone);
            siteNoti.showNoti({ name: appConfig.translations.removedSearch });
            this.tooltip.setContent(this.refs.save.innerText);
        } else {
            Hearts.pop(e, this.element);
            if (userService.user === null) {
                switch (counter.value) {
                    case 0:
                        menuService.triggerOpen('user');
                        break;
                    case 1:
                        siteNoti.showNoti({ name: appConfig.translations.savedSearch });
                        break;
                    default:
                        eventBus.emit(EVENT_OPEN_REGISTER);
                        menuService.triggerOpen('user');
                }
                counter.value++;
            } else {
                this.isSaved = true;
                this._toggleCaptions(true);
                searchSaveService.saveItem(null, clone(this.options));
                this.tooltip.setContent(this.refs.remove.innerText);
                siteNoti.showNoti({ name: appConfig.translations.savedSearch });
            }
        }
    };

    _toggleCaptions = (isSaved) => {
        this.element.classList[isSaved ? 'add' : 'remove']('is-active');
        this.refs.save.style.display = isSaved ? 'none' : 'inline';
        this.refs.remove.style.display = isSaved ? 'inline' : 'none';
        $(this).toggleClass('is-active');
        $(this).find('.icon-text').css('display', isSaved ? 'none' : 'inline');
    };

    _findAndToggle = (items) => {
        this.isSaved = isPartialEqual(items, this._optimizedClone).length > 0;
        this._toggleCaptions(this.isSaved);
    };

    _getOptimizedClone = () => {
        const _clone = clone(this.options);
        IGNORE_OPTIONS.forEach((key) => {
            if (typeof _clone[key] !== 'undefined') delete _clone[key];
        });
        return _clone;
    };
}
