import './scss/index.scss';

import { dcFactory } from '@deleteagency/dc';
import SavedPropertiesComponent from './js/saved-properties.component';
import SavedSearchesComponent from './js/saved-searches.component';
import RecentSearchesComponent from './js/recent-searches.component';

dcFactory.register(SavedPropertiesComponent);
dcFactory.register(SavedSearchesComponent);
dcFactory.register(RecentSearchesComponent);
