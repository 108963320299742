import { DcBaseComponent, dcFactory } from '@deleteagency/dc';
import Cookies from 'js-cookie';
import { eventBus } from 'general/js/events';
import api from 'general/js/api';
import { appConfig } from 'general/js/app-config';
import ModalTriggerComponent from 'components/modal/js/modal-trigger.component';
import { EVENT_BEFORE_SUBMIT } from './cookie-settings-form.component';

const COOKIE_BANNER_FLAG_STORAGE_KEY = 'is-cookie-banner-seen';

export default class CookieBannerComponent extends DcBaseComponent {
    static getNamespace() {
        return 'cookie-banner';
    }

    static getRequiredRefs() {
        return ['close', 'accept', 'settingsTrigger'];
    }

    onInit() {
        if (this._isCookieBannerVisible()) {
            this.cookieFormModalTrigger = dcFactory.getChildComponent(this.refs.settingsTrigger, ModalTriggerComponent);
            eventBus.addListener(EVENT_BEFORE_SUBMIT, this._onCookieFormBeforeSubmit);
            this.addListener(this.refs.close, 'click', this._onCloseClick);
            this.addListener(this.refs.accept, 'click', this._onAcceptClick);
            this._show();
        }
    }

    _show() {
        this.element.classList.add('is-visible');
    }

    _hide() {
        this.element.classList.remove('is-visible');
    }

    _onCloseClick = () => {
        this._hide();
        this._setShortTermFlag();
    };

    _onAcceptClick = () => {
        this._hide();
        api.post(this.options.acceptEndpointUrl);
    };

    _onCookieFormBeforeSubmit = () => {
        this.cookieFormModalTrigger.closeModal();
        this._hide();
    };

    _isCookieBannerVisible() {
        return !this._isShortTermFlagSet() && !this._isLongTermFlagSet();
    }

    _setShortTermFlag() {
        sessionStorage.setItem(COOKIE_BANNER_FLAG_STORAGE_KEY, '1');
    }

    _isShortTermFlagSet() {
        return sessionStorage.getItem(COOKIE_BANNER_FLAG_STORAGE_KEY);
    }

    _isLongTermFlagSet() {
        return Cookies.get(appConfig.cookieMark);
    }
}
