import { DcBaseComponent } from '@deleteagency/dc';
import { createFragment, createElement } from '@deleteagency/dom-helper';
import api from 'general/js/api';

const ENDPOINT_URL = '/api/getToken';
const TOKEN_NAME = '__RequestVerificationToken';
const TOKEN_SELECTOR = `input[type="hidden"][name="${TOKEN_NAME}"]`;

export default class KenticoFormComponent extends DcBaseComponent {
    static getNamespace() {
        return 'kentico-form';
    }

    onInit() {
        if (this.element.tagName !== 'FORM') {
            throw new Error('Component must be mounted on form');
        }

        const url = window.location.origin + ENDPOINT_URL;
        this._getToken(url);
    }

    _getToken = (url) => {
        api.get(url)
            .then((result) => {
                const htmlString = result.data;
                const htmlFragment = createFragment(htmlString);
                const tempContainer = createElement('<div></div>');
                tempContainer.appendChild(htmlFragment);
                const input = tempContainer.firstElementChild;

                if (input && input.name === TOKEN_NAME) {
                    this._setInput(input);
                } else {
                    throw new Error('Response must contain valid html string with input');
                }
            })
            .catch((error) => {
                console.dir(error);
            });
    };

    _setInput = (input) => {
        const currentInputs = [...this.element.querySelectorAll(TOKEN_SELECTOR)];
        currentInputs.forEach((elem) => elem.remove());
        this.element.appendChild(input);
    };
}
