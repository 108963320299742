import NotificationsService from 'general/js/notifications-service';
import { appConfig } from 'general/js/app-config';

class FlashMessages {
    constructor() {
        this.#checkUrl();
    }

    #checkUrl = () => {
        if (window.location.hash && window.location.hash.length > 0) {
            const value = window.location.hash.substring(1);
            if (appConfig.flashMessages && appConfig.flashMessages[value]) {
                this.#showMessage(appConfig.flashMessages[value]);
            }
        }
    };

    #showMessage = (message) => {
        const onClose = () => { window.location.hash = ''; };
        NotificationsService.createFlash(message, 3000, onClose);
    };
}

const instance = new FlashMessages();
export default instance;
