import { DcBaseComponent } from '@deleteagency/dc';
import { createElement } from '@deleteagency/dom-helper';
import ControlAdapter from './control-adapter';
import { recentSearchService } from 'general/js/save-service';
import analyticsService from 'general/js/analytics-service';

export default class CoreSearchComponent extends DcBaseComponent {
    #controlAdapter = null;

    static getNamespace() {
        return 'core-search';
    }

    static getRequiredRefs() {
        return ['priceRanges'];
    }

    onInit() {
        const { toggleControlType } = this.options;
        const { trigger, triggerBuy, triggerRent } = this.refs;
        const { addBuyOptions, addRentOptions, removeOptions } = this;

        this.#controlAdapter = new ControlAdapter(
            toggleControlType,
            trigger,
            triggerBuy,
            triggerRent,
            addBuyOptions,
            addRentOptions,
            removeOptions
        );

        this.addListener(this.element, 'submit', this._sendGAEvent);
    }

    _sendGAEvent = () => {
        const isBuySelected = this.refs.triggerBuy ? this.refs.triggerBuy.checked : false;
        const locationInput = this.element.getElementsByClassName("react-autosuggest__input").length > 0 ?
             this.element.getElementsByClassName("react-autosuggest__input")[0] : null;
        const gaCategory = "Main Search";
        const gaAction = isBuySelected ? "Buy" : "Rent";
        let gaLabel = "Empty";
        if(locationInput && !!locationInput.value){
            //bypass dangerous query exception
            if (locationInput.value.startsWith('<')) {
                locationInput.value = locationInput.value.replace('<', '');
            }

            gaLabel = locationInput.value;
        }
        
        analyticsService.sendEvent(gaCategory, gaAction, gaLabel);
        recentSearchService.searched();
    };

    removeOptions = () => {
        const { priceRanges } = this.refs;
        if (priceRanges) {
            priceRanges.forEach(range => {
                [...range.children].forEach((child, index) => {
                    if (index > 0) {
                        child.parentElement.removeChild(child);
                    }
                });
            });
        }
    };

    addBuyOptions = () => {
        this.#addOptions(this.options.priceRangeBuy);
    };

    addRentOptions = () => {
        this.#addOptions(this.options.priceRangeRent);
    };

    #addOptions(options) {
        const { priceRanges } = this.refs;
        if (priceRanges) {
            priceRanges.forEach(range => {
                options.forEach(option => {
                    const element = createElement(
                        `<option value="${option.value}">${option.name}</option>`
                    );
                    range.appendChild(element);
                });
            });
        }
    }
}
