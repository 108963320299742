import { modalService } from 'general/js/modal';

// This class is global entity intended to notify the user
// if something important happened in response to his action on the website
// For example: geolocation api failed, a property was successfully added to his favourites, etc
class NotificationsService {
    _getFlashContentWithLayout(str) {
        return `
            <div class="modal-content">
                <div class="modal-content__body">
                    ${str}                   
                </div>    
                <button class="btn mt-3" type="button" data-modal-close>Dismiss</button>           
            </div>     
            `;
    }

    createFlash(str, timeout = 3000, onClose = null) {
        const flash = modalService.create(this._getFlashContentWithLayout(str), {
            cssModifier: 'flash',
            cross: false,
            destroyOnClose: true,
            onClose
        });
        flash.open();
        setTimeout(() => {
            flash.close();
        }, timeout);
        return flash;
    }

    error(content) {
        console.error(content);
    }

    warn(content) {
        console.warn(content);
    }

    info(content) {
        console.info(content);
    }
}

export default (new NotificationsService());

