import './scss/index.scss';

import { dcFactory } from '@deleteagency/dc';
import RvWizardComponent from './js/rv-wizard.component';
import RvWizardTriggerComponent from './js/rv-wizard-trigger.component';
import RvPreinputedComponent from './js/rv-preinputed.component';
import RvRadio from './js/rv-radio.component';

dcFactory.register(RvWizardComponent);
dcFactory.register(RvWizardTriggerComponent);
dcFactory.register(RvPreinputedComponent);
dcFactory.register(RvRadio);
