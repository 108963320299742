import { DcBaseComponent } from '@deleteagency/dc';
import { eventBus } from 'general/js/events';
import { modalService, MODAL_WINDOW_CLOSE } from 'general/js/modal';
import { getSearchFromLocation } from 'general/js/url-helper';

const AUTO_OPEN_QUERY_PARAM_NAME = 'type';
const AUTO_OPEN_QUERY_PARAM_VALUE = 'WIN1';

export default class ReviewModalComponent extends DcBaseComponent {
    static getNamespace() {
        return 'review-modal';
    }

    onInit() {
        const query = getSearchFromLocation(window.location);
        if (query[AUTO_OPEN_QUERY_PARAM_NAME] === AUTO_OPEN_QUERY_PARAM_VALUE) {
            this.openModal();
        }
    }

    openModal() {
        this._toggleParent(false);
        const modal = modalService.create(this.element);
        eventBus.addListener(MODAL_WINDOW_CLOSE, () => this._toggleParent(true));
        modal.open();
    }

    _toggleParent(isEnabled) {
        this.element
            ?.closest('[data-dc-enter-viewport]')
            ?.classList[isEnabled ? 'add' : 'remove']('subscribe-on-viewport-entrance');
    }
}
