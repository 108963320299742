import { DcBaseComponent } from '@deleteagency/dc';
import notificationsService from 'general/js/notifications-service';
import userService from 'general/js/user/user-service';
import BaseForm from 'general/js/forms/base-form';
import { menuService } from 'general/js/menu';
import ElementSpinner from 'general/js/spinner/element-spinner';

export default class LogoutFormComponent extends DcBaseComponent {
    static getNamespace() {
        return 'logout-form';
    }

    async onInit() {
        this.spinner = new ElementSpinner(this.element, { modifiers: ['with-overlay'] });
        this.form = new BaseForm(this.element, {
            onBeforeSubmit: this._onBeforeSubmit,
            onSuccessfulSubmit: this._onSuccessfulSubmit,
            onFailedSubmit: this._onFailedSubmit,
            withRecaptcha: false
        }, this);
    }

    _onSuccessfulSubmit = ({ data }) => {
        this.spinner.hide();
        userService.refetchUser();
        notificationsService.createFlash(data.successMessage);
        menuService.closeActive();
    };

    _onBeforeSubmit = () => {
        this.spinner.show();
    };

    _onFailedSubmit = (error) => {
        this.spinner.hide();
        console.error(error);
    };
}
