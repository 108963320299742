export default class RootService {
    constructor(options) {
        this.options = options;
        this._items = this._getItemsFromLocalStorage();
        this._subscribers = [];
    }

    _getItemsFromLocalStorage = () => {
        const result = window.localStorage.getItem(this.options.name);
        return result !== null ? JSON.parse(result) : this.options.startCollection;
    };

    _writeInLocalStorage = () => {
        window.localStorage.setItem(this.options.name, JSON.stringify(this._items));
    };

    _cleanLocalStorage = () => {
        window.localStorage.removeItem(this.options.name);
    };

    subscribeOnChanges = (subscriber) => {
        this._subscribers.push(subscriber);
    };

    _callSubscribers = () => {
        this._subscribers.forEach((subscriber) => {
            if (typeof subscriber === 'function') subscriber(this.getItems());
        });
    };

    getItems = () => this._items;
}
