import AbstractVideo from './abstract-video';

export default class Html5Video extends AbstractVideo {
    constructor(options) {
        super(options);

        this.videoElement = null;
    }

    getVideoElement() {
        if (this.videoElement === null) {
            this.emitInit();
            this.videoElement = this.getPlayerElement().querySelector('video');
            this.videoElement.addEventListener('play', this._onPlay);
            this.videoElement.addEventListener('pause', this._onPause);
        }

        return this.videoElement;
    }

    _onPlay = () => {
        if (!this.isStarted) {
            this.emitStart();
        }
        this.emitPlay();
    };

    _onPause = () => {
        this.emitPause();
    };

    handlePlay() {
        this.getVideoElement().play();
    }

    handlePause() {
        this.getVideoElement().pause();
    }
}
