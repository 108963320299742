import React from 'preact/compat';
import nanoid from 'nanoid';
import { getSearchFromLocation } from 'general/js/url-helper';

const Hiddens = () => {
    const params = getSearchFromLocation(window.location);
    return Object.keys(params).map(key => (
        <input key={nanoid()} type="hidden" name={key} value={params[key]} />
    ));
};

export default Hiddens;

