import { DcBaseComponent } from '@deleteagency/dc';
import focusLock from 'dom-focus-lock';
import { eventBus } from 'general/js/events';
import { menuService } from 'general/js/menu';

export const EVENT_SIDEBAR_MENU_CLOSED = 'eventSideBarMenuClosed';

/**
 * This is an abstract class to share similar sidebar menu functionality
 */
export default class SidebarMenuComponent extends DcBaseComponent {
    onInit() {
        this.isFromUrl = false;
        menuService.register(this.getMenuId(), this);
        this.addListener(this.refs.close, 'click', this._onCloseClick);
        window.addEventListener('hashchange', this._openUserTabByUrl, false);
        this._openUserTabByUrl();
    }

    _openUserTabByUrl = () => {
        const hash = window.location.hash;
        if (hash) {
            const routeParts = hash.slice(1).split('/');
            const routeMenuId = routeParts[0];
            const menuId = this.getMenuId();
            if (menuId === routeMenuId) {
                // open current menu
                menuService.triggerOpen(this.getMenuId());
                // do something specific in the child class
                this.afterOpenByUrl(routeParts);
                this.isFromUrl = true;
            }
        }
    };

    afterOpenByUrl() {}

    getMenuId() {
        throw new Error('SidebarMenuComponent:getMenuId is not implemented');
    }

    _onCloseClick = () => {
        menuService.triggerClose(this.getMenuId());
    };

    onOpen() {
        document.documentElement.classList.add('is-sidebar-opened');
        this.element.classList.add('is-opened');
        focusLock.on(this.element);
    }

    onClose() {
        document.documentElement.classList.remove('is-sidebar-opened');
        this.element.classList.remove('is-opened');
        focusLock.off(this.element);

        if (this.isFromUrl) {
            window.history.back();
        }
        eventBus.emit(EVENT_SIDEBAR_MENU_CLOSED);
    }
}
