import { DcBaseComponent } from '@deleteagency/dc';
import GtmHelper from 'components/gtm-trigger/js/gtm-helper';

export default class GtmFormChange extends DcBaseComponent {
    static getNamespace() {
        return 'gtm-form-change';
    }

    onInit() {
        this.addListener(this.element, 'change', () => GtmHelper.send(null, {
            category: this.options.category,
            action: this.options.action,
            label: this.options.label
        }));
    }
}
