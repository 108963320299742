import { DcBaseComponent } from '@deleteagency/dc';

export class FilterSearch_PopularSearches extends DcBaseComponent {
    static getNamespace() {
        return 'filter-search';
    }

    onInit() {
        const searchSection = document.querySelector('.filter-quick-search__search-section');
        const searchWrapper = $('.filter-quick-search__input-wrapper');
        const search = $('.filter-quick-search__input-text');
        const popLocations = $('.popular-locations');
        const popLocationsListItems = $('.popular-locations__list-item');

        const submitBtn = $('.search-results-main-filters__form button[type="submit"]');
        const modalSubmitBtn = $('.search-results-all-filters-modal__form button[type="submit"]');

        const autoSuggestContainer = $('.react-autosuggest__suggestions-container');

        this.disableSearchCheck();

        popLocationsListItems.each(() => {
            $(this).on('click', () => {
                search.val($(this).text());
                popLocations.removeClass('active');

                if (search.val().length >= 3) {
                    submitBtn.removeAttr('disabled');
                    modalSubmitBtn.removeAttr('disabled');
                } else {
                    submitBtn.attr('disabled', 'disabled');
                    modalSubmitBtn.attr('disabled', 'disabled');
                }

                // on mobile - submit the search to load the results
                if (window.innerWidth < 768) {
                    if (search.val().length >= 3) {
                        setTimeout(() => {
                            modalSubmitBtn.trigger('click');
                        }, 500);
                    }
                }
            });
        });

        search.on('focus', () => {
            searchWrapper.addClass('active');
            if (search.val() === '') {
                popLocations.addClass('active');
            }
        });

        search.on('change paste keyup', () => {
            if (search.is(':focus') || search.val() !== '') {
                searchWrapper.addClass('active');
            }

            if (search.val() !== '') {
                searchWrapper.removeClass('active');
                popLocations.removeClass('active');
            }

            if (search.val() === '') {
                searchWrapper.addClass('active');
                popLocations.addClass('active');
            }
        });

        search.on('keydown', () => {
            search.attr('data-location', '');
        });

        let popLocationsClicked = false;
        popLocations.on('click', () => {
            popLocationsClicked = true;
            this.disableSearchCheck();
        });

        $('body').on('mouseup', function(e) {
            if (
                !search.is(e.target) &&
                search.has(e.target).length === 0 &&
                !popLocations.is(e.target) &&
                popLocations.has(e.target).length === 0
            ) {
                popLocations.removeClass('active');
            }
            popLocationsClicked = false;
        });

        this.observeMutations(this.autoSearchOnSuggestionMobile, autoSuggestContainer[0]);
        this.observeMutations(this.disableSearchCheck, searchSection[0]);

        $(document).on('click', '.predictive__clear-btn', function() {
            search.val('');
            search.attr('data-location', '');
            submitBtn.attr('disabled', 'disabled');
            modalSubmitBtn.attr('disabled', 'disabled');
        });

        submitBtn.on('click', () => {
            this.setLocalStorageToSearchValue(search);
        });

        modalSubmitBtn.on('click', () => {
            this.setLocalStorageToSearchValue(search);
        });
    }

    observeMutations(callback, targetNode = document.body) {
        const observer = new MutationObserver(function(mutationsList, observer) {
            for (let mutation of mutationsList) {
                // Check if nodes were added or removed
                if (mutation.type === 'childList') {
                    callback();
                }
            }
        });

        const config = { childList: true, subtree: true };
        observer.observe(targetNode, config);
    }

    autoSearchOnSuggestionMobile() {
        const search = $('.search__input-text');
        const form = $('.search-results-main-filters__form');
        const modalSubmitBtn = $('.search-results-all-filters-modal__form button[type="submit"]');
        const suggestionOptions = form.find('.react-autosuggest__suggestion');
        suggestionOptions.off('click');
        suggestionOptions.on('click', function() {
            search.attr('data-location', 'valid');
            if (window.innerWidth < 768) {
                if (search.val().length >= 3) {
                    setTimeout(() => {
                        modalSubmitBtn.trigger('click');
                    }, 500);
                }
            }
        });
    }

    disableSearchCheck() {
        const search = $('.filter-quick-search__input-text');

        const submitBtn = $('.search-results-main-filters__form button[type="submit"]');
        const modalSubmitBtn = $('.search-results-all-filters-modal__form button[type="submit"]');

        if (search.val().length >= 3) {
            submitBtn.removeAttr('disabled');
            modalSubmitBtn.removeAttr('disabled');
        } else {
            submitBtn.attr('disabled', 'disabled');
            modalSubmitBtn.attr('disabled', 'disabled');
        }
    }

    setLocalStorageToSearchValue(inputElement) {
        const searchInput = inputElement.val() || '';
        const storedSearches =
            JSON.parse(localStorage.getItem('winkworth_recently_searched_locations')) || [];
        const searchValueWithTimestamp = {
            location: searchInput,
            timestamp: Date.now(),
        };

        let found = false;
        const updatedSearchesWithTimestamp = storedSearches.map((search) => {
            if (search.location === searchInput) {
                found = true;
                return searchValueWithTimestamp;
            }
            return search;
        });

        if (!found) {
            updatedSearchesWithTimestamp.push(searchValueWithTimestamp);
        }

        if (inputElement.attr('data-location') === 'valid') {
            localStorage.setItem(
                'winkworth_recently_searched_locations',
                JSON.stringify(updatedSearchesWithTimestamp)
            );
        }
    }
}