import { DcBaseComponent } from '@deleteagency/dc';
import Cookie from 'js-cookie';
import notificationsService from 'general/js/notifications-service';
import api from 'general/js/api';
import { eventBus } from 'general/js/events';

const TIME_COOKIE_EXPIRES = 1 / 48; //number 1 means 1 day, and 1/48 equals 30min
const COOKIE_KEY = 'closestPosition';
const GEOLOCATION_ERROR_PERMISSION_DENIED = 1;
const GEOLOCATION_ERROR_POSITION_UNAVAILABLE = 2;
const GEOLOCATION_ERROR_TIMEOUT = 3;
const DEFAULT_GEOLOCATION_ERROR =
    'Using your location is unavailable at the moment. Please try again later';

export const EVENT_CLOSEST_LOCATION_RECEIVED = 'location-button:closest-location-received';

export default class LocationButtonComponent extends DcBaseComponent {
    static getNamespace() {
        return 'location-button';
    }

    onInit() {
        this.isInProgress = false;
        this.addListener(this.element, 'click', this._onClick);
    }

    _onClick = () => {
        if (!this.isInProgress) {
            this.isInProgress = true;
            this.element.classList.add('is-loading');
            this._fetchAndProcessLocationsByPosition().then(() => {
                this.element.classList.remove('is-loading');
                this.isInProgress = false;
            }, () => {
                this.isInProgress = false;
            });
        }
    };

    _fetchAndProcessLocationsByPosition = () => this._getLocation()
        .then((location) => {
            eventBus.emit(
                EVENT_CLOSEST_LOCATION_RECEIVED, { location, target: this.options.target }
            );
        });

    _getLocationFromCookie = () => new Promise((resolve, reject) => {
        const location = Cookie.get(COOKIE_KEY);
        if (location) {
            resolve(JSON.parse(location));
        } else {
            reject();
        }
    });

    _getLocation = () => this._getLocationFromCookie()
        .catch(() => this.getPosition()
            .then(position => this.getClosestLocationByPosition(position))
            .then((response) => {
                const location = response.data[0];
                Cookie.set(COOKIE_KEY, JSON.stringify(location), { expires: TIME_COOKIE_EXPIRES });
                return location;
            }))
        .catch((error) => {
            notificationsService.warn(this.getGeolocationErrorMessage(error));
            return Promise.reject(error);
        });

    getPosition = () => new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            (position) => { resolve(position); },
            (error) => { reject(error); }
        );
    });

    getClosestLocationByPosition = position => api.get(this.options.endpointUrl, {
        params: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        }
    });

    getGeolocationErrorMessage(error) {
        switch (error.code) {
            case GEOLOCATION_ERROR_PERMISSION_DENIED:
                return 'Your device and/or browser restricted us to use your location. Please check your settings';
            case GEOLOCATION_ERROR_POSITION_UNAVAILABLE:
            case GEOLOCATION_ERROR_TIMEOUT:
                return DEFAULT_GEOLOCATION_ERROR;
            default:
                return error.message || DEFAULT_GEOLOCATION_ERROR;
        }
    }
}
