import { render } from 'preact';
import React from 'preact/compat';
import Notification from 'general/js/save-service/notification';

export default class SiteNoti {
    constructor() {
        this.container = document.createElement('div');
        document.body.appendChild(this.container);
        this._init();
    }

    _setInstance = (instance) => {
        this._instance = instance;
    };

    showNoti = (content) => {
        if (this._instance) this._instance.showNoti(content);
    };

    _init = () => {
        render(<Notification ref={this._setInstance}/>, this.container);
    };
}
