import { DcBaseComponent } from '@deleteagency/dc';

export class QuickSearch_PopularSearches extends DcBaseComponent {
    static getNamespace() {
        return 'quick-search';
    }

    onInit() {
        const searchSection = document.querySelector('.updated-quick-search__search-section');

        const searchWrapper = $('.updated-quick-search__input-wrapper');
        const search = $('.updated-quick-search__input-text');
        const popLocations = $('.popular-locations');
        const popLocationsListItems = $('.popular-locations__list-item');
        const submitBtn = $('.updated-quick-search__input-wrapper button[type="submit"]');

        popLocationsListItems.each(function() {
            $(this).on('click', () => {
                search.val($(this).text());
                popLocations.removeClass('active');

                if (search.val().length >= 3) {
                    submitBtn.each(function() {
                        $(this).removeAttr('disabled');
                    });
                } else {
                    submitBtn.each(function() {
                        $(this).attr('disabled', 'disabled');
                    });
                }
            });
        });

        search.on('focus', () => {
            searchWrapper.addClass('active');
            if (search.val() === '') {
                popLocations.addClass('active');
            }
        });

        search.on('change paste keyup', () => {
            const value = search.val();

            if (search.is(':focus') || value !== '') {
                searchWrapper.addClass('active');
            }

            if (value !== '') {
                searchWrapper.removeClass('active');
                popLocations.removeClass('active');
            }

            if (value === '') {
                searchWrapper.addClass('active');
                popLocations.addClass('active');
            }
        });

        search.on('keydown', () => {
            search.attr('data-location', '');
        });

        let popLocationsClicked = false;
        popLocations.on('click', () => {
            popLocationsClicked = true;
            this.disableSearchCheck();
        });

        $('body').on('mouseup', function(e) {
            if (
                !search.is(e.target) &&
                search.has(e.target).length === 0 &&
                !popLocations.is(e.target) &&
                popLocations.has(e.target).length === 0
            ) {
                popLocations.removeClass('active');
            }
            popLocationsClicked = false;
        });

        this.observeMutations(this.setSuggestionAsValidLocation, searchSection[0]);
        this.observeMutations(this.disableSearchCheck, searchSection[0]);

        $(document).on('click', '.predictive__clear-btn', function() {
            search.val('');
            search.attr('data-location', '');
            submitBtn.attr('disabled', 'disabled');
        });

        submitBtn.on('click', () => {
            this.setLocalStorageToSearchValue(search);
        });
    }

    observeMutations(callback, targetNode = document.body) {
        const observer = new MutationObserver(function(mutationsList, observer) {
            for (let mutation of mutationsList) {
                // Check if nodes were added or removed
                if (mutation.type === 'childList') {
                    callback();
                }
            }
        });

        const config = { childList: true, subtree: true };
        observer.observe(targetNode, config);
    }

    setSuggestionAsValidLocation() {
        const suggestionOptions = $('.react-autosuggest__suggestion');
        const search = $('.updated-quick-search__input-text');
        suggestionOptions.off('click').on('click', function() {
            search.attr('data-location', 'valid');
            $('.popular-locations').removeClass('active');
        });
    }

    disableSearchCheck() {
        const search_value = $('.updated-quick-search__input-text');
        const submitBtn = $('.quick-search__input-wrapper button[type="submit"]');

        if (search_value.val().length >= 3) {
            submitBtn.removeAttr('disabled');
        } else {
            submitBtn.attr('disabled', 'disabled');
        }
    }

    setLocalStorageToSearchValue(inputElement) {        
        const searchInput = inputElement.val() || '';
        
        const storedSearches =
            JSON.parse(localStorage.getItem('winkworth_recently_searched_locations')) || [];
        const searchValueWithTimestamp = {
            location: searchInput,
            timestamp: Date.now(),
        };

        let found = false;
        const updatedSearchesWithTimestamp = storedSearches.map((search) => {
            if (search.location === searchInput) {
                found = true;
                return searchValueWithTimestamp;
            }
            return search;
        });

        if (!found) {
            updatedSearchesWithTimestamp.push(searchValueWithTimestamp);
        }
        
        if (inputElement.attr('data-location') === 'valid') {
            localStorage.setItem(
                'winkworth_recently_searched_locations',
                JSON.stringify(updatedSearchesWithTimestamp)
            );
        }        
    }
}
