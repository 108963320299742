import { DcBaseComponent } from '@deleteagency/dc';
import notificationsService from 'general/js/notifications-service';
import userService from 'general/js/user/user-service';
import BaseForm from 'general/js/forms/base-form';
import GtmHelper from 'components/gtm-trigger/js/gtm-helper';
import ElementSpinner from 'general/js/spinner/element-spinner';
import { eventBus } from 'general/js/events';

export const EVENT_LOGIN = 'eventUserLogin';

export default class LoginFormComponent extends DcBaseComponent {
    static getNamespace() {
        return 'login-form';
    }

    async onInit() {
        this.spinner = new ElementSpinner(this.element, { modifiers: ['with-overlay'] });
        this.form = new BaseForm(this.element, {
            submitElement: this.refs.submit,
            onBeforeSubmit: this._onBeforeSubmit,
            errorsSummaryElement: this.refs.errorsSummary,
            onSuccessfulSubmit: this._onSuccessfulSubmit,
            onFailedSubmit: this._onFailedSubmit,
            withRecaptcha: this.options.withRecaptcha
        }, this);
    }

    _onSuccessfulSubmit = ({ data }) => {
        this.spinner.hide();
        GtmHelper.send(null, {
            category: 'My Winkworth',
            action: 'Login',
            label: 'My Winkworth Login'
        });
        userService.refetchUser();
        notificationsService.createFlash(data.successMessage);
        this._cleanInputedData();
        eventBus.emit(EVENT_LOGIN);
    };

    _cleanInputedData = () => {
        const elems = [...this.element.elements];
        if (elems.length > 0) {
            elems.forEach((input) => {
                const type = input.getAttribute('type');
                if (type && type !== 'hidden' && type !== 'button' && type !== 'submit') input.value = '';
            });
        }
    };

    _onBeforeSubmit = () => {
        this.spinner.show();
    };

    _onFailedSubmit = (error) => {
        this.spinner.hide();
        console.error(error);
    };
}
