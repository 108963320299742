import { DcBaseComponent } from '@deleteagency/dc';
import { deviceObserver } from '@deleteagency/device-observer';
import isArray from 'lodash/isArray';
import isElement from 'lodash/isElement';
import {
    addPassiveEventListener,
    removePassiveEventListener
} from 'general/js/passive-event-listener-polyfill';

const SHIFT_AMOUNT = 85;
const COLUMN_Z_INDEX = 31;
const SHIFT_MULTIPLIER = 3;

export default class ShiftColumnComponent extends DcBaseComponent {
    static getNamespace() {
        return 'shift-column';
    }

    onInit() {
        this.shiftAmount = this.options.shiftAmount || SHIFT_AMOUNT;
        this.#initColumns();
    }

    onDestroy() {
        this.#destroyPassiveListeners();
    }

    #isRefsExist = () => isElement(this.refs.subnav) &&
        isArray(this.refs.columns) &&
        this.refs.columns.length > 0 &&
        this.refs.columns.reduce((acc, column) => isElement(column), false);

    #initColumns = () => {
        if (this.#isRefsExist()) {
            this.refs.columns.forEach(this.#handleColumn);
        }
    };

    #handleColumn = (column) => {
        if (this.#isOverlapping(this.refs.subnav, column) && deviceObserver.is('>', 'mobile')) {
            this.#setColumnPosition(column);
            this.#createPassiveListener(window, 'scroll', this.#getColumnHandler(column));
        } else {
            this.#resetColumnZIndex(column);
        }
    };

    #isOverlapping = (subnav, column) => {
        const subnavBotEdge = window.pageYOffset + subnav.getBoundingClientRect().bottom;
        const columnTopEdge = window.pageYOffset + column.getBoundingClientRect().top;
        return subnavBotEdge >= columnTopEdge;
    };

    #resetColumnZIndex = (column) => {
        column.style.zIndex = 0;
    };

    #setColumnPosition = (column) => {
        const maxShift = this.shiftAmount * SHIFT_MULTIPLIER;
        if (window.pageYOffset <= maxShift) {
            const currentShift = Math.round(window.pageYOffset / SHIFT_MULTIPLIER);
            column.style.transform = `translateY(${currentShift}px)`;
            column.style.zIndex = COLUMN_Z_INDEX;
        } else {
            column.style.transform = `translateY(${maxShift})`;
            column.style.zIndex = 0;
        }
    };

    #getColumnHandler = (column) => {
        const handler = () => {
            this.#setColumnPosition(column);
        };

        return () => {
            window.requestAnimationFrame(handler);
        };
    };

    #createPassiveListener = (elem, eventName, eventCallback) => {
        if (!elem || typeof elem.addEventListener !== 'function') return;

        if (!Array.isArray(this.passiveListeners)) {
            this.passiveListeners = [];
        }

        addPassiveEventListener(elem, eventName, eventCallback);
        this.passiveListeners.push({
            elem,
            eventName,
            eventCallback
        });
    };

    #destroyPassiveListeners = () => {
        if (Array.isArray(this.passiveListeners)) {
            this.passiveListeners.forEach(({ elem, eventName, eventCallback }) => {
                removePassiveEventListener(elem, eventName, eventCallback);
            });
        }

        this.passiveListeners = [];
    };
}
