import { DcBaseComponent, dcFactory } from '@deleteagency/dc';
import { deviceObserver } from '@deleteagency/device-observer';
import { DEVICE_MOBILE } from 'general/js/app';
import { eventBus, events } from 'general/js/events';
import Scroller from 'general/js/scroller';

const CLASS_ACTIVE = 'is-active';
const REVEAL_DELAY = 1000;

export default class SubnavTabsComponent extends DcBaseComponent {
    constructor(element) {
        super(element);

        this.tabs = this.refs.tabs;
        this.tabsById = this.refs.tabs.reduce((result, tab) => {
            result[tab.id] = tab;
            return result;
        }, {});
        
        this.tabsByGroup = this.refs.tabs.reduce((result, tab) => {
            result[tab.dataset.group] = tab;
            return result;
        }, {});
        
        this._activeTabId = null;
        this._activeGroupTabId = null;
    }

    static getRequiredRefs() {
        return ['tabs'];
    }

    static getNamespace() {
        return 'subnav-tabs';
    }

    onInit() {
        if (this.tabs.length === 0) {
            throw new Error('There must be at least 1 tab');
        }

        this.eventId = this.options.customEventId || events.EVENT_SUBNAV_TAB_TRIGGER;
        this._setInitialTab();

        // handle outer triggers
        eventBus.addListener(this.eventId, this._onTriggerClick);
    }

    _onTriggerClick = (tabId) => {
        if (tabId) {
            this._activate(tabId);
        }
    };

    _setInitialTab() {
        const activeTab = this.tabs.find((tab) => tab.classList.contains(CLASS_ACTIVE));
        const activeGroupTab = this.tabs.find((tab) => tab.classList.contains(CLASS_ACTIVE));

        if (activeTab) {
            this._activeTabId = activeTab.id;
        }

        if (activeGroupTab) {
            this._activeGroupTabId = activeTab.dataset.group;
        }

        const mapActive = sessionStorage.getItem('mapActive');
        if (mapActive == 'true') {
            this._activate('map');
            this._activeTabId = 'map';
            this._activeGroupTabId = 'map';
        }

        if (window.location.hash) {
            setTimeout(() => {
                this.element.classList.remove('is-hidden');
            }, REVEAL_DELAY);
        } else {
            this.element.classList.remove('is-hidden');
        }
        
    }

    _activate(tabId) {

        if (tabId == 'map') {
            document.querySelector('.search-results-tabs').classList.add('inactive');
            document.querySelector('html').classList.add('is-locked');
            sessionStorage.setItem('mapActive', 'true');
        } else {
            document.querySelector('.search-results-tabs').classList.remove('inactive');
            document.querySelector('html').classList.remove('is-locked');
            sessionStorage.setItem('mapActive', 'false');
        }
        
        if (tabId !== this._activeTabId && tabId in this.tabsById) {
            this._activateTab(tabId, this._activeTabId);
            this._activeTabId = tabId;
        }
        if (tabId !== this._activeGroupTabId) {
            this._activateGroupTab(tabId, this._activeGroupTabId);
            this._activeGroupTabId = tabId;
        }
    }

    _activateTab(newTabId, oldTabId) {
        const contentToShow = this.tabsById[newTabId];
        dcFactory.init(contentToShow, true);

        let navScrollOffset = 0;

        if (history.scrollRestoration) {
            history.scrollRestoration = 'manual';
        }

        if (this.options.offsetElement) {
            const offsetElement = document.getElementById(this.options.offsetElement);
            const header = document.getElementById('header');
            const searchResultsTabs = document.getElementById('search-results-tabs');
            if (deviceObserver.is('<=', DEVICE_MOBILE)) {
                navScrollOffset = header.offsetHeight + searchResultsTabs?.offsetHeight;
            } else {
                navScrollOffset = header.offsetHeight + offsetElement.offsetHeight;
            }
        }

        new Scroller(this.element, {
            duration: 400,
            scrollOffset: navScrollOffset,
        })
            .scrollToTop()
            .then(() => {
                if (oldTabId) {
                    this.tabsById[oldTabId].classList.remove(CLASS_ACTIVE);
                }
                requestAnimationFrame(() => {
                    this.tabsById[newTabId].classList.add(CLASS_ACTIVE);
                });
                if (history.scrollRestoration) {
                    history.scrollRestoration = 'auto';
                }
                eventBus.emit(events.REFRESH_SCROLL_TRIGGER_EVENT);
            });
    }

    _activateGroupTab(newTabId, oldTabId) {
        const contentToShow = this.tabsByGroup[newTabId];
        dcFactory.init(contentToShow, true);

        if (oldTabId) {
            this.tabsByGroup[oldTabId]?.classList?.remove(CLASS_ACTIVE);
        }
        requestAnimationFrame(() => {
            this.tabsByGroup[newTabId]?.classList?.add(CLASS_ACTIVE);
        });
    }
}
