import { DcBaseComponent } from '@deleteagency/dc';
import intersectionHelper from 'general/js/intersection-helper';

export default class EnterViewportComponent extends DcBaseComponent {
    static getNamespace() {
        return 'enter-viewport';
    }

    onInit() {
        intersectionHelper.enterViewportOnce(this.element, this.onEnter);
    }

    onEnter = () => {
        this.element.addEventListener('transitionend', this._afterEntered);
        this.element.classList.add('is-viewport-entered');
    };

    _afterEntered = () => {
        this.element.classList.remove('is-viewport-entered', 'subscribe-on-viewport-entrance');
        this.element.removeAttribute('data-dc-enter-viewport');
    };
}
