import { DcBaseComponent } from '@deleteagency/dc';

export default class CustomActivityLoggerSearch extends DcBaseComponent {
    static getNamespace() {
        return 'custom-activity-logger-search';
    }

    onInit() {
        this.element.addEventListener('submit', () => {
            const searchId = Math.floor(Math.random() * Date.now()).toString(16);
            sessionStorage.setItem('searchId', searchId);
        });
    }
}
