import { DcBaseComponent } from '@deleteagency/dc';
import { eventBus } from 'general/js/events';

const CLASS_SUBNAV_STUCK_EVENT = 'subnavStuckEvent';
let executed = false;

export default class SubnavDropComponent extends DcBaseComponent {
    constructor(element) {
        super(element);

        this._isOpened = false;
    }

    static getRequiredRefs() {
        return ['trigger', 'tray'];
    }

    static getNamespace() {
        return 'subnav-drop';
    }

    onInit() {
        this.addListener(this.refs.trigger, 'click', this._onTriggerClick);
        this.addListener(this.refs.tray, 'click', this._onTrayClick);
        eventBus.addListener(CLASS_SUBNAV_STUCK_EVENT, this._triggerDisplayEffect);
    }

    _onTrayClick = () => {
        this.close();
    };

    _onTriggerClick = () => {
        if (this._isOpened) {
            this._close();
        } else {
            this._open();
        }
    };

    _triggerDisplayEffect = () => {
        if (executed) {
            return;
        }

        this._open();
        setTimeout(() => { this._close(); }, 1500);
        executed = true;
    }

    _open() {
        this.element.classList.add('is-opened');
        this._isOpened = true;
    }

    _close() {
        this.element.classList.remove('is-opened');
        this._isOpened = false;
    }

    close() {
        if (this._isOpened) {
            this._close();
        }
    }
}
