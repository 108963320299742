import { DcBaseComponent } from '@deleteagency/dc';
import userService, { EVENT_GOT_USER_INFO } from 'general/js/user/user-service';
import { eventBus } from 'general/js/events';

export default class PortalBtn extends DcBaseComponent {
    static getNamespace() {
        return 'portal-btn';
    }

    onInit() {
        this._isDisabled = true;
        if (userService.user !== null) this._onLogin(userService.user);
        eventBus.addListener(EVENT_GOT_USER_INFO, this._onLogin);
    }

    _onLogin = (user) => {
        if (this._isDisabled && user !== null && user.isVendor) {
            this._isDisabled = false;
            this.element.classList.remove('is-disabled');
        } else if (!this._isDisabled) {
            this._isDisabled = true;
            this.element.classList.add('is-disabled');
        }
    };
}
