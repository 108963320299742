import './scss/index.scss';

import { dcFactory } from '@deleteagency/dc';
import CookieLoaderComponent from './js/cookie-loader.component';
import CookieBannerComponent from './js/cookie-banner.component';
import CookieSettingsFormComponent from './js/cookie-settings-form.component';

dcFactory.register(CookieLoaderComponent);
dcFactory.register(CookieBannerComponent);
dcFactory.register(CookieSettingsFormComponent);
