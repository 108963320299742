import FormWizardStep from './form-wizard-step';

export default class FormWizard {
    constructor(element, form, options) {
        this.element = element;
        this.form = form;
        this._steps = [];
        this._currentStepIndex = null;
        this.options = { ...this._getDefaultOptions(), ...options };

        this._init();
    }

    _getDefaultOptions = () => ({
        stepsElements: [...this.element.querySelectorAll('[data-step]')],
        onShowingStep: null,
        onHidingStep: null,
        onActivateStep: null,
        onDisablingStep: null,
    });

    _getStepOptions = () => ({
        parsleyNamespace: this.form.getParsleyForm().options.namespace,
        parsleyInputs: this.form.getParsleyForm().options.inputs,
        ...this.options,
    });

    _buildSteps = () => {
        let prevStep = null;
        return this.options.stepsElements.map((stepElement, index) => {
            const step = new FormWizardStep(
                stepElement,
                { ...this._getStepOptions(), index },
                prevStep
            );
            step.setParsleyGroup();
            prevStep = step;
            Object.assign(stepElement, step);
            return step;
        });
    };

    _init = () => {
        this._attachListeners();
        this._steps = this._buildSteps();
        if (this._steps.length > 0) {
            this._initStartStep();
        }
    };

    _attachListeners = () => {
        // add listener to form first in order to be able to prevent event propagation in some cases
        // todo will be called after handler in Form class, should be custom and preventable event
        this.form.element.addEventListener('submit', this._onSubmit);
    };

    _initStartStep = () => {
        this._setStep(this._steps[0]);
    };

    _isCurrentStepValid = () =>
        this.form.getParsleyForm().whenValidate({
            group: this._currentStep.getGroup(),
        });

    continue = (isValid = false) =>
        new Promise(async (resolve, reject) => {
            try {
                if (!isValid) await this._isCurrentStepValid();
                const next = this._getNextActiveStep(this._currentStep, true);
                if (next !== null) {
                    this._setStep(next, true);
                    resolve(next);
                } else {
                    reject();
                }
            } catch (error) {
                console.log(error);
            }
        });

    back = () =>
        new Promise((resolve, reject) => {
            const next = this._getNextActiveStep(this._currentStep, false);
            if (next !== null) {
                this._setStep(next, false);
                resolve(next);
            } else {
                reject();
            }
        });

    _getNextActiveStep = (step, forward) => {
        const nextStep = this._getNextStep(step, forward);
        if (nextStep === null) return null;
        if (nextStep.isDisabled) return this._getNextActiveStep(nextStep, forward);
        return nextStep;
    };

    _getNextStep = (step, forward) => step[forward ? 'nextStep' : 'prevStep'];

    hideLast = () => {
        this._setStep(null);
    };

    forceGoTo = (index, forward) => {
        const step = this._steps[index];
        step.setActive();
        return this._setStep(step, forward);
    };

    _onSubmit = (e) => {
        // submit is allowed only from the last step
        // in other cases, e.g user clicks on Enter must not trigger submit
        if (this._currentStep !== null && this._currentStep.nextStep !== null) {
            e.preventDefault();
            e.stopImmediatePropagation();
            this.continue();
        }
    };

    _setStep = (newStep, forward) =>
        new Promise((resolve) => {
            if (this._currentStep !== newStep) {
                if (this._currentStep) {
                    this._currentStep.hide();
                    if (forward) {
                        this._currentStep.element.classList.add('is-done');
                    } else if (typeof forward === 'undefined') {
                        this._currentStep.element.classList.add('is-done');
                    }
                }
                if (newStep) {
                    this._currentStep = newStep;
                    if (!forward) {
                        this._currentStep.element.classList.remove('is-done');
                    }
                    const doneSteps = [...this.element.querySelectorAll('.is-done')];
                    if (doneSteps.length > 1 && forward) {
                        doneSteps[0].classList.add('is-out');
                    } else {
                        doneSteps[0]?.classList?.remove('is-out');
                    }
                    newStep.show().then(() => {
                        resolve();
                    });
                } else {
                    this._currentStep = null;
                }
            } else resolve();
        });

    getCurrentStepIndex = () => this._currentStep.getIndex();

    getSteps = () => this._steps;

    destroy = () => {
        this.form.element.removeEventListener('submit', this._onSubmit);
        this._steps.forEach((step) => {
            step.destroy();
        });
    };

    destroyStep = (step, index) => {
        const _index = index || step.getIndex();
        const promises = [Promise.resolve()];
        if (this._currentStepIndex === _index) {
            promises.push(this._setStep(_index + 1));
        }
        Promise.all(promises).then(() => {
            this._steps[_index].destroy();
            step.parentNode.removeChild(step);
            this._steps.splice(_index);
        });
    };
}
