import { DcBaseComponent } from '@deleteagency/dc';
import { render } from 'preact';
import React from 'preact/compat';
import SavedProperties from './saved-properties';

export default class SavedPropertiesComponent extends DcBaseComponent {
    static getNamespace() {
        return 'saved-properties';
    }

    onInit() {
        render(<SavedProperties placeholder={this.options.placeholder} />, this.element);
    }
}
