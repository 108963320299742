import constants from './constants';
import YoutubeVideo from './youtube-video';
import VimeoVideo from './vimeo-video';
import Html5Video from './html5-video';

export default class VideoCreator {
    /**
     * @param type
     * @param options
     * @returns AbstractVideo
     */
    static createFromOptions({ type, ...options }) {
        switch (type) {
            case constants.VIDEO_TYPE_YOUTUBE:
                return new YoutubeVideo(options);
            case constants.VIDEO_TYPE_VIMEO:
                return new VimeoVideo(options);
            case constants.VIDEO_TYPE_HTML5:
                return new Html5Video(options);
            default:
                throw new Error('Unknown video type');
        }
    }
}
