import { DcBaseComponent } from '@deleteagency/dc';
import GtmHelper from './gtm-helper';

export default class GtmTriggerGroup extends DcBaseComponent {
    static getNamespace() {
        return 'gtm-group';
    }

    onInit() {
        const activeElements = [...this.element.querySelectorAll('a, button')];
        if (activeElements.length > 0) {
            activeElements.forEach((element) => {
                if (!element.hasAttribute('data-dc-gtm')) {
                    this.addListener(element, 'click', e => GtmHelper.send(e, this.options));
                }
            });
        }
    }
}
