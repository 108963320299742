import React from 'preact/compat';
import { render } from 'preact';
import { DcBaseComponent } from '@deleteagency/dc';
import SvgIcon from 'general/js/svg-icon';
import { TransformWrapper, TransformComponent } from './react-zoom-pan-pinch';

export default class ImageZoom extends DcBaseComponent {
    static getNamespace() {
        return 'image-zoom';
    }

    onInit() {
        const { imageSrc, imageTitle } = this.options;
        const imageWrapper = (
            <TransformWrapper>
                {({ zoomIn, zoomOut, resetTransform, setTransform, ...rest }) => (
                    <React.Fragment>
                        <div className='tools'>
                            <button
                                className='floorplan-image-zoom-buttons'
                                id='zoomInButton'
                                onClick={zoomIn}>
                                <SvgIcon size={['25']} name='zoom-in' />
                            </button>
                            <button
                                className='floorplan-image-zoom-buttons'
                                id='zoomOutButton'
                                onClick={zoomOut}>
                                <SvgIcon size={['25']} name='zoom-out' />
                            </button>
                            <button
                                className='floorplan-image-zoom-buttons'
                                id='resetTransformButton'
                                data-floorplan-reset-transform
                                onClick={resetTransform}>
                                <SvgIcon
                                    size={['30']}
                                    name='reset'
                                    additionalClass='reset-svg'
                                />
                            </button>
                            <button
                                data-floorplan-reset-transform-2
                                onClick={() => setTransform(0, 0)}
                                style="display: none"
                            />
                        </div>
                        <TransformComponent>
                            <img
                                className='floorplan-image'
                                data-size='auto'
                                srcset={imageSrc}
                                data-srcset={imageSrc}
                                alt={imageTitle}
                            />
                        </TransformComponent>
                    </React.Fragment>
                )}
            </TransformWrapper>
        );
        render(imageWrapper, this.element);
        setTimeout(() => {
            // reset all instances in case of carousel
            [...document.querySelectorAll('[data-floorplan-reset-transform]')].forEach(button => button.click());
            [...document.querySelectorAll('[data-floorplan-reset-transform-2]')].forEach(button => button.click());
        }, 100);
    }
}
