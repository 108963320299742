import { DcBaseComponent } from '@deleteagency/dc';
import Flickity from 'flickity';
import { deviceObserver } from '@deleteagency/device-observer';

export default class OfficeReviewsCarouselComponent extends DcBaseComponent {
    _carousel = null;

    static getNamespace() {
        return 'office-reviews-car';
    }

    onInit() {

        const childrenCount = this.element.children.length;

        const options = {
            wrapAround: true,
            prevNextButtons: false,
            watchCSS: true,
            adaptiveHeight: false,
            groupCells: false,
            cellAlign: 'left',
            autoPlay: this.options.autoPlay*1000,
            pageDots: true,
        };

        if (childrenCount >= 2) {
            this.element.classList.add('flick-car--mobile-only');
        }

        if (childrenCount >= 3) {
            this.element.classList.add('flick-car--tablet-only', 'grid-reviews__grid--carousel-tablet');
        }

        if (childrenCount > 3) {
            this.element.classList.add('flick-car--desktop-only', 'flick-car--desktop-show-dots', 'grid-reviews__grid--carousel-desktop');
        }

        this._carousel = new Flickity(this.element, options);
    }


    onDestroy() {
        this._carousel.destroy();
        this._carousel = null;
    }
}
