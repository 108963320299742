import { DcBaseComponent } from '@deleteagency/dc';
import { eventBus } from 'general/js/events';
import { menuService } from 'general/js/menu';

export const EVENT_VIDEO_VAL_SHOULD_OPEN = 'videoValModalShouldOpen';

export default class ValVideoTriggerComponent extends DcBaseComponent {
    static getNamespace() {
        return 'video-val-modal-trigger';
    }

    onInit() {
        this.addListener(this.element, 'click', this._open);
    }

    _open = () => {
        menuService.closeActive();
        this._isOpened = true;
        eventBus.emit(EVENT_VIDEO_VAL_SHOULD_OPEN, this.options.target);
    };
}
