import keyBy from 'lodash/keyBy';
import { DcBaseComponent } from '@deleteagency/dc';
import userService, { EVENT_GOT_USER_INFO } from 'general/js/user/user-service';
import { EVENT_FORM_SUBMIT } from 'components/form/js/form.component';
import { eventBus } from 'general/js/events';
import { EVENT_SIDEBAR_MENU_CLOSED } from 'components/site-sidebar/js/sidebar-menu.component';
import { EVENT_LOGIN } from 'components/form/js/login-form.component';

const CLASS_HIDDEN = 'is-hidden';
const TAB_ID_MAIN = 'main';
const TAB_ID_REGISTER = 'register';
const TAB_ID_USERDETAILS = 'user-details';
const CLASS_SIGNUP_SIDEBAR = '.myww-signup-sidebar';

export const EVENT_OPEN_REGISTER = 'eventOpenRegister';

export default class SidebarUserTabsComponent extends DcBaseComponent {
    static getNamespace() {
        return 'sidebar-user-tabs';
    }

    static getRequiredRefs() {
        return ['triggers', 'items'];
    }

    onInit() {
        this.shouldOpenRouteFirst = false;
        this.triggers = this.options.group ?
            this.refs.triggers :
            this.refs.triggers.filter((item) => !item.hasAttribute('data-details'));
        this.items = this.options.group ?
            this.refs.items :
            this.refs.items.filter((item) => !item.hasAttribute('data-details'));

        this.activeItem = this.items.find((item) => !item.classList.contains(CLASS_HIDDEN));
        this.itemsById = keyBy(this.items, (el) => el.getAttribute('data-id'));

        this.triggers.forEach((el) => {
            const id = el.getAttribute('data-target');
            this.addListener(el, 'click', this._onTriggerClick.bind(this, id));
        });

        if (userService.user !== null) {
            this._showItem(TAB_ID_MAIN);
        }
        eventBus.addListener(EVENT_GOT_USER_INFO, this._onGotUser);
        eventBus.addListener(EVENT_LOGIN, this._onLogin);
        eventBus.addListener(EVENT_OPEN_REGISTER, () => this.open([TAB_ID_REGISTER]));
        eventBus.addListener(EVENT_SIDEBAR_MENU_CLOSED, this._onSidebarClosed);
        eventBus.addListener(EVENT_FORM_SUBMIT, this._onFormSubmit);
    }

    _onFormSubmit = (name) => {
        if (this.activeItem.getAttribute('data-source-form') === name && name === 'updateDetails') {
            userService.refetchUser();
            this._showItem(TAB_ID_MAIN);
            this._onTriggerClick(TAB_ID_MAIN);
        }
    };

    _onTriggerClick(id) {
        this._showItem(id);
        console.log('current id: ', id);
        if(id === TAB_ID_USERDETAILS ) {
            this._triggerParentClass('add', TAB_ID_USERDETAILS);
        } else {
            this._triggerParentClass('remove', TAB_ID_USERDETAILS);
        }
    }

    _triggerParentClass(param, TAB_ID) {
        const parentEl = this.element.closest(CLASS_SIGNUP_SIDEBAR);
        switch (param) {
            case 'add':
                parentEl.classList.add('is-' + TAB_ID);
                break;
            case 'remove':
                parentEl.classList.remove('is-' + TAB_ID);
                break;
        }
    }

    _hasItem(id) {
        // eslint-disable-next-line no-prototype-builtins
        return this.itemsById.hasOwnProperty(id);
    }

    _onSidebarClosed = () => {
        if (this._isActive(TAB_ID_REGISTER)) {
            this._showItem(TAB_ID_MAIN);
        }
    };

    _onGotUser = () => {
        if (this.shouldOpenRouteFirst) {
            this.shouldOpenRouteFirst = false;
            return;
        }

        this._showItem(TAB_ID_MAIN);
    };

    _onLogin = () => {
        this._showItem(TAB_ID_MAIN);
    };

    _isActive = (id) => this._hasItem(id) && this.activeItem.dataset.id === id;

    _showItem(id) {
        const itemToShow = this.itemsById[id];

        if (!itemToShow) {
            throw new Error(`No items for id ${id}`);
        }

        if (this.activeItem) {
            this.activeItem.classList.add('is-hidden');
            this.activeItem.setAttribute('aria-hidden', 'true');
        }

        itemToShow.classList.remove('is-hidden');
        itemToShow.removeAttribute('aria-hidden');
        this.activeItem = itemToShow;
    }

    open(route) {
        const id = route[0];
        this.shouldOpenRouteFirst = true;
        if (this._hasItem(id)) {
            this._showItem(id);
        }
    }
}
