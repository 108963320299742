// @ts-nocheck

import { DcBaseComponent } from '@deleteagency/dc';

const IS_VALID_CLASS = 'is-valid';

export default class PasswordStrengthComponent extends DcBaseComponent {
    static getNamespace() {
        return 'password-strength';
    }

    async onInit() {
        this._addValidators();
        this._addListeners();
    }

    _addValidators() {
        const passwordRules = [
            {
                id: 'oneNumericSymbol',
                regexp: /(?=.*\d)/,
            },
            {
                id: 'oneLowerCaseSymbol',
                regexp: /(?=.*[a-z])/,
            },
            {
                id: 'oneUpperCaseSymbol',
                regexp: /(?=.*[A-Z])/,
            },
        ];

        passwordRules.forEach((rule) => {
            const { id, regexp } = rule;
            if (!window.Parsley.hasValidator(id)) {
                window.Parsley.addValidator(id, {
                    requirementType: 'regexp',
                    validateString: function(value) {
                        var regExp = regexp;
                        return '' !== value ? regExp.test(value) : false;
                    },
                });
            }
        });
    }

    _addListeners() {
        $(this.refs.password)
            .parsley()
            .on('field:validated', this._onPasswordValidate);
    }

    _toggleClass(toggle) {
        this.refs.rules.forEach((el) => {
            el.classList[toggle](IS_VALID_CLASS);
        });
    }

    _onPasswordValidate = (event) => {
        const { validationResult } = event;
        if (validationResult === true) {
            this._toggleClass('add');
            return;
        }
        if (this.refs.password.value === '') {
            this._toggleClass('remove');
            return;
        }
        this._toggleClass('add');
        validationResult.map((error) => {
            const { assert } = error;
            const { name } = assert;
            this.refs.rules.forEach((el) => {
                if (el.getAttribute('data-rule') === name) {
                    el.classList.remove(IS_VALID_CLASS);
                }
            });
        });
    };
}
