import { DcBaseComponent } from '@deleteagency/dc';
import GtmHelper from 'components/gtm-trigger/js/gtm-helper';
import { eventBus } from 'general/js/events';
import { menuService } from "general/js/menu";
import { propertyStorageService } from 'general/js/property-storage-service';

export const EVENT_RV_SHOULD_OPEN = 'rvWizardShouldOpen';

export default class RvWizardTriggerComponent extends DcBaseComponent {
    static getNamespace() {
        return 'rv-wizard-trigger';
    }

    onInit() {
        this.addListener(this.element, 'click', this._open);
        if (this.options.checkAndAutoOpen && !propertyStorageService.isKeyExists()) {
/*            setTimeout(() => {
                if (!this._isOpened) this._open();
            }, +this.options.checkAndAutoOpen);*/
        }
    }

    _open = () => {
        menuService.closeActive();
        this._isOpened = true;
        const { category = 'page', action = 'Book valuation', label = this.element.textContent.trim() } = this.options;
        GtmHelper.send(null, { category, action, label });
        eventBus.emit(EVENT_RV_SHOULD_OPEN, this.options.target);
    };
}
