import { DcBaseComponent } from '@deleteagency/dc';
import { render } from 'preact';
import React from 'preact/compat';
import SavedSearches from './saved-searches';

export default class SavedSearchesComponent extends DcBaseComponent {
    static getNamespace() {
        return 'saved-searches';
    }

    onInit() {
        render(
            <SavedSearches
                placeholder={this.options.placeholder}
                updateCaption={this.options.updateCaption}
                updateOptions={this.options.updateOptions}
            />,
            this.element
        );
    }
}
