const PARSLEY_GROUP_ATTRIBUTE = 'group';

export default class FormWizardStep {
    constructor(element, options = {}, prevStep = null) {
        this.element = element;
        if (element) {
            Object.assign(element, this);
        }
        const defaultOptions = {
            parsleyInputs: null,
            parsleyNamespace: null,
            activeClass: 'is-active',
            showingClass: 'go-show',
            hidingClass: 'go-hide',
            validClass: 'is-valid',
            outClass: 'is-out',
        };
        this.options = { ...defaultOptions, ...options };
        const { index = null } = options;
        if (index !== null) this._setIndex(index);
        this._showResolve = null;
        this._hideResolve = null;
        this.nextStep = null;
        this.prevStep = prevStep;
        if (prevStep !== null) prevStep._setNextStep(this);
    }

    _setNextStep = (step) => {
        this.nextStep = step;
    };

    getElement = () => this.element;

    _setIndex = (index) => {
        this._index = index;
        this._group = `group-${index}`;
    };

    setParsleyGroup = () => {
        const inputs = [...this.element.querySelectorAll(this.options.parsleyInputs)];
        inputs.forEach((inputNode) => {
            inputNode.setAttribute(
                `${this.options.parsleyNamespace}${PARSLEY_GROUP_ATTRIBUTE}`,
                this.getGroup()
            );
        });
    };

    getGroup = () => this._group;

    getIndex = () => this._index;

    setDisable = () => {
        if (!this.isDisabled) {
            this.isDisabled = true;
            if (this._index !== null && this.options.onDisablingStep) {
                this.options.onDisablingStep(this._index);
            }
        }
    };

    setActive = () => {
        if (this.isDisabled) {
            this.isDisabled = false;
            if (this._index !== null && this.options.onActivateStep) {
                this.options.onActivateStep(this._index);
            }
        }
    };

    show = () =>
        new Promise((resolve) => {
            this.element.addEventListener('animationend', this._finishShow);
            this.hasShowListener = true;
            this._showResolve = resolve;
            this.element.classList.add(this.options.showingClass);
            this.element.classList.add(this.options.activeClass);
            if (this._index !== null && this.options.onShowingStep) {
                this.options.onShowingStep(this._index);
            }
            this._finishShow();
        });

    _finishShow = () => {
        if (this.hasShowListener) {
            this.element.removeEventListener('animationend', this._finishShow);
            this.hasShowListener = false;
            this.element.classList.remove(this.options.showingClass);
            if (this._showResolve !== null) {
                this._showResolve();
                this._showResolve = null;
            }
        }
    };

    hide = () =>
        new Promise((resolve) => {
            this.element.addEventListener('animationend', this._finishHide);
            this.hasHideListener = true;
            this.element.classList.add(this.options.hidingClass);

            this._hideResolve = resolve;
            if (this._index !== null && this.options.onHidingStep) {
                this.options.onHidingStep(this._index);
            }
            this._finishHide();
        });

    _finishHide = () => {
        if (this.hasHideListener) {
            this.element.removeEventListener('animationend', this._finishHide);
            this.hasHideListener = false;
            this.element.classList.remove(this.options.hidingClass);
            this.element.classList.remove(this.options.activeClass);
            if (this._hideResolve !== null) {
                this._hideResolve();
                this._hideResolve = null;
            }
        }
    };

    destroy = () => {
        if (this.hasHideListener) {
            this._finishHide();
        }

        if (this.hasShowListener) {
            this._finishShow();
        }
    };
}
