import { DcBaseComponent } from '@deleteagency/dc';
import {
    EVENT_RV_ADDRESS_BLURED,
    EVENT_RV_ADDRESS_EDIT_CLICKED,
    EVENT_RV_ADRESS_CHOOSED,
} from 'components/address-predictive-field/js/address-predictive-field.jsx';
import GtmHelper from 'components/gtm-trigger/js/gtm-helper';
import { eventBus } from 'general/js/events';
import FormWizard from 'general/js/form-wizard/form-wizard';
import FormWizardStep from 'general/js/form-wizard/form-wizard-step';
import BaseForm from 'general/js/forms/base-form';
import { modalService } from 'general/js/modal';
import ElementSpinner from 'general/js/spinner/element-spinner';
import { EVENT_VIDEO_VAL_SHOULD_OPEN } from './val-video-trigger.component';

const GTM_ACTION = {
    sales: 'Sell Property',
    lettings: 'Let Property',
    both: 'Both',
};

export default class ValVideoComponent extends DcBaseComponent {
    static getNamespace() {
        return 'val-video';
    }

    static getRequiredRefs() {
        return ['submit', 'form', 'spinner', 'channel'];
    }

    onInit() {
        this._steps = this.refs.steps;
        this.form = new BaseForm(
            this.refs.form,
            {
                submitElement: this.refs.submit,
                onFailedSubmit: this._onFailedSubmit,
                onSuccessfulSubmit: this._onSuccessfulSubmit,
                onError: this._onError,
                validateVisibleOnly: false,
            },
            this
        );
        this.parsleyForm = this.form.getParsleyForm();
        this.wizard = new FormWizard(this.element, this.form, {
            stepsElements: this._steps,
            startIndex: 0,
            onShowingStep: (index) => {},
            onHidingStep: (index) => {},
            onActivateStep: (index) => {},
            onDisablingStep: (index) => {},
        });
        this.finalStep = new FormWizardStep(this.refs.finalStep);
        this.spinner = new ElementSpinner(this.refs.spinner, {
            modifiers: ['with-overlay'],
        });
        this.modal = modalService.create(this.element, {
            cssModifier: 'blue',
            cross: false,
        });
        if (!window.Parsley.hasValidator('rvAddressChoosed')) {
            window.Parsley.addValidator('rvAddressChoosed', {
                requirementType: 'string',
                validateString: () => !!this.addressIsChoosed,
            });
        }
        this._addListeners();
    }

    _addListeners = () => {
        this.refs.nextBtns.forEach((btn) => {
            this.addListener(btn, 'click', this._onContinue);
        });
        this.addListener(this.refs.form, 'input', this._onChange);
        this.addListener(this.refs.submit, 'submit', this._onBeforeSubmit);
        eventBus.addListener(EVENT_RV_ADRESS_CHOOSED, this._onChangeAddress);
        eventBus.addListener(EVENT_RV_ADDRESS_EDIT_CLICKED, this._onAddressEdit);
        eventBus.addListener(EVENT_RV_ADDRESS_BLURED, this._onAddressBlur);
        eventBus.addListener(EVENT_VIDEO_VAL_SHOULD_OPEN, this._openModal);
    };

    _openModal = (target) => {
        if (target === this.element.id) {
            this.modal.open();
            this._checkStepValidAndToggle(this._steps[0]);
        }
    };

    _onContinue = () => {
        this.wizard.continue().then(this._onNextStep);
    };

    _onNextStep = () => {
        const current = this.wizard.getCurrentStepIndex();
        this._checkStepValidAndToggle(this._steps[current]);
    };

    _onChange = (e) => {
        const target = e.target;
        this._steps.forEach((step) => {
            if (step.contains(target)) {
                this._checkStepValidAndToggle(step);
            }
        });
    };

    _checkStepValidAndToggle = (step) => {
        const isValid = this.parsleyForm.isValid({ group: step.getGroup() });
        this._checkAndToggleBtn(step, isValid);
    };

    _checkAndToggleBtn = (step, remove) => {
        this.refs.nextBtns.forEach((btn) => {
            if (step.contains(btn)) this._toggleDisable(btn, remove);
        });
        if (step.contains(this.refs.submit)) {
            this._toggleDisable(this.refs.submit, remove);
        }
    };

    _toggleDisable = (btn, remove) => {
        btn[remove ? 'removeAttribute' : 'setAttribute']('disabled', 'true');
    };

    _showLastStep = (message) => {
        this.refs.finalStepContent.innerHTML = message;
        this.element.classList.add('is-finished');
        this.wizard.hideLast();
        this.finalStep.show();
    };

    _dropState = () => {
        this.modal.options.onClose = null;
        setTimeout(() => {
            this.finalStep.hide();
            this.element.classList.remove('is-finished');
            this._steps.forEach((step) => {
                step.classList.remove('is-done');
            });
            this.refs.finalStep.classList.remove('is-done');
            this.wizard.forceGoTo(0);
        }, 300);
    };

    _onAddressEdit = () => {
        this.addressIsChoosed = false;
        this.refs.addressHiddens.forEach((input) => {
            input.value = '';
        });
        this._checkStepValidAndToggle(this._steps[0]);
    };

    _onAddressBlur = () => {
        this.addressIsChoosed = false;
        this._checkStepValidAndToggle(this._steps[0]);
    };

    _onChangeAddress = (props) => {
        this.addressIsChoosed = true;
        this._updateOptions(props);
        this._checkStepValidAndToggle(this._steps[0]);
    };

    _updateOptions = (props) => {
        const { content = {} } = props;
        this.refs.addressHiddens.forEach((input) => {
            const name = input.getAttribute('name');
            if (content[name]) {
                input.value = content[name];
            }
        });
    };

    _onError = (error) => {
        this.spinner.hide();
        const message = error || '<p>There was a problem with network. Please try again later.</p>';
        this._showLastStep(message);
    };

    _onSuccessfulSubmit = (result) => {
        this.spinner.hide();
        const type = result.data.type;
        if (type === 'success') {
            GtmHelper.send(null, {
                category: this.options.category || 'page',
                action: this._getCurrentChannel() || this.options.action,
                label: this.options.label,
            });
        }
        if (type === 'success' || type === 'serverError') {
            this._showLastStep(result.data.message);
            this.modal.options.onClose = this._dropState;
        }
        this.refs.form.reset();
        this.parsleyForm.reset();
        this.parsleyForm.refresh();
        eventBus.emit(EVENT_RV_ADDRESS_EDIT_CLICKED); // reset address field
    };

    _onFailedSubmit = () => {
        this.spinner.hide();
    };

    _onBeforeSubmit = () => {
        this.spinner.show();
    };

    _getCurrentChannel = () => {
        const currentInput = [
            ...this.refs.channel.querySelectorAll('input[type="radio"][name="channel"]'),
        ].find((input) => input.checked);
        return GTM_ACTION[currentInput.value];
    };
}
