import { DcBaseComponent } from '@deleteagency/dc';

export default class AdfenixTriggerComponent extends DcBaseComponent {
    static getNamespace() {
        return 'adfenix';
    }

    handleAdfenixTrack = () => {
        if (!window.adfenix || !this.options.event) return;

        const trackEvent = this.element.getAttribute('data-adfenix-updated') || this.options.event;

        adfenix.track(trackEvent);
    }

    onInit() {
        this.addListener(this.element, 'click', this.handleAdfenixTrack);
    }
}
