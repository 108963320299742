import { DcBaseComponent } from '@deleteagency/dc';
import userService, { EVENT_GOT_USER_INFO } from 'general/js/user/user-service';
import { eventBus } from 'general/js/events';
import camelCase from 'lodash/camelCase';

export const EVENT_PREPOPULATED_FORM_FILLED = 'preFormFilled';

export default class GalleryComponent extends DcBaseComponent {
    static getNamespace() {
        return 'prepopulated-form';
    }

    onInit() {
        if (userService.user !== null) this._gotUser(userService.user);
        eventBus.addListener(EVENT_GOT_USER_INFO, this._gotUser);
    }

    _gotUser = (user) => {
        const elements = this.element.elements || this.refs.elements;

        if (!user) return;
        [...elements].forEach((element) => {
            switch (element.type) {
                case 'radio':
                case 'checkbox':
                    element.checked = !!user[camelCase(element.name)];
                    break;
                case undefined:
                    this.processNotInput(element, user);
                    break;
                default:
                    if (element.hasAttribute('data-ignore-prepopulate')) {
                        break;
                    }
                    element.value = user[camelCase(element.name)] || '';
                    break;
            }
        });
        eventBus.emit(EVENT_PREPOPULATED_FORM_FILLED, this.element);
    };

    processNotInput(element, user) {
        element.textContent = user[camelCase(element.dataset.name)];
        const conditionalWrapper = element.parentNode;
        if (conditionalWrapper && conditionalWrapper.matches('[data-if]')) {
            conditionalWrapper.hidden = false;
        }

        if (
            !user[camelCase(element.dataset.name)] &&
            conditionalWrapper &&
            conditionalWrapper.matches('[data-if]')
        ) {
            conditionalWrapper.hidden = true;
        }
    }
}
