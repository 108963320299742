import { DcBaseComponent } from '@deleteagency/dc';
import BaseForm from 'general/js/forms/base-form';
import { eventBus } from 'general/js/events';
import { modalService } from 'general/js/modal';
import ElementSpinner from 'general/js/spinner/element-spinner';

export const EVENT_BEFORE_SUBMIT = 'cookie-settings-form:before-submit';

export default class CookieSettingsFormComponent extends DcBaseComponent {
    static getNamespace() {
        return 'cookie-settings-form';
    }

    static getRequiredRefs() {
        return ['level2', 'level3'];
    }

    async onInit() {
        this.spinner = new ElementSpinner(this.element, { modifiers: ['with-overlay'] });
        this.form = new BaseForm(this.element, {
            submitElement: this.refs.submit,
            onBeforeSubmit: this._onBeforeSubmit,
            onSuccessfulSubmit: this._onSuccessfulSubmit,
            onFailedSubmit: this._onFailedSubmit,
        }, this);
        this.addListener(this.refs.level2, 'change', this._onCheckLevel2);
        this.addListener(this.refs.level3, 'change', this._onCheckLevel3);
    }

    _onCheckLevel2 = () => {
        if (!this.refs.level2.checked) {
            this.refs.level3.checked = false;
        }
    };

    _onCheckLevel3 = () => {
        if (this.refs.level3.checked) {
            this.refs.level2.checked = true;
        }
    };

    _onBeforeSubmit = () => {
        this.spinner.show();
        eventBus.emit(EVENT_BEFORE_SUBMIT);
    };

    _onSuccessfulSubmit = () => {
        this.spinner.hide();
        modalService.closeOpened();
    };

    _onFailedSubmit = (error) => {
        this.spinner.hide();
        console.error(error);
    };
}
