import { DcBaseComponent } from '@deleteagency/dc';
import GtmHelper from 'components/gtm-trigger/js/gtm-helper';
import { eventBus } from 'general/js/events';
import BaseForm from 'general/js/forms/base-form';
import { MODAL_WINDOW_CLOSE } from 'general/js/modal';
import ElementSpinner from 'general/js/spinner/element-spinner';

export default class ContactFormComponent extends DcBaseComponent {
    static getNamespace() {
        return 'contact-form';
    }

    async onInit() {
        this.spinner = new ElementSpinner(this.element, {
            modifiers: ['with-overlay'],
        });
        this.form = new BaseForm(
            this.element,
            {
                submitElement: this.refs.submit,
                onBeforeSubmit: this._onBeforeSubmit,
                errorsSummaryElement: this.refs.errorsSummary,
                onSuccessfulSubmit: this._onSuccessfulSubmit,
                onFailedSubmit: this._onFailedSubmit,
            },
            this
        );
        eventBus.addListener(MODAL_WINDOW_CLOSE, this._onModalClose);
    }

    _onSuccessfulSubmit = ({ data }) => {
        this.spinner.hide();
        GtmHelper.send(this.element, {
            category: 'page',
            action: this.options.action || 'Contact Form Submission',
            label: this.options.label,
        });

        this.element.classList.add('is-submitted');

        if (!this.options?.customNotification) {
            this.refs.successMessage.innerHTML = `<h2>${data.message}</h2>`;
        }
    };

    _onModalClose = () => {
        this.element.classList.remove('is-submitted');
    };

    _onBeforeSubmit = () => {
        this.spinner.show();
    };

    _onFailedSubmit = (error) => {
        this.spinner.hide();
        console.error(error);
    };
}
