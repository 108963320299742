const DEFAULT_OPTIONS = {
    threshold: [0, 0.25, 0.5, 0.75, 1]
};

class ScrollObserver {
    constructor(options) {
        this.subscribers = [];
        this.intersectionObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio <= 0) return;
                    const foundSubscriber = this.subscribers.find((subscriber) =>
                        subscriber.node === entry.target);

                    if (!foundSubscriber) return;

                    foundSubscriber.cb();
                });
            },
            {
                ...DEFAULT_OPTIONS,
                ...options
            }
        );
    }

    subscribe = (node, cb) => {
        if (!node || typeof cb !== 'function') {
            throw new Error('You must provide valid input props');
        }

        this.intersectionObserver.observe(node);
        this.subscribers.push({
            node,
            cb
        });

        return () => {
            this.subscribers = this.subscribers.filter((subscriber) =>
                subscriber.node !== node);
            this.intersectionObserver.unobserve(node);
        };
    };
}

export { ScrollObserver };

const instance = new ScrollObserver();
export default instance;
