import { DcBaseComponent } from '@deleteagency/dc';
import { eventBus } from 'general/js/events';

export const EVENT_PREINPUTED_SUBMITED = 'preInputedSubmited';

export default class RvPreinputedComponent extends DcBaseComponent {
    static getNamespace() {
        return 'rv-preinputed';
    }

    static getRequiredRefs() {
        return ['inputs'];
    }

    onInit() {
        this.addListener(this.element, 'submit', this._onSubmit);
    }

    _onSubmit = (e) => {
        e.preventDefault();
        const result = {};
        this.refs.inputs.forEach((input) => {
            if (input.value && input.value.length !== 0) {
                result[input.getAttribute('name')] = input.value;
            }
        });
        if (this.options.target) {
            eventBus.emit(EVENT_PREINPUTED_SUBMITED, { result, target: this.options.target });
        }
    };
}
