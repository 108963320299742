import { DcBaseComponent, dcFactory } from '@deleteagency/dc';
import {
    EVENT_RV_ADDRESS_BLURED,
    EVENT_RV_ADDRESS_EDIT_CLICKED,
    EVENT_RV_ADRESS_CHOOSED,
} from 'components/address-predictive-field/js/address-predictive-field.jsx';
import analyticsService from 'general/js/analytics-service';
import { eventBus } from 'general/js/events';
import FormWizard from 'general/js/form-wizard/form-wizard';
import FormWizardStep from 'general/js/form-wizard/form-wizard-step';
import BaseForm from 'general/js/forms/base-form';
import { modalService } from 'general/js/modal';
import ElementSpinner from 'general/js/spinner/element-spinner';
import userService, { EVENT_GOT_USER_INFO } from 'general/js/user/user-service';
import { render } from 'preact';
import React from 'preact/compat';
import Calendar from './calendar';
import Hiddens from './hiddens';
import { EVENT_PREINPUTED_SUBMITED } from './rv-preinputed.component';
import { EVENT_RV_RADIO_CHANGE } from './rv-radio.component';
import { EVENT_RV_SHOULD_OPEN } from './rv-wizard-trigger.component';

export const SELECTED_TIME_NAME = 'selectedTime';

export default class RvWizardComponent extends DcBaseComponent {
    static getNamespace() {
        return 'rv-wizard';
    }

    static getRequiredRefs() {
        return [
            'steps',
            'nextBtns',
            'backBtns',
            'finalStep',
            'finalStepContent',
            'submit',
            'calendar',
            'spinner',
            'passCalendar',
            'hiddens',
        ];
    }

    onInit() {
        this._steps = this.refs.steps;
        this.form = new BaseForm(
            this.refs.form,
            {
                submitElement: this.refs.submit,
                onFailedSubmit: this._onFailedSubmit,
                onSuccessfulSubmit: this._onSuccessfulSubmit,
                onError: this._onError,
                validateVisibleOnly: false,
            },
            this
        );
        this.parsleyForm = this.form.getParsleyForm();
        this.wizard = new FormWizard(this.element, this.form, {
            stepsElements: this._steps,
            startIndex: 0,
            onShowingStep: (index) => this._onShowingHidingStep(index, true),
            onHidingStep: (index) => this._onShowingHidingStep(index, false),
        });
        this.finalStep = new FormWizardStep(this.refs.finalStep);
        this.spinner = new ElementSpinner(this.refs.spinner, { modifiers: ['with-overlay'] });
        this.modal = modalService.create(this.element, {
            cssModifier: 'blue',
            cross: false,
        });
        this.calendarInstance = null;
        this._renderHiddens();
        this._makeCalendar();

        if (this.options.skipCalendar && this.options.skipCalendar.toLowerCase() === 'true') {
            this.wizard.forceGoTo(this.calendarStep.getIndex() +
                1, !this.wizard.getCurrentStepIndex());
            this.wizard.destroyStep(this.calendarStep, this.calendarStep.getIndex());
        }


        this._addListeners();
        if (userService.user !== null) this._fillUserInfo(userService.user);
        if (this.options.id === 'request-valuation') {
            window.Parsley.addValidator('rvAddressChoosed', {
                requirementType: 'string',
                validateString: () => !!this.addressIsChoosed,
            });
        }
    }

    _renderHiddens = () => {
        if (window.location.search.length > 0) {
            render(<Hiddens />, this.refs.hiddens);
        }
    };

    _onShowingHidingStep = (index, isShowing) => {
        const email = this.refs.form.email;
        if (index === 0 && email.length > 0) {
            email[0][isShowing ? 'removeAttribute' : 'setAttribute']('disabled', 'true');
        }
    };

    _fillUserInfo = (user) => {
        this._findKeysInFormAndApply(user);
        this._checkStepValidAndToggle(this._steps[0]);
    };

    _openModal = (target) => {
        if (target === this.options.id) {
            this.modal.open();
            if (this._steps.findIndex((step) => step.contains(this.refs.calendar)) !== 0) {
                this._skipValidStep(this._steps[0]);
            }
        }
    };

    _addListeners = () => {
        this.refs.nextBtns.forEach((btn) => {
            this.addListener(btn, 'click', this._onContinue);
        });
        this.refs.backBtns.forEach((btn) => {
            this.addListener(btn, 'click', this._onBack);
        });
        this.addListener(this.refs.passCalendar, 'click', this._onPassCalendar);
        this.addListener(this.refs.submit, 'click', this._onBeforeSubmit);
        this.addListener(this.refs.form, 'input', this._onChange);
        eventBus.addListener(EVENT_PREINPUTED_SUBMITED, this._onPreinputedSubmited);
        eventBus.addListener(EVENT_GOT_USER_INFO, this._fillUserInfo);
        eventBus.addListener(EVENT_RV_SHOULD_OPEN, this._openModal);
        eventBus.addListener(EVENT_RV_RADIO_CHANGE, this._updateOptions);
        eventBus.addListener(EVENT_RV_ADRESS_CHOOSED, this._onChangeAddress);
        eventBus.addListener(EVENT_RV_ADDRESS_EDIT_CLICKED, this._onAddressEdit);
        eventBus.addListener(EVENT_RV_ADDRESS_BLURED, this._onAddressBlur);
        if (this.refs.emails && this.refs.emails.length > 1) {
            this.refs.emails.forEach((input) => {
                this.addListener(input, 'change', this._onEmailChange);
            });
        }
    };

    _onAddressEdit = () => {
        this.addressIsChoosed = false;
        if (this.refs.addressDuplicate) this.refs.addressDuplicate.value = '';
        this.refs.addressHiddens.forEach((input) => {
            input.value = '';
        });
        this.calendarStep.setActive();
        this._checkStepValidAndToggle(this._steps[0]);
    };

    _onAddressBlur = () => {
        this.addressIsChoosed = false;
        this._checkStepValidAndToggle(this._steps[0]);
    };

    _getActiveStepElement = () =>
        this._steps.filter((step) => step.classList.contains('is-active'));

    _onPassCalendar = () => {
        if (this.refs.form[SELECTED_TIME_NAME]) {
            if (this.refs.form[SELECTED_TIME_NAME].length > 0) {
                [...this.refs.form[SELECTED_TIME_NAME]].some((time) => {
                    if (time.checked) {
                        time.checked = false;
                        time.parentNode.classList.remove('is-active');
                        return true;
                    }

                    return false;
                });
            } else {
                if (this.refs.form[SELECTED_TIME_NAME].checked) {
                    this.refs.form[SELECTED_TIME_NAME].checked = false;
                    this.refs.form[SELECTED_TIME_NAME].parentNode.classList.remove('is-active');
                }
            }
        }

        const isRequestForm = this._isRequestForm();
        if (isRequestForm) {
            const gaLabel = this._getSelectedChannel();
            analyticsService.sendEvent('request-valuation', 'Just Contact Me Clicked', gaLabel);
        } else {
            analyticsService.sendEvent('request-viewing', 'Just Contact Me Clicked', '');

            this.refs.submit.setAttribute('data-adfenix-updated', 'contactrequestsubmit');
        }

        this._setTimes('nochoosed');
        this.wizard.forceGoTo(this.calendarStep.getIndex() + 1, !this.wizard.getCurrentStepIndex());
    };

    _onEmailChange = (e) => {
        this.refs.emails.forEach((input) => {
            if (e.target !== input) input.value = e.target.value;
        });
    };

    _onChangeAddress = (props) => {
        this.addressIsChoosed = true;
        this._updateOptions(props);
        this._checkStepValidAndToggle(this._steps[0]);
    };

    _updateOptions = (props) => {
        const { target, content = {}, value } = props;
        if (target === this.options.id) {
            this.calendarStep.setActive();
            this.refs.addressHiddens.forEach((input) => {
                const name = input.getAttribute('name');
                if (content[name]) {
                    input.value = content[name];
                }
            });
            if (this.refs.addressDuplicate && value) {
                this.refs.addressDuplicate.value = value;
            }
        }
    };

    _onPreinputedSubmited = (props) => {
        if (this.options.id === props.target) {
            analyticsService.sendEvent('request-viewing', 'Alt Viewing form Submitted', '');
            this._findKeysInFormAndApply(props.result);
            if (this.calendarInstance) {
                this.calendarInstance.sentRequest();
                if (!this.calendarStep.isDisabled) {
                    this.wizard.forceGoTo(this.calendarStep.getIndex());
                }
            }

            this._steps.forEach((step) => {
                this._checkStepValidAndToggle(step);
            });
            this._openModal(props.target);
        }
    };

    _findKeysInFormAndApply = (props) => {
        if (props === null) return;
        Object.keys(props).forEach((key) => {
            if (this.refs.form[key]) {
                if (this.refs.form[key].length) {
                    [...this.refs.form[key]].forEach((item) => {
                        item.value = props[key];
                    });
                } else {
                    this.refs.form[key].value = props[key];
                }
            }
        });
    };

    _onCalendarRef = (instance) => {
        this.calendarInstance = instance;
    };

    _makeCalendar = () => {
        const index = this._steps.findIndex((step) => step.contains(this.refs.calendar));
        const skipCalendarValue =
            this.options.skipCalendar && this.options.skipCalendar.toLowerCase() === 'true';
        this.calendarStep = this._steps[index];
        if (!skipCalendarValue && this.calendarStep) {
            render(
                <Calendar
                    ref={this._onCalendarRef}
                    endpoint={this.options.calendarEndpoint}
                    onFailRequest={this._onCalendarRequestFail}
                    onChoose={this._onChooseTime}
                    optionsForRequest={this.options.calendarOptions}
                    waitingOptions={this.options.waitingOptions}
                    group={this.calendarStep.getGroup()}
                    initLoading={index === 0}
                />,
                this.refs.calendar
            );
        } else {
            this.calendarStep.setDisable();
            this._setTimes('fail');
        }
    };

    _onChooseTime = (time) => {
        this._checkStepValidAndToggle(this.calendarStep);
        this._setTimes('choosed', () => {
            this.refs.times.choosed.querySelector('span').innerHTML = time;
        });
    };

    _onCalendarRequestFail = (error) => {
        if (error) console.log(error);
        this.calendarStep.setDisable();
        this._setTimes('fail');
        this.wizard.forceGoTo(this.calendarStep.getIndex() + 1, !this.wizard.getCurrentStepIndex());
    };

    _setTimes = (name, cb = null) => {
        if (this.refs.times) {
            if (name === 'nochoosed' && this.refs.form.selectedTime) {
                if (this.refs.form.selectedTime.length > 0) {
                    [...this.refs.form.selectedTime].forEach((radio) => {
                        radio.checked = false;
                    });
                } else {
                    this.refs.form.selectedTime.checked = false;
                }
            }

            Object.keys(this.refs.times).forEach((key) => {
                if (key === name) {
                    if (cb) cb();
                    this.refs.times[key].style.display = 'block';
                } else {
                    this.refs.times[key].style.display = 'none';
                }
            });
        }
    };

    _onChange = (e) => {
        const target = e.target;
        this._steps.forEach((step) => {
            if (step.contains(target)) {
                this._checkStepValidAndToggle(step);
            }
        });
    };

    _checkStepValidAndToggle = (step) => {
        const isValid = this.parsleyForm.isValid({ group: step.getGroup() });
        this._checkAndToggleBtn(step, isValid);
    };

    _skipValidStep = (step) => {
        const isValid = this.parsleyForm.isValid({ group: step.getGroup() });
        const current = this.wizard.getCurrentStepIndex();
        if (isValid && current === step.getIndex()) {
            this.wizard.continue().then(this._onNextStep);
        }
    };

    _checkAndToggleBtn = (step, remove) => {
        this.refs.nextBtns.forEach((btn) => {
            if (step.contains(btn)) this._toggleDisable(btn, remove);
        });
        if (step.contains(this.refs.submit)) {
            this._toggleDisable(this.refs.submit, remove);
        }
    };

    _toggleDisable = (btn, remove) => {
        btn[remove ? 'removeAttribute' : 'setAttribute']('disabled', 'true');
    };

    _onContinue = async () => {
        let gaAction = 'Property Details Entered';
        let gaLabel = 'Sell a property';
        const isRequestForm = this._isRequestForm();
        if (this.wizard.getCurrentStepIndex() === 0) {
            if (isRequestForm) {
                gaLabel = this._getSelectedChannel();
                analyticsService.sendEvent('request-valuation', gaAction, gaLabel);
            } else {
                analyticsService.sendEvent('request-viewing', 'Email Provided Clicked', '');
            }
        } else if (this.wizard.getCurrentStepIndex() === 1) {
            if (isRequestForm) {
                gaAction = 'DateTime Selected';
                analyticsService.sendEvent('request-valuation', gaAction, gaLabel);
            } else {
                analyticsService.sendEvent('request-viewing', 'DateTime Selected', '');
            }
        }

        if (
            this.wizard.getCurrentStepIndex() + 1 === this.calendarStep.getIndex() &&
            this.calendarInstance
        ) {
            const res = await this.calendarInstance.sentRequest();
        }

        this.wizard.continue(true).catch(() => {});
    };

    _onNextStep = (step) => {
        if (
            step.getIndex() === this.calendarStep.getIndex() &&
            !this.calendarStep.isDisabled &&
            this.calendarInstance !== null
        ) {
            this.calendarInstance.sentRequest();
        }
    };

    _onBack = () => {
        this.wizard.back();
    };

    _onBeforeSubmit = () => {
        const step = this._getActiveStepElement()[0];
        if (step) step.append(this.refs.spinner);
        this.spinner.show();
    };

    _onError = (error) => {
        this._showLastStep(
            error || "Server error, we could't sent request. Some problem with network."
        );
    };

    _onSuccessfulSubmit = (result) => {
        this.spinner.hide();
        const type = result.data.type;
        if (type === 'success') {
            const gaLabel = this._getSelectedChannel();
            const isRequestForm = this._isRequestForm();
            if (isRequestForm) {
                analyticsService.sendEvent(
                    'request-valuation',
                    'Confirmed Valuation Details',
                    gaLabel
                );
            } else {
                analyticsService.sendEvent('request-viewing', 'Confirmed Viewing Details', '');
            }
        }

        if (type === 'success' || type === 'serverError') {
            this._showLastStep(result.data.message);
            this.modal.options.onClose = this._dropState;
        }
    };

    _onFailedSubmit = () => {
        this.spinner.hide();
    };

    _showLastStep = (message) => {
        this.refs.finalStepContent.innerHTML = message;
        dcFactory.init(this.refs.finalStepContent);
        this.element.classList.add('is-finished');
        this.wizard.hideLast();
        this.finalStep.show();
        const gaLabel = this._getSelectedChannel();
        const isRequestForm = this._isRequestForm();
        if (isRequestForm) {
            analyticsService.sendEvent('request-valuation', 'Valuation Booked', gaLabel);
        } else {
            analyticsService.sendEvent('request-viewing', 'Viewing Booked', '');
        }
    };

    _dropState = () => {
        this.modal.options.onClose = null;
        setTimeout(() => {
            this.finalStep.hide();
            this.element.classList.remove('is-finished');
            this._steps.forEach((step) => {
                step.classList.remove('is-done');
            });
            this.refs.finalStep.classList.remove('is-done');
            this.wizard.forceGoTo(0);
        }, 300);
    };

    _getSelectedChannel = () => {
        const radioButtons = this.form.element.querySelectorAll('input[name="channel"]');
        let gaLabel = 'Sell a property';
        for (let i = 0; i < radioButtons.length; i++) {
            if (radioButtons[i].checked) {
                gaLabel = radioButtons[i].placeholder;
            }
        }

        return gaLabel;
    };

    _isArrangeForm = () => {
        const currentFormAction = this.form.action;
        if (currentFormAction.includes('arrangeViewing')) {
            return true;
        }

        return false;
    };

    _isRequestForm = () => {
        const currentFormAction = this.form.action;
        if (currentFormAction.includes('requestValuation')) {
            return true;
        }

        return false;
    };
}
