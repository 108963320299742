import React from 'preact/compat';
import SvgIcon from 'general/js/svg-icon';
import { appConfig } from 'general/js/app-config';

class Notification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            content: null,
            isFlash: false
        };
        this.timer = null;
    }

    showNoti = (content) => {
        const { isActive } = this.state;
        if (isActive) {
            this.setState({ isFlash: true });
            setTimeout(() => {
                this.setState({ isFlash: false, content });
            }, 300);
        } else {
            this.setState({ isActive: true, content });
        }

        if (this.timer !== null) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.timer = null;
            this.setState({ isActive: false });
        }, 3000);
    };

    render() {
        const { content, isActive, isFlash } = this.state;
        return content && (
            <div className={`site-noti${isActive ? ' is-active' : ''}`}>
                <div className="site-noti__content">
                    {content.image && (
                        <a className="site-noti__pic"
                            href={content.urlToDetails}
                        >
                            <img className="lazyload"
                                data-src={content.image}
                                alt={content.name}
                            />
                        </a>
                    )}
                    <div className={`site-noti__info${isFlash ? '' : ' is-active'}`}>
                        <h4 className="site-noti__title">
                            <a href={content.urlToDetails} className="site-noti__main-link">
                                {content.name}
                            </a>
                        </h4>
                        {content.price && (
                            <p className="site-noti__text">
                                <span
                                    className="site-noti__price" dangerouslySetInnerHTML={{ __html: content.price }}/>
                                <span className="site-noti__meta">
                                    {' ' + appConfig.translations.propertyAdditional}
                                </span>
                            </p>
                        )}
                    </div>
                </div>
                <button className="site-noti__close"
                    type="button"
                    aria-label="close popup"
                    onClick={() => { this.setState({ isActive: false }); }}
                >
                    <SvgIcon size={[10]} name="cross"/>
                </button>
            </div>
        );
    }
}

export default Notification;
