import { DcBaseComponent } from '@deleteagency/dc';
import { deviceObserver } from '@deleteagency/device-observer';
import { DEVICE_TABLET } from 'general/js/app';
import { eventBus } from 'general/js/events';
import { addPassiveEventListener } from 'general/js/passive-event-listener-polyfill';
import StickyManager from 'general/js/sticky-manager';

const CLASS_SUBNAV_STUCK_EVENT = 'subnavStuckEvent';

export default class StickySubnavComponent extends DcBaseComponent {
    constructor(element) {
        super(element);
        this._stickyManager = null;
    }

    static getNamespace() {
        return 'sticky-subnav';
    }

    onInit() {
        this._setStickyManager();
        if (deviceObserver.is('>', DEVICE_TABLET)) {
            this.header = document.getElementById('header');
            this.headerHeight = this.header.offsetHeight;
            this._addScrollListener();
        }
    }

    _addScrollListener() {
        addPassiveEventListener(window, 'scroll', this._setHeaderOffset);
    }

    _setHeaderOffset = () => {
        const { top } = this.element.getBoundingClientRect();
        const headerOffset = this.headerHeight - top;

        requestAnimationFrame(() => {
            this.header.style.transform =
                top < this.headerHeight ? `translateY(-${headerOffset}px)` : 'translateY(0)';
        });
    };

    _setStickyManager() {
        this._stickyManager = new StickyManager(
            this.element,
            100,
            this.onStickyCb,
            this.onNotStickyCb
        );
    }

    onStickyCb = () => {
        eventBus.emit(CLASS_SUBNAV_STUCK_EVENT);
    };

    onNotStickyCb = () => {};
}
