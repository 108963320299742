import { DcBaseComponent } from '@deleteagency/dc';
import FormWizard from 'general/js/form-wizard/form-wizard';
import FormWizardStep from 'general/js/form-wizard/form-wizard-step';
import BaseForm from 'general/js/forms/base-form';
import { modalService } from 'general/js/modal';
import ElementSpinner from 'general/js/spinner/element-spinner';

export default class TellUsComponent extends DcBaseComponent {
    static getNamespace() {
        return 'tell-us';
    }

    static getRequiredRefs() {
        return ['submit', 'form', 'spinner'];
    }

    onInit() {
        this._steps = this.refs.steps;
        this.form = new BaseForm(
            this.refs.form,
            {
                submitElement: this.refs.submit,
                onFailedSubmit: this._onFailedSubmit,
                onSuccessfulSubmit: this._onSuccessfulSubmit,
                onError: this._onError,
                validateVisibleOnly: false,
            },
            this
        );
        this.parsleyForm = this.form.getParsleyForm();
        this.wizard = new FormWizard(this.element, this.form, {
            stepsElements: this._steps,
            startIndex: 0,
            onShowingStep: (index) => {},
            onHidingStep: (index) => {},
            onActivateStep: (index) => {},
            onDisablingStep: (index) => {},
        });
        this.finalStep = new FormWizardStep(this.refs.finalStep);
        this.spinner = new ElementSpinner(this.refs.spinner, {
            modifiers: ['with-overlay'],
        });
        this.modal = modalService.create(this.element, {
            cssModifier: 'blue',
            cross: false,
        });
        if (!window.Parsley.hasValidator('rvAddressChoosed')) {
            window.Parsley.addValidator('rvAddressChoosed', {
                requirementType: 'string',
                validateString: () => !!this.addressIsChoosed,
            });
        }
        this._addListeners();
    }

    _addListeners = () => {
        this.refs.nextBtns.forEach((btn) => {
            this.addListener(btn, 'click', this._onContinue);
        });
        this.addListener(this.refs.form, 'input', this._onChange);
    };

    _openModal = (target) => {
        if (target === this.element.id) {
            this.modal.open();
            this._checkStepValidAndToggle(this._steps[0]);
        }
    };

    _onContinue = () => {
        this.wizard.continue().then(this._onNextStep);
    };

    _onNextStep = () => {
        const current = this.wizard.getCurrentStepIndex();
        this._checkStepValidAndToggle(this._steps[current]);
    };

    _onChange = (e) => {
        const target = e.target;
        this._steps.forEach((step) => {
            if (step.contains(target)) {
                this._checkStepValidAndToggle(step);
            }
        });
    };

    _checkStepValidAndToggle = (step) => {
        const isValid = this.parsleyForm.isValid({ group: step.getGroup() });
        this._checkAndToggleBtn(step, isValid);
    };

    _checkAndToggleBtn = (step, remove) => {
        this.refs.nextBtns.forEach((btn) => {
            if (step.contains(btn)) this._toggleDisable(btn, remove);
        });
        if (step.contains(this.refs.submit)) {
            this._toggleDisable(this.refs.submit, remove);
        }
    };

    _toggleDisable = (btn, remove) => {
        btn[remove ? 'removeAttribute' : 'setAttribute']('disabled', 'true');
    };

    _showLastStep = (message) => {
        if (typeof message != 'undefined') {
            this.refs.finalStepContent.innerHTML = message;
        }
        this.element.classList.add('is-finished');
        this.wizard.hideLast();
        this.finalStep.show();
    };

    _dropState = () => {
        this.modal.options.onClose = null;
        setTimeout(() => {
            this.finalStep.hide();
            this.element.classList.remove('is-finished');
            this._steps.forEach((step) => {
                step.classList.remove('is-done');
            });
            this.refs.finalStep.classList.remove('is-done');
            this.wizard.forceGoTo(0);
        }, 300);
    };

    _updateOptions = (props) => {
        const { content = {} } = props;
        this.refs.addressHiddens.forEach((input) => {
            const name = input.getAttribute('name');
            if (content[name]) {
                input.value = content[name];
            }
        });
    };

    _onError = (error) => {
        this.spinner.hide();
        const message = error || '<p>There was a problem with network. Please try again later.</p>';
        this._showLastStep(message);
    };

    _onSuccessfulSubmit = (ans) => {
        this.spinner.hide();
        const result = ans.data.result;
        if (result) {
            this._showLastStep();
        } else {
            this._showLastStep(ans.data.message);
        }
        this.refs.form.reset();
        this.parsleyForm.reset();
        this.parsleyForm.refresh();
    };

    _onFailedSubmit = () => {
        this.spinner.hide();
    };

    _onBeforeSubmit = () => {
        this.spinner.show();
    };
}
